import { TableKeyEnum } from '@interfaces';

export const defaultPersonalSetting = {
  PHOTO_TAB: {
    view_type: 'list',
  },
  tables: {
    [TableKeyEnum.RISK_RADAR]: {
      hidden_columns: [
        'loan.external_id',
        'address.address_1',
        'project_funds.original_estimate',
        'construction_holdback_current',
        'construction_holdback_available',
        'loan.close_date',
        'scores.budget_score',
        'scores.schedule_score',
        'scores.policy_score',
        'gap',
      ],
    },
    [TableKeyEnum.LINE_ITEMS]: {
      hidden_columns: [
        'proofpoints',
        'original_construction_budget',
        'revised_construction_budget',
      ],
    },
    [TableKeyEnum.REQUESTS]: {
      hidden_columns: [
        'type',
        'project.loan.external_id',
        'project.address.address_1',
        'inspector_allowance_rate',
        'lender_allowance_rate',
        'variance_to_lender_allowance_rate',
        'approved_amount_cumulative',
        'transaction_date',
        'customer.name',
        'inspection.provider_status_display',
      ],
    },
    [TableKeyEnum.SUBMISSION]: {
      hidden_columns: [
        'cost_type',
        'prefunding_cost',
        'funders',
        'borrower_entity',
        'construction_holdback',
        'original_estimate',
        'previous_changes_rate',
        'requested_adjustments_rate',
        'previous_lender_allowance_rate',
        'balance_to_finish_rate',
        'requested_amount_relative',
      ],
    },
    [TableKeyEnum.DRAW_REQUEST]: {
      hidden_columns: [
        'cost_type',
        'prefunding_cost',
        'funders',
        'borrower_entity',
        'construction_holdback',
        'original_estimate',
        'previous_changes',
        'previous_changes_rate',
        'requested_adjustments_rate',
        'adjustments_rate',
        'requested_amount_relative',
        'inspector_allowance',
        'inspector_allowance_rate_incremental',
        'inspector_allowance_incremental',
        'approved_amount_cumulative',
        'approved_amount_relative',
        'previous_approved_amount_cumulative',
        'previous_lender_allowance_rate',
        'balance_to_finish_rate',
        'variance_to_lender_allowance',
        'variance_to_lender_allowance_rate',
        'original_construction_budget',
        'revised_construction_budget',
      ],
    },
    [TableKeyEnum.CHANGE_REQUEST]: {
      hidden_columns: [
        'cost_type',
        'prefunding_cost',
        'funders',
        'original_estimate',
        'previous_changes_rate',
        'requested_adjustments_rate',
        'adjustments_rate',
        'previous_lender_allowance_rate',
        'balance_to_finish_rate',
        'inspector_allowance_rate',
        'inspector_allowance_rate_incremental',
        'inspector_allowance_incremental',
        'approved_amount_relative',
        'variance_to_lender_allowance',
        'original_construction_budget',
        'revised_construction_budget',
      ],
    },
    [TableKeyEnum.INSPECTION_LIST]: {
      hidden_columns: [
        'project.loan.external_id',
        'project.address.address_1',
        'project.property_existing_type',
        'project.property_proposed_type',
        'project.status',
        'project.loan.loc_commitment',
        'project.original_estimate',
        'project.score',
        'project.type',
        'ordered_at',
      ],
    },
    [TableKeyEnum.PROJECT_SERVICES]: {
      hidden_columns: ['provider_order_id', 'inspector_allowance'],
    },
  },
};
