/* eslint-disable react/no-array-index-key */
import React, { useMemo } from 'react';
import {
  Card,
  CardContent,
  CardMedia,
  Stack,
  Theme,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { Link } from 'react-router-dom';

import { colors } from '@theme';
import {
  dateFormatter,
  getAddress,
  getStatusChip,
  isChangeRequest,
  isInactiveProject,
  isRequestHistorical,
} from '@utils';
import { ComponentProps } from './interface';
import { AddressIcon, CalendarIcon, DrawRequestIcon, HomeIcon } from '@svgAsComponents';
import { StatusChip, TruncatedTextWithTooltip } from '@components';
import { ProjectStatusMap, REQUEST_TYPE_LABEL } from '@constants';
import { useLaunchDarklyFlags } from '@context';

const ProjectImage = styled('div')({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  backgroundColor: colors.background.gray,
});

const requestChecks = {
  [REQUEST_TYPE_LABEL.CHANGE_REQUEST]: (request) => isChangeRequest(request),
  [REQUEST_TYPE_LABEL.HISTORICAL_DRAW_REQUEST]: (request) => isRequestHistorical(request?.source),
  [REQUEST_TYPE_LABEL.DRAW_REQUEST]: () => true,
};

const getRequestType = (request) => {
  for (const [type, checkFunction] of Object.entries(requestChecks)) {
    if (checkFunction(request)) return type;
  }
};

const ProjectCard = ({ link, item }: ComponentProps) => {
  const middleMediaQuery = useMediaQuery((theme: Theme) => theme.breakpoints.up('sm'));
  const isArchived = useMemo(() => isInactiveProject(item.status), [item.status]);
  const projectImage =
    item.thumb_representations?.thumb?.url || item.thumb_representations?.original?.url || '';
  const flags = useLaunchDarklyFlags();
  const lastDrawRequest =
    flags?.['ENG_7990_new_projects_page'] && 'draw_request' in item
      ? item.draw_request
      : item.last_draw_request;
  const requestName = `${getRequestType(lastDrawRequest)} #${lastDrawRequest?.number} `;

  return (
    <Link to={`${link}/${item.id}/overview`} style={{ textDecoration: 'none' }}>
      <Card
        variant="outlined"
        data-id={item.id}
        sx={{
          borderRadius: 1,
          borderColor: colors.neutral.lighter,
          display: 'flex',
          flexDirection: { sm: 'row', xs: 'column' },
          height: { sm: 104 },
          flexGrow: { xs: 1 },
        }}
      >
        {projectImage && (
          <CardMedia
            component="img"
            sx={{ width: { sm: 104, xs: '100%' }, height: { sm: 104, xs: 100 } }}
            image={projectImage}
            title="project image"
          />
        )}
        {!projectImage && (
          <ProjectImage sx={{ width: { sm: 104, xs: '100%' }, height: { sm: 104, xs: 100 } }}>
            <HomeIcon />
          </ProjectImage>
        )}
        <CardContent
          sx={{
            width: { sm: 'calc(100% - 104px)', xs: '100%' },
            p: { sm: 2, xs: 1 },
            '&:last-child': { pb: { sm: 2, xs: 1 } },
          }}
        >
          <Stack sx={{ flexDirection: 'row', alignItems: 'center', mt: { sm: 0, xs: 1 } }}>
            <TruncatedTextWithTooltip
              value={item.name}
              variant="h4"
              style={{ color: colors.text.heading }}
              ellipsisActive={middleMediaQuery}
            />
          </Stack>
          <Stack sx={{ flexDirection: 'row', alignItems: 'center', mt: 1 }}>
            <AddressIcon size={14} />
            <TruncatedTextWithTooltip
              value={getAddress(item.address)}
              variant="label"
              style={{ marginLeft: 0.75 }}
              ellipsisActive={middleMediaQuery && !!lastDrawRequest?.number}
            />
          </Stack>
          <Stack sx={{ flexDirection: 'row', alignItems: 'center', mt: 0.5 }}>
            {isArchived ? (
              <>
                {item.archived_at && <CalendarIcon size={12} />}
                <Stack alignItems="center" direction="row" sx={{ flexGrow: 1, ml: 0.75 }}>
                  {item.archived_at && (
                    <Typography variant="label" sx={{ color: colors.text.medium, mr: 1 }}>
                      {`Archived ${dateFormatter({ date: item.archived_at })}`}
                    </Typography>
                  )}
                  <StatusChip
                    color={colors.text.medium}
                    backgroundColor={colors.neutral.lightest}
                    label={ProjectStatusMap[item.status]}
                    size="small"
                  />
                </Stack>
              </>
            ) : (
              <>
                {lastDrawRequest?.number && (
                  <>
                    <DrawRequestIcon size={14} />
                    <Stack alignItems="center" direction="row" sx={{ flexGrow: 1, ml: 0.75 }}>
                      <Typography variant="label" sx={{ color: colors.text.medium, mr: 1 }}>
                        {requestName}
                      </Typography>
                      {getStatusChip(lastDrawRequest, 'small')}
                    </Stack>
                  </>
                )}
              </>
            )}
          </Stack>
        </CardContent>
      </Card>
    </Link>
  );
};

export default ProjectCard;
