import React from 'react';
import { IconButton, Stack, Typography } from '@mui/material';
import { LoadingSkeleton, PoliciesList, PoliciesListEditMode, StyledBox } from '@components';
import { EditIcon, NoDataImage } from '@svgAsComponents';
import * as Controller from './controller';
import { colors } from '@theme';
import { PolicyContainerProps } from './interface';

const PolicyContainer = ({
  policyLists,
  title,
  assigneeRole,
  type,
  source,
  editMode,
  setEditMode,
}: PolicyContainerProps) => {
  const controller = Controller.usePolicyContainer({
    setEditMode,
    policyLists,
    assigneeRole,
    type,
  });
  const { activeTemplate, handleSave, handleClose, isMutating } = controller;
  const isEditing = editMode === source;

  return (
    <StyledBox
      flex={1}
      sx={{ display: 'flex', flexDirection: 'column', flexGrow: 1, overflow: 'inherit' }}
    >
      <Stack direction="row" justifyContent="space-between" alignItems="center" sx={{ mb: 2 }}>
        <Typography variant="h3">{title}</Typography>
        <Stack direction="row">
          {!isEditing && activeTemplate?.id && (
            <IconButton
              onClick={() => setEditMode(source)}
              data-cy={`${source}_policy__list__edit__button`}
            >
              <EditIcon size={20} color={colors.icons.green} />
            </IconButton>
          )}
        </Stack>
      </Stack>

      {!isEditing &&
        (activeTemplate?.items?.length ? (
          isMutating ? (
            <LoadingSkeleton type="overviewBlock" />
          ) : (
            <PoliciesList
              items={activeTemplate?.items}
              policiesId={activeTemplate?.id}
              isTemplate
              source={source}
            />
          )
        ) : (
          <Stack sx={{ flexGrow: 1 }} justifyContent="center" alignItems="center">
            <NoDataImage size={200} />
            <Typography sx={{ mt: 4, textAlign: 'center' }} variant="body2SemiBold">
              No policy set up
            </Typography>
          </Stack>
        ))}
      {isEditing && (
        <PoliciesListEditMode
          isCompanyPolicyTemplate
          policiesList={activeTemplate}
          setEditMode={setEditMode}
          handleSave={handleSave(activeTemplate?.id)}
          handleClose={handleClose}
          source={source}
          isPlatformPolicy
        />
      )}
    </StyledBox>
  );
};

export default PolicyContainer;
