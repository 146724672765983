export enum TableKeyEnum {
  INSPECTION_ENTER_RESULTS = 'INSPECTION_ENTER_RESULTS',
  INSPECTION_RESULTS = 'INSPECTION_RESULTS',
  RISK_RADAR = 'RISK_RADAR',
  LINE_ITEMS = 'LINE_ITEMS',
  PHB_LINE_ITEMS = 'PHB_LINE_ITEMS',
  REQUESTS = 'REQUESTS',
  REQUEST_LINE_ITEMS = 'REQUEST_LINE_ITEMS',
  PHB_REQUEST_LINE_ITEMS = 'PHB_REQUEST_LINE_ITEMS',
  DRAW_REQUEST = 'DRAW_REQUEST',
  CHANGE_REQUEST = 'CHANGE_REQUEST',
  INSPECTION_LIST = 'INSPECTION_LIST',
  SUBMISSION = 'SUBMISSION',
  PHOTOS = 'PHOTOS',
  PROJECT_SERVICES = 'PROJECT_SERVICES',
}

export enum HookState {
  FETCHING = 'fetching',
  ERROR = 'error',
  SUCCESS = 'success',
  EMPTY = 'empty',
  IDLE = 'idle',
}

export enum InspectionStatusEnum {
  CREATED = 'CREATED',
  PENDING = 'PENDING',
  COMPLETED = 'COMPLETED',
  CANCELLED = 'CANCELLED',
  ORDERED = 'ORDERED',
  ERROR = 'ERROR',
  CANCEL_REQUESTED = 'CANCEL_REQUESTED',
}

export enum InspectionActionEnum {
  EDIT = 'edit',
  VIEW = 'view',
}

export enum InspectionServiceEnum {
  TRINITY = 'TRINITY',
  CFSI = 'CFSI',
  MANUAL = 'MANUAL',
  PROXYPICS = 'PROXYPICS',
  NVMS = 'NVMS',
}

export enum ProjectStatusEnum {
  CREATED = 'CREATED',
  ACTIVE = 'ACTIVE',
  INACTIVE_INCOMPLETE = 'INACTIVE_INCOMPLETE',
  INACTIVE_COMPLETE = 'INACTIVE_COMPLETE',
  PAUSED = 'PAUSED',
}

export enum UserStatusEnum {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
}

export enum UserStatusDisplayEnum {
  ACTIVE = 'Active',
  AWAITING_INVITE = 'Awaiting invite',
  INVITED = 'Invited',
  INACTIVE = 'Deactivated',
}

export enum DrawRequestStatus {
  DRAFT = 'DRAFT',
  APPROVED = 'APPROVED',
  COMPLETED = 'COMPLETED',
  IN_REVIEW = 'IN_REVIEW',
}

export enum RequestCombinedStatus {
  AWAITING_YOU = 'AWAITING_YOU',
  AWAITING_OTHERS = 'AWAITING_OTHERS',
  AWAITING_FOR_ADDITIONAL_REQUIREMENTS = 'AWAITING_FOR_ADDITIONAL_REQUIREMENTS',
  ON_HOLD = 'ON_HOLD',
}

export enum RequestReviewStatusEnum {
  APPROVE = 'APPROVE',
  FUND = 'FUND',
  REJECT = 'REJECT',
}
export enum PaymentConfiguration {
  PER_DRAW_REQUEST = 'PARTIAL_PER_DRAW_REQUEST',
  SEQUENTIAL = 'PROJECT_SEQUENTIAL',
}

export enum DrawRequestTypeEnum {
  DRAW_REQUEST = 'DRAW_REQUEST',
  CHANGE_REQUEST = 'CHANGE_REQUEST',
  RETAINAGE_REQUEST = 'RETAINAGE_REQUEST',
}

export enum DrawRequestSourceEnum {
  SYSTEM = 'SYSTEM',
  MANUAL = 'MANUAL',
}

export enum LineItemHighlightVariantEnum {
  CURRENT = 'current',
  ACTIVE = 'active',
  ERROR = 'error',
  COMPLETED = 'complete',
}

export enum ToolTipLineVariantEnum {
  INFORMATION = 'information',
  ERROR = 'error',
  TEXT = 'text',
  WARNING = 'warning',
}

export enum ServerErrorCodesEnum {
  PROFILE_DEACTIVATED = 'login.profile_deactivated',
  PERMISSION_DENIED = 'permission_denied',
  NOT_FOUND = 'not_found',
  NOT_AUTHENTICATED = 'not_authenticated',
  TOKEN_NOT_VALID = 'jwt.token_not_valid',
}

export enum ColumnWidth {
  MONEY = '120px',
  MONEY_WITH_PROGRESS = '120px',
  PERCENTS = '100px',
  PERCENTS_WITH_PROGRESS = '110px',
  INPUT_MONEY = '140px',
  TEXT_INPUT = '140px',
  NAME = '250px',
  TEXT = '110px',
  WIDE_TEXT = '140px',
}

export enum ColumnV2Width {
  INPUT = 180,
  SELECT_MIN = 220,
  NUMBER_MIN = 180,
  NUMBER_MAX = 200,
  WIDE_ICON = 84,
  ICON = 48,
  LAST = 60,
  TEXT_MIN = 100,
  TEXT_MAX = 350,
  NAME_MIN = 300,
  NAME_MAX = 650,
  MAX_LINE = 1000,
  MIN_LINE = 0,
}

export enum ColumnAlign {
  MONEY = 'right',
  TEXT = 'left',
}

export enum DisbursementStatementHeaderTypeEnum {
  FINAL_APPROVAL_INTERNAL_PAYMENT = 'FINAL_APPROVAL_INTERNAL_PAYMENT',
  PM_APPROVAL_INTERNAL_PAYMENT = 'PM_APPROVAL_INTERNAL_PAYMENT',
  FINAL_APPROVAL_EXTERNAL_PAYMENT = 'FINAL_APPROVAL_EXTERNAL_PAYMENT',
  MARK_AS_PAID_APPROVAL_EXTERNAL_PAYMENT = 'MARK_AS_PAID_APPROVAL_EXTERNAL_PAYMENT',
}

export enum TransactionTypeEnum {
  PLATFORM = 'PLATFORM',
  MANUAL = 'MANUAL',
  WEBHOOK = 'WEBHOOK',
}

export enum TransactionFailureReasonEnum {
  PREMATURE_FUNDING = 'PREMATURE_FUNDING',
}

export enum DocumentVisibilityEnum {
  COMPANY = 'COMPANY',
  EVERYONE = 'EVERYONE',
}

export enum MutationKeyEnum {
  MILESTONE_PATCH_BULK = 'MILESTONE_PATCH_BULK',
  MILESTONE_PATCH = 'MILESTONE_PATCH',
  MILESTONE_DELETE = 'MILESTONE_DELETE',
  DRAW_REQUEST_PATCH = 'DRAW_REQUEST_PATCH',
  DRAW_REQUEST_ADD_ITEM = 'DRAW_REQUEST_ADD_ITEM',
  COMPANY_POLICIES_TEMPLATE_PATCH = 'COMPANY_POLICIES_TEMPLATE_PATCH',
  PLATFORM_POLICIES_TEMPLATE_PATCH = 'PLATFORM_POLICIES_TEMPLATE_PATCH',
}

export enum ItemStatusesEnum {
  NOT_STARTED = 'NOT_STARTED',
  COMPLETED = 'COMPLETED',
  SKIPPED = 'SKIPPED',
}

export enum ImageSizeEnum {
  ORIGINAL = 'original',
  LARGE = 'large', // width 2400
  THUMB = 'thumb', // width 300
}

export enum PoliciesTypeEnum {
  PROJECT = 'all',
  FUTURE_DRAWS = 'future-draws',
  FUTURE_CHANGES = 'future-changes',
  FIRST_DRAW = 'first-draw',
}

export enum GuideNameEnum {
  PROFILE_SECTION_INTRO = 'PROFILE_SECTION_INTRO',
  CONNECT_BANK_ACCOUNT = 'CONNECT_BANK_ACCOUNT',
  PROJECT_OVERVIEW = 'PROJECT_OVERVIEW',
  SEND_TO_INSPECTOR = 'SEND_TO_INSPECTOR',
  NEW_INPUT = 'NEW_INPUT',
}

export enum RequestSummaryErrorType {
  APPROVED = 'APPROVED',
  REQUESTED = 'REQUESTED',
}

export enum CSVBoxSheetsEnum {
  IMPORT_BUDGET_BULK = 'import_budget_bulk',
  IMPORT_PROJECT = 'import_project',
  IMPORT_PROJECT_BUDGET = 'import_project_budget',
  IMPORT_BUDGET_PHB = 'import_budget_phb',
}

export enum SystemConfigKeysEnum {
  CSVBOX = 'csvbox',
}

export enum ContingencyDescriptionEnum {
  COLUMN_DESCRIPTION = 'To utilize contingency, enter the desired amount here and reallocate this value to the other line items.',
}

export enum DocumentTypeEnum {
  APPRAISAL = 'Appraisal Report',
  PROJECT_IMPORT_TEMPLATE = 'Project Import Template',
  PROGRESS_PHOTO = 'Progress Photo',
  THUMB = 'Thumb',
}

export enum ApproveActionEnum {
  APPROVE = 'Approve',
  FUND = 'Fund',
  SEND_FOR_APPROVAL = 'Send for approval',
}

export enum AutoFillModeEnum {
  CREDIT_REVIEW = 'Review credit',
  LENDER_ALLOWANCE = 'Lender allowance',
  APPROVED_CHANGES = 'Approved changes',
  APPROVED_RETAINAGE = 'Approved retainage release',
}

export enum ValueTypeEnum {
  IS_IN_REVIEW = 'isInReview',
  IS_ERROR = 'isError',
}

export enum TransloaditTemplateEnum {
  PROOFPOINTS = 'proofpoints',
  INSPECTION_DOCUMENTS = 'inspection_documents',
  DOCUMENTS = 'document',
}
export enum ContingencyModeEnum {
  REALLOCATION_ONLY = 'REALLOCATION_ONLY',
  NORMAL = 'NORMAL',
}

export enum IInspectionClosePopupEnum {
  SUCCESS = 'success',
  MISSING_BORROWER_PHONE = 'missingBorrowerPhone',
}

export enum RequestOperationsModalTypeEnum {
  CHECKLIST = 'CHECKLIST',
  PAYMENT = 'PAYMENT',
  DRAW_REQUEST = 'DRAW_REQUEST',
  APPROVED = 'APPROVED',
  PAYMENT_ERROR = 'PAYMENT_ERROR',
  SWITCH_APPROVER = 'SWITCH_APPROVER',
  REJECT = 'REJECT',
  REJECT_SUCCESS = 'REJECT_SUCCESS',
  PUT_ON_HOLD = 'PUT_ON_HOLD',
  PUT_ON_HOLD_SUCCESS = 'PUT_ON_HOLD_SUCCESS',
  RELEASE_FROM_HOLD = 'RELEASE_FROM_HOLD',
  RELEASE_FROM_HOLD_SUCCESS = 'RELEASE_FROM_HOLD_SUCCESS',
  MARK_AS_DISBURSED = 'MARK_AS_DISBURSED',
}

export enum FiltersSwitcherEnum {
  AWAITING_YOU = 'awaiting-you',
  ALL = 'all',
}

export enum RequestActions {
  SUBMISSION = 'submission',
  RESUBMIT = 'resubmit',
  TABLE_EDIT = 'table-edit',
}

export enum WorkflowReasonEnum {
  REJECT = 'REQUEST_RETURNED',
  PUT_ON_HOLD = 'REQUEST_PUT_ON_HOLD',
  RELEASE_FROM_HOLD = 'REQUEST_RELEASE_FROM_HOLD',
  PAUSED = 'PROJECT_STATUS_PAUSED',
  INACTIVE_COMPLETE = 'PROJECT_STATUS_INACTIVE_COMPLETE',
  INACTIVE_INCOMPLETE = 'PROJECT_STATUS_INACTIVE_INCOMPLETE',
  COMPLETION_DATE_CHANGED = 'PROJECT_ESTIMATED_COMPLETION_DATE_CHANGED',
}

export enum CreditReasonEnum {
  REBATE_OR_CREDIT = 'Supplier rebate or credit',
  DISBURSEMENT_CORRECTION = 'Disbursement correction',
  OTHER = 'Other',
}

export enum UserListModalTypeEnums {
  ACTIVATE = 'activate',
  DEACTIVATE = 'deactivate',
  SEND_INVITE = 'sendInvite',
  INVITE_SENT = 'inviteSent',
  USER_ACTIVATED = 'userActivated',
  USER_DEACTIVATED = 'userDeactivated',
  REMOVE_FROM_TEAM = 'removeFromTeam',
}

export enum LineItemModalTypeEnums {
  ADD = 'add',
  EDIT = 'edit',
}

export enum RequestModalTypeEnums {
  DELETE = 'delete',
  EDIT = 'edit',
}

export enum RequestTableTabsEnum {
  DOCUMENTS = 'documents',
  COMMENTS = 'comments',
  PHOTOS = 'photos',
  CREDIT_SUBMIT = 'credit_submit',
  CREDIT_APPROVE = 'credit_approve',
  CREDIT_INFO = 'credit_info',
  CREDIT = 'credit',
  BREAKDOWN = 'breakdown',
}

export enum RequestTableTabTypesEnum {
  APPROVE = 'approve',
  SUBMIT = 'submit',
  INFO = 'info',
}

export enum DateValidationEnum {
  MORE = 'MORE',
  LESS = 'LESS',
  MORE_OR_EQUAL = 'MORE_OR_EQUAL',
  LESS_OR_EQUAL = 'LESS_OR_EQUAL',
  BETWEEN_INCLUDING = 'BETWEEN_INCLUDING',
}

export enum PolicyItemTypesEnum {
  DRAW_REQUEST_FORM = 'DRAW_REQUEST_FORM',
  INSPECTION_ORDERING = 'INSPECTION_ORDERING',
  COMPLETION_DATE_RENEWAL = 'COMPLETION_DATE_RENEWAL',
  COMMENT = 'COMMENT',
  OPTIONAL_DOCUMENT_UPLOAD = 'OPTIONAL_DOCUMENT_UPLOAD',
  REQUIRED_DOCUMENT_UPLOAD = 'REQUIRED_DOCUMENT_UPLOAD',
  ATTESTATION = 'ATTESTATION',
}

export enum LineItemFilterEnum {
  ALL = 'ALL',
  ACTIVE = 'ACTIVE',
  CURRENT_REQUEST_ONLY = 'CURRENT_REQUEST_ONLY',
  NON_ZERO_BUDGET = 'NON_ZERO_BUDGET',
  HAS_PHOTOS = 'HAS_PHOTOS',
  HORIZONTAL_SOFT_COST = 'HORIZONTAL_SOFT_COST',
  VERTICAL_COST = 'VERTICAL_COST',
}

export enum ScoreColorEnum {
  RED = 'RED',
  GREEN = 'GREEN',
  YELLOW = 'YELLOW',
  NOT_APPLICABLE = 'NOT_APPLICABLE',
}

export enum ScoreValuesEnum {
  PROJECT_HEALTH = 'overall_score',
  BUDGET_HEALTH = 'budget_score',
  SCHEDULE_HEALTH = 'schedule_score',
  POLICY_HEALTH = 'policy_score',
  BORROWER_SCORE = 'borrower_score',
}

export enum ExpandedIconPositionEnum {
  TOP = 'top',
  RIGHT = 'right',
}

export enum PolicyTitleEnum {
  SUBMISSION = 'Submission policy',
  APPROVAL = 'Approval policy',
  PROJECT_ACTIVATION = 'Project activation policy',
}

export enum PolicyHistoryActionEnum {
  CREATE = 'CREATE',
  UPDATE = 'UPDATE',
}

export enum SubmissionAutofillEnum {
  REQUESTED_DRAW = 'Requested draw',
  RETAINAGE = 'Retainage release',
}

export enum PolicyListReasonsTypeEnum {
  PROJECT = 'PROJECT',
  REQUEST = 'REQUEST',
}

export enum PopupTypeEnum {
  GENERAL = 'GENERAL',
  SUCCESS = 'SUCCESS',
  CONFIRMATION = 'CONFIRMATION',
  ERROR = 'ERROR',
}

export enum DocumentContentTypeEnum {
  MILESTONE_SUBMIT = 'projects.milestonesubmit',
  DRAW_REQUEST = 'drawrequests.drawrequest',
  MILESTONE = 'projects.projectmilestone',
  PROJECT = 'projects.project',
  CHECKLIST_ITEM = 'checklists.checklistitem',
  INSPECTION_CHECKLIST_ITEM = 'inspections.milestoneinspection',
  INSPECTION = 'inspection.inspection',
  MILESTONE_GROUP = 'projects.milestonegroup',
}

export enum CSVButtonTypesEnum {
  BACK_ICON = 'backIcon',
  BACK_BUTTON = 'backButton',
  CREATE_BUTTON = 'createButton',
}

export enum CSVPHBBudgetSectionsKeysEnum {
  IS_HORIZONTAL = 'isHorizontal',
  IS_VERTICAL = 'isVertical',
  ALL = 'all',
}

export enum MilestoneTagsTypesEnums {
  LINE_ITEM = 'LINE_ITEM',
  UNIT = 'UNIT',
  MODEL = 'MODEL',
}

export enum PolicyListSourceEnums {
  SUBMISSION = 'submission',
  APPROVAL = 'approval',
}

export enum EventType {
  DOCUMENT_BATCH_ADDED = 'DOCUMENT_BATCH_ADDED',
  DOCUMENT_DELETED = 'DOCUMENT_DELETED',
  DOCUMENT_SCOPE_CHANGED = 'DOCUMENT_SCOPE_CHANGED',
  DRAW_REQUEST_APPROVED = 'DRAW_REQUEST_APPROVED',
  DRAW_REQUEST_CHANGED = 'DRAW_REQUEST_CHANGED',
  DRAW_REQUEST_COORDINATOR_CHANGED = 'DRAW_REQUEST_COORDINATOR_CHANGED',
  DRAW_REQUEST_CREATED = 'DRAW_REQUEST_CREATED',
  DRAW_REQUEST_DELETED = 'DRAW_REQUEST_DELETED',
  DRAW_REQUEST_DISBURSED = 'DRAW_REQUEST_DISBURSED',
  DRAW_REQUEST_ESCALATED = 'DRAW_REQUEST_ESCALATED',
  DRAW_REQUEST_FINALIZED = 'DRAW_REQUEST_FINALIZED',
  DRAW_REQUEST_IS_ON_HOLD_CHANGED = 'DRAW_REQUEST_IS_ON_HOLD_CHANGED',
  DRAW_REQUEST_MARKED_AS_DISBURSED = 'DRAW_REQUEST_MARKED_AS_DISBURSED',
  DRAW_REQUEST_POLICY_CHANGED = 'DRAW_REQUEST_POLICY_CHANGED',
  DRAW_REQUEST_RETURNED = 'DRAW_REQUEST_RETURNED',
  DRAW_REQUEST_REVIEWER_TEAM_CHANGED = 'DRAW_REQUEST_REVIEWER_TEAM_CHANGED',
  DRAW_REQUEST_SUBMITTED = 'DRAW_REQUEST_SUBMITTED',
  DOCUMENT_ADDED = 'DOCUMENT_ADDED',
  INSPECTION_BATCH_CHANGED = 'INSPECTION_BATCH_CHANGED',
  INSPECTION_CANCELLED = 'INSPECTION_CANCELLED',
  INSPECTION_COMPLETED = 'INSPECTION_COMPLETED',
  INSPECTION_ERRORED = 'INSPECTION_ERRORED',
  INSPECTION_ORDERED = 'INSPECTION_ORDERED',
  PHOTO_BATCH_ADDED = 'PHOTO_BATCH_ADDED',
  PHOTO_BATCH_DELETED = 'PHOTO_BATCH_DELETED',
  PROJECT_ACTIVATED = 'PROJECT_ACTIVATED',
  PROJECT_DEACTIVATED = 'PROJECT_DEACTIVATED',
  PROJECT_ARCHIVED = 'PROJECT_ARCHIVED',
  PROJECT_CHANGED = 'PROJECT_CHANGED',
  PROJECT_COMPLETED = 'PROJECT_COMPLETED',
  PROJECT_CREATED = 'PROJECT_CREATED',
  PROJECT_LOAN_SOLD = 'PROJECT_LOAN_SOLD',
  PROJECT_PAUSED = 'PROJECT_PAUSED',
  PROJECT_POLICY_TEMPLATE_CHANGED = 'PROJECT_POLICY_TEMPLATE_CHANGED',
  SCORE_CHANGED = 'SCORE_CHANGED',
  SCORE_FLAG_CREATED = 'SCORE_FLAG_CREATED',
  SCORE_FLAG_RESOLVED = 'SCORE_FLAG_RESOLVED',
}

export enum DocumentTabEnums {
  ALL = 'all',
  PROJECT = 'project',
}

export enum DocumentActionTypeEnum {
  RESTORE_PROJECT_DOCUMENT = 'RESTORE_PROJECT_DOCUMENT',
  DELETE_PROJECT_DOCUMENT = 'DELETE_PROJECT_DOCUMENT',
  DELETE_DR_DOCUMENT = 'DELETE_DR_DOCUMENT',
  RESTORE_DR_DOCUMENT = 'RESTORE_DR_DOCUMENT',
  DELETE_INSPECTION_DOCUMENT = 'DELETE_INSPECTION_DOCUMENT',
}
