import React, { FC, memo, useContext } from 'react';
import { IconButton } from '@mui/material';

import { MilestoneListColumnType, CommonRowType } from './common';
import { TableContext } from '../controller';
import { FundingIconWithIndicator } from '@svgAsComponents';
import { ColumnV2Width, RequestTableTabsEnum } from '@interfaces';
import { creditAvalibleForMilestone } from '@utils';

const SubmitCredit: FC<{ row: CommonRowType }> = ({ row }) => {
  const { updateRightDrawer, source } = useContext(TableContext);
  const creditAvalible = creditAvalibleForMilestone(row);
  return creditAvalible ? (
    <IconButton
      onClick={
        updateRightDrawer
          ? updateRightDrawer({
              milestoneId: row?.id,
              milestoneName: row?.name,
              tab: RequestTableTabsEnum.CREDIT_SUBMIT,
            })
          : row?.updateRightDrawer()
      }
      data-cy={`${source}__body__credit__icon__index_${row.project_milestone?.index}`}
    >
      <FundingIconWithIndicator />
    </IconButton>
  ) : null;
};

const Memoized = memo(SubmitCredit);

const credit: MilestoneListColumnType = {
  name: 'credit_reason',
  columnText: '',
  Header: '',
  justifyContent: 'center',
  noBorder: true,
  noMargin: true,
  renderCell: ({ row }) => <Memoized row={row} />,
  minWidth: () => ColumnV2Width.ICON,
  maxWidth: () => ColumnV2Width.ICON,
};

export default credit;
