import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useMutation, useQueries, useQueryClient } from 'react-query';
import map from 'lodash/map';
import isEmpty from 'lodash/isEmpty';

import {
  checkHasAmountChanges,
  checkIsAllNamesFillInProject,
  checkIsOwner,
  getHookState,
  getItemLocalHighlight,
  getTeamRole,
  isCreatedProject,
  isRestricted,
  parsePathErrorDual,
} from '@utils';
import {
  DeleteListItemParam,
  ErrorDual,
  HookState,
  IDynamicColumns,
  IMilestone,
  IMilestoneTotal,
  MutationKeyEnum,
  PatchListItem,
  PatchListItemParam,
  PermissionNamesEnums,
  ProjectById,
  QueryNamesEnums,
  TableKeyEnum,
} from '@interfaces';
import {
  deleteMilestoneItem,
  deleteProjectMilestones,
  getProject,
  getProjectDrawRequestsList,
  getProjectMilestonesList,
  pathMilestoneItem,
  postMilestoneToProject,
} from '@globalService';
import { AuthContext, PermissionsContext, SettingsContext, useLaunchDarklyFlags } from '@context';
import {
  ConfirmModalHookInterface,
  useConfirmationModal,
  useLineItemsFilter,
  useSafeSnackbar,
} from '@hooks';
import { excludeCommentsQueryFields, LineItemFilterValues, REGEX } from '@constants';
import { AddedMsTableProps } from '../MilestoneList/columns/common';

export type ControllerInterface = {
  editMode: boolean;
  totals?: IMilestoneTotal;
  initColumns: Array<string>;
  handleFiltersChange: (value: string) => void;
  state: HookState;
  milestones: Array<Partial<IMilestone> & AddedMsTableProps>;
  filterOptions: string[];
  editBudget: () => void | null;
  addLineList: () => Promise<Response>;
  patchMilestone: (args: PatchListItem) => void;
  showEdit?: boolean;
  isAllNamesNotFill: boolean;
  update: () => void;
  createMode: boolean;
  deleteBudgetModal: ConfirmModalHookInterface;
  deleteBudget: () => void;
  deleteMilestone: (milestone: string) => void;
  filterValue: string;
  updateListData: (updater) => void;
  dynamicColumns: IDynamicColumns[];
  isOwner: boolean;
};

export const useProjectMilestones = (projectId: string): ControllerInterface => {
  const { permissions } = useContext(PermissionsContext);
  const { settings, isPHBProject } = useContext(SettingsContext);
  const { user } = useContext(AuthContext);
  const teamRole = getTeamRole(user);
  const [editMode, setEditMode] = useState<boolean>(false);
  const flags = useLaunchDarklyFlags();
  const filterOptions = [
    LineItemFilterValues.ALL,
    LineItemFilterValues.ACTIVE,
    LineItemFilterValues.NON_ZERO_BUDGET,
  ];
  const { filterValue, handleFilterClick, setFilterValue, filterKey } = useLineItemsFilter({
    defaultState: LineItemFilterValues.ALL.filterValue,
    tableKey: TableKeyEnum.LINE_ITEMS,
  });

  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSafeSnackbar();

  const query = excludeCommentsQueryFields;
  const requestedDataQueries = useQueries([
    {
      queryKey: [QueryNamesEnums.GET_PROJECT_MILESTONES, { projectId, query, filterKey }],
      queryFn: getProjectMilestonesList.bind(this, { projectId, query, filterKey }),
      // TODO remove this check with flag ENG_4271_production_build_budget
      enabled: !isPHBProject,
    },
    {
      queryKey: [QueryNamesEnums.GET_PROJECT, { projectId }],
      queryFn: getProject.bind(this, projectId),
    },
    {
      queryKey: [QueryNamesEnums.GET_PROJECT_DRAW_REQUEST_LIST, { projectId }],
      queryFn: getProjectDrawRequestsList.bind(this, projectId),
    },
  ]);

  const projectMilestones = requestedDataQueries[0];
  const project = requestedDataQueries[1];
  const drawRequests = requestedDataQueries[2];

  const [isFirstRender, setIsFirstRender] = useState(true);

  useEffect(() => {
    // to show edit mode by default for created projects (only for the first time)
    if (isCreatedProject(project.data?.status) && !isEmpty(settings) && isFirstRender) {
      setFilterValue(LineItemFilterValues.ALL.filterValue);
      if (
        projectMilestones.data?.results?.length &&
        !drawRequests.data?.results?.length &&
        (!isRestricted(PermissionNamesEnums.PROJECT__CREATE__BUTTON, permissions) ||
          !isRestricted(PermissionNamesEnums.PROJECT__BUDGET__EDIT, permissions))
      ) {
        setEditMode(true);
        setIsFirstRender(false);
      }
    }
  }, [
    project.data,
    projectMilestones.data,
    settings,
    permissions,
    drawRequests.data,
    isFirstRender,
  ]);

  const milestones = useMemo(() => {
    if (!projectMilestones.data?.results) return [];

    return projectMilestones.data.results.map((item) => ({
      ...item,
      activeToEdit: editMode,
      localNew: editMode,
      localHighlight: getItemLocalHighlight(item),
      paymentConfigurationType: project.data?.payment_configuration_type,
      original_construction_budget: item.prefunding_cost + item.original_estimate,
      revised_construction_budget: item.prefunding_cost + item.revised_estimate,
    }));
  }, [project.data, projectMilestones.data, filterValue, editMode]);

  const hasAmountChanges = useMemo(
    () => checkHasAmountChanges(projectMilestones.data?.results),
    [projectMilestones.data?.results],
  );

  const retainageRate = useMemo(() => project.data?.retainage_rate, [project.data]);

  const initColumns = useMemo(
    () =>
      editMode
        ? [
            'nameV2',
            'costType',
            'originalConstructionBudget',
            'prefundingCost',
            'originalEstimate',
            ...(retainageRate ? ['retainageRateBudget'] : []),
            'revisedConstructionBudget',
          ]
        : [
            'descriptionV2',
            'costType',
            'fundsProvider',
            ...(flags?.['ENG_4272_borrower_equity_in_milestone_list']
              ? ['borrowerEquity', 'constructionHoldback']
              : []),
            'originalConstructionBudget',
            'prefundingCost',
            'originalEstimate',
            ...(retainageRate ? ['retainageRateBudget'] : []),
            'revisedConstructionBudget',
            ...(hasAmountChanges ? ['adjustmentsTotalApprovedBudget'] : []),
            'revisedMilestoneAmount',
            'balanceToFinish',
            PermissionNamesEnums.PROJECT__SOV__INSPECTOR_ALLOWANCE,
            PermissionNamesEnums.PROJECT__SOV__INSPECTOR_ALLOWANCE_RATE,
            'approvedAmountCumulative',
            'lenderAllowanceRate',
            PermissionNamesEnums.PROJECT__SOV__GAP,
            'drawRequestNumbers',
            'changeRequestNumbers',
            'spaceItem',
            ...(flags?.['ENG_7938_breakdown_of_line_item'] ? ['approveLineItemDetails'] : []),
            'documentsPhotosUploaderMenu',
            'documentsPhotosGalleryMenu',
            'comments',
          ],
    [flags, hasAmountChanges, editMode],
  );
  // TODO item list V2 refract

  const update = () => {
    queryClient.invalidateQueries([QueryNamesEnums.GET_PROJECT_MILESTONES, { projectId }]);
    queryClient.invalidateQueries([QueryNamesEnums.GET_PROJECT_FUNDS, { projectId }]);
    queryClient.invalidateQueries([QueryNamesEnums.GET_PROJECT, { projectId }]);
  };

  const addLineListItemMutation = useMutation<Response, Error, ProjectById>(
    postMilestoneToProject,
    {
      mutationKey: MutationKeyEnum.DRAW_REQUEST_ADD_ITEM,
      onSuccess: () => {
        update();
      },
      onError: (error) => {
        enqueueSnackbar(error.message, { variant: 'error' });
      },
    },
  );

  const addLineList = useCallback(
    () => addLineListItemMutation.mutateAsync({ project: projectId }),
    [addLineListItemMutation],
  );

  const patchMilestoneMutation = useMutation<Response, ErrorDual, PatchListItemParam>(
    pathMilestoneItem,
    {
      mutationKey: MutationKeyEnum.MILESTONE_PATCH,
      onError: (error) => {
        enqueueSnackbar(parsePathErrorDual(error), { variant: 'error' });
      },
      onSuccess: () => {
        update();
      },
    },
  );

  const patchMilestone = useCallback(
    (args: PatchListItem) =>
      patchMilestoneMutation.mutate({
        project: projectId,
        milestone: args.milestone,
        json: args.json,
      }),
    [projectId],
  );

  const isAllNamesNotFill = useMemo(
    () => editMode && !checkIsAllNamesFillInProject(projectMilestones.data?.results),
    [projectMilestones.data, editMode],
  );

  // bulk milestones delete
  const deleteBudgetModal = useConfirmationModal();

  const deleteBudgetMutation = useMutation<
    Response,
    Error,
    {
      projectId: string;
    }
  >(deleteProjectMilestones, {
    mutationKey: MutationKeyEnum.MILESTONE_DELETE,
    onSuccess: () => {
      update();
      deleteBudgetModal.closeConfirmModal();
      setEditMode(false);
    },
    onError: (error) => {
      enqueueSnackbar(error.message, { variant: 'error' });
    },
  });

  const deleteBudget = async () =>
    deleteBudgetMutation.mutateAsync({
      projectId,
    });

  const deleteMilestoneMutation = useMutation<Response, ErrorDual, DeleteListItemParam>(
    deleteMilestoneItem,
    {
      onSuccess: () => {
        update();
      },
      onError: (error) => {
        enqueueSnackbar(parsePathErrorDual(error), { variant: 'error' });
        update();
      },
    },
  );

  const deleteMilestone = useCallback(
    (milestone: string) =>
      deleteMilestoneMutation.mutate({
        project: projectId,
        milestone,
      }),
    [projectId],
  );

  const isCreateMode = useMemo(
    () =>
      !editMode &&
      projectMilestones.isSuccess &&
      !milestones?.length &&
      isCreatedProject(project.data?.status),
    [editMode, projectMilestones.isSuccess, milestones, project.data],
  );

  const updateListData = useCallback(
    (updater) =>
      queryClient.setQueriesData(
        {
          queryKey: [QueryNamesEnums.GET_PROJECT_MILESTONES, { projectId, query }],
          exact: false,
        },
        updater,
      ),
    [queryClient, projectId, query],
  );

  const dynamicColumns = useMemo(() => {
    const permissionRelatedColumns = [
      {
        column_name: 'approved_amount',
        type: 'regex',
        validators: { expression: REGEX.positiveCurrency },
        info_hint: 'cumulative approved amount for historical DR',
      },
      {
        column_name: 'adjustments',
        type: 'regex',
        validators: {
          expression: REGEX.positiveAndNegativeCurrency,
        },
        info_hint: 'cumulative adjustments amount for historical DR',
      },
      {
        column_name: 'inspector_allowance_rate',
        type: 'number',
        validators: {
          min_value: 0,
          max_value: 100,
          allow_commas: false,
        },
      },
    ];

    // project__create__button is permission for lender and he has possibility to create historical draws
    // borrower can have only project__budget__edit permission and he can't create historical draws
    if (!isRestricted(PermissionNamesEnums.PROJECT__CREATE__BUTTON, permissions))
      return permissionRelatedColumns;

    return [];
  }, [permissions]);

  const isOwner = useMemo(() => checkIsOwner(teamRole), [teamRole]);

  return {
    state: getHookState(projectMilestones),
    totals:
      filterValue === LineItemFilterValues.ALL.filterValue
        ? projectMilestones.data?.totals
        : undefined,
    handleFiltersChange: (value) => handleFilterClick(value),
    filterOptions: map(filterOptions, 'filterValue'),
    initColumns,
    milestones,
    editMode,
    addLineList,
    patchMilestone,
    isAllNamesNotFill,
    editBudget: () => setEditMode((data) => !data),
    showEdit: isCreatedProject(project.data?.status) && !drawRequests.data?.results?.length,
    createMode: isCreateMode,
    update,
    deleteBudgetModal,
    deleteBudget,
    deleteMilestone,
    filterValue,
    updateListData,
    dynamicColumns,
    isOwner,
  };
};
