import React, { useCallback, useEffect, useMemo } from 'react';
import { styled } from '@mui/material/styles';
import Viewer from 'react-viewer';

import { ComponentProps } from './interface';

const PREFIX = 'Gallery';

const classes = {
  root: `${PREFIX}-root`,
};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled('div')(() => ({
  [`& .${classes.root}`]: {
    '& .react-viewer-navbar': {
      '& .react-viewer-list': {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginLeft: '0 !important',
        height: 140,
        '& li': {
          width: 120,
          height: 125,
          '& img': {
            width: 100,
            height: 125,
          },
        },
      },
    },
  },
}));

const Gallery = ({ files, close, startIndex, container }: ComponentProps) => {
  const [visible, setVisible] = React.useState(true);

  useEffect(() => {
    setVisible(true);
  }, [files]);

  const imagesForViewer = useMemo(
    () => files.map((item) => ({ src: item.file_url, downloadUrl: item.file_url })),
    [files],
  );

  const closeViewer = useCallback(() => {
    setVisible(false);
    close();
  }, []);

  if (!files) return null;

  return (
    <Root>
      <Viewer
        container={container}
        visible={visible}
        onClose={closeViewer}
        images={imagesForViewer}
        rotatable={true}
        scalable={false}
        downloadable={false}
        zoomSpeed={0.2}
        activeIndex={startIndex || 0}
        zIndex={9999}
        className={classes.root}
        noToolbar={Boolean(container)}
        noFooter={Boolean(container)}
        noClose={Boolean(container)}
        drag={!container}
      />
    </Root>
  );
};

export default Gallery;
