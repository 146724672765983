import React, { FC } from 'react';
import { Box, Divider, Skeleton, Stack, Typography } from '@mui/material';
import snakeCase from 'lodash/snakeCase';
import {
  BarChart,
  LabelAndValue,
  LoadingSkeleton,
  PieChartItem,
  ServiceMessage,
  StyledBox,
} from '@components';
import { HookState, IProjectProgressItem } from '@interfaces';
import { useParams } from 'react-router-dom';
import { colors } from '@theme';
import { currencyFormatter, percentFormatter } from '@utils';
import DrawPaymentInfo from '../DrawPaymentInfo';
import { getLabels, usePaymentGraph } from './controller';
import { useLaunchDarklyFlags } from '@context';

const PaymentGraphs = () => {
  const { drawRequestId: isRequestTab } = useParams();
  const flags = useLaunchDarklyFlags();

  const controller = usePaymentGraph();
  const labels = getLabels(Boolean(isRequestTab));

  const {
    state,
    currentScheduledValue,
    originalScheduledValue,
    constructionHoldback,
    additionalBorrowerEquity,
    originalBorrowerEquity,
    revisedValuePieChartData,
    originalValuePieChartData,
  } = controller;

  switch (state) {
    case HookState.FETCHING: {
      return (
        <>
          <StyledBox>
            <Skeleton />
          </StyledBox>
          <Divider sx={{ my: '24px' }} />
          <LoadingSkeleton type="twoBlocks" />
        </>
      );
    }
    case HookState.ERROR: {
      return <ServiceMessage text="audit log" />;
    }

    case HookState.SUCCESS: {
      return (
        <Stack
          direction="row"
          sx={{
            mt: 2,
            flexWrap: { lg: 'nowrap', xs: 'wrap' },
          }}
        >
          <StyledBox sx={{ width: '100%' }}>
            <Typography variant="h3">{labels.progress}</Typography>
            <Divider sx={{ mt: 3 }} />
            {flags['ENG_7417_show_additional_equity_in_new_payment_tab'] && (
              <BarChartItem
                item={currentScheduledValue}
                label={labels.scheduledValue}
                subLabel="(Construction holdback + Original borrower equity + Additional borrower equity)"
                beforeValueColor={colors.main.primary.main}
                totalValueColor={colors.main.primary.lightest}
                afterValueColor={colors.main.primary.light}
                source="payments_tab__current_scheduled_value"
              />
            )}
            <BarChartItem
              item={originalScheduledValue}
              label="Original schedule of values"
              subLabel="(Construction holdback + Original borrower equity)"
              beforeValueColor={colors.status.success.medium}
              totalValueColor={colors.status.success.lighter}
              afterValueColor={colors.status.success.light}
              source="payments_tab__original_scheduled_value"
            />
            <Divider sx={{ mt: 3 }} />
            <BarChartItem
              item={constructionHoldback}
              label="Construction holdback"
              beforeValueColor={colors.status.information.medium}
              afterValueColor={colors.status.information.light}
              totalValueColor={colors.status.information.lighter}
              source="payments_tab__construction_holdback"
            />
            <BarChartItem
              item={originalBorrowerEquity}
              label="Original borrower equity"
              beforeValueColor={colors.status.orange.medium}
              afterValueColor={colors.status.orange.light}
              totalValueColor={colors.status.orange.lighter}
              source="payments_tab__original_borrower_equity"
            />
            {flags['ENG_7417_show_additional_equity_in_new_payment_tab'] && (
              <BarChartItem
                item={additionalBorrowerEquity}
                label="Additional borrower equity"
                beforeValueColor={colors.status.violet.medium}
                afterValueColor={colors.status.violet.light}
                totalValueColor={colors.status.violet.lighter}
                source="payments_tab__additional_borrower_equity"
              />
            )}
          </StyledBox>
          <Stack sx={{ width: '100%', ml: { lg: 2, xs: 0 }, mt: { lg: 0, xs: 2 } }}>
            <StyledBox sx={{ flexGrow: 1 }}>
              <Typography variant="h3" data-cy="payments_tab__graphs__pie_chart__label">
                {labels.budget}
              </Typography>
              {isRequestTab ? (
                <PieChartItem data={originalValuePieChartData} />
              ) : (
                <>
                  {flags['ENG_7417_show_additional_equity_in_new_payment_tab'] && (
                    <>
                      <Divider sx={{ my: 3 }} />
                      <Typography variant="h4" sx={{ mb: 2 }}>
                        Current schedule of values
                      </Typography>
                      <PieChartItem data={revisedValuePieChartData} />
                    </>
                  )}
                  <Divider sx={{ my: 3 }} />
                  <Typography variant="h4" sx={{ mb: 2 }}>
                    Original schedule of values
                  </Typography>
                  <PieChartItem data={originalValuePieChartData} />
                </>
              )}
            </StyledBox>
            {isRequestTab && <DrawPaymentInfo />}
          </Stack>
        </Stack>
      );
    }
    default:
      return null;
  }
};

const BarChartItem: FC<{
  item: Partial<IProjectProgressItem>;
  label: string;
  subLabel?: string;
  beforeValueColor: string;
  totalValueColor: string;
  afterValueColor?: string;
  source?: string;
}> = ({ item, label, subLabel, beforeValueColor, totalValueColor, afterValueColor, source }) => {
  const { drawRequestId: isRequestTab } = useParams();

  if (!item?.total) return null;
  return (
    <Stack>
      <BarChart
        stacked
        orientation="horizontal"
        values={[
          item?.before_value,
          ...(isRequestTab ? [item?.after_value - item?.before_value] : []),
        ]}
        total={item?.total}
        barWidth={24}
        colors={[beforeValueColor, ...(isRequestTab ? [afterValueColor] : []), totalValueColor]}
        legendPosition="top"
        borderRadius="2px"
        CustomLegend={
          <Stack
            sx={{
              width: '100%',
              flexDirection: 'row',
              justifyContent: 'space-between',
              mb: 1,
              mt: 3,
            }}
          >
            <Stack>
              <Typography variant="h4">{label}</Typography>
              {subLabel && (
                <Typography variant="label" color={colors.main.primary.main}>
                  {subLabel}
                </Typography>
              )}
            </Stack>
            <Typography variant="h4" data-cy={`payments_tab__graphs__${snakeCase(label)}__value`}>
              {currencyFormatter(item?.total)}
            </Typography>
          </Stack>
        }
        tooltipTitle={
          <TooltipContent
            label={label}
            item={item}
            contributedColor={beforeValueColor}
            remainingColor={totalValueColor}
            currentColor={afterValueColor}
          />
        }
        source={source}
      />
    </Stack>
  );
};

const TooltipContent: FC<{
  label: string;
  contributedColor: string;
  remainingColor: string;
  currentColor: string;
  item: Partial<IProjectProgressItem>;
}> = ({ label, item, contributedColor, remainingColor, currentColor }) => {
  const { drawRequestId: isRequestTab } = useParams();
  const labels = getLabels(Boolean(isRequestTab));

  return (
    <Stack sx={{ minWidth: 360 }}>
      <Stack direction="row" justifyContent="space-between" sx={{ mb: 2 }}>
        <Typography variant="h4">{label}</Typography>
        <Typography variant="body2SemiBold">{currencyFormatter(item?.total)}</Typography>
      </Stack>
      <Stack spacing={1}>
        {isRequestTab && (
          <LabelAndValue
            label="Current draw"
            text={`${currencyFormatter(
              item?.after_value - item?.before_value,
            )} / ${percentFormatter({
              value: item?.after - item?.before,
              roundTo: 2,
            })}`}
            icon={
              <Box
                sx={{
                  width: 16,
                  height: 16,
                  background: currentColor,
                  borderRadius: '2px',
                }}
              />
            }
            iconPosition="left"
            textDataTestName={`payments_tab__graphs__${snakeCase(label)}__current_draw__value`}
          />
        )}

        <LabelAndValue
          label={labels.contributed}
          text={`${currencyFormatter(item?.before_value)} / ${percentFormatter({
            value: item?.before,
            roundTo: 2,
          })}`}
          icon={
            <Box
              sx={{
                width: 16,
                height: 16,
                background: contributedColor,
                borderRadius: '2px',
              }}
            />
          }
          iconPosition="left"
          textDataTestName={`payments_tab__graphs__${snakeCase(label)}__contributed__value`}
        />
        <LabelAndValue
          label={labels.remaining}
          text={`${currencyFormatter(
            isRequestTab ? item?.total - item?.after_value : item?.total - item?.before_value,
          )} / ${percentFormatter({
            value: isRequestTab ? 100 - item?.after : 100 - item?.before,
            roundTo: 2,
          })}`}
          icon={
            <Box
              sx={{
                width: 16,
                height: 16,
                background: remainingColor,
                borderRadius: '2px',
              }}
            />
          }
          iconPosition="left"
          textDataTestName={`payments_tab__graphs__${snakeCase(label)}__remaining__value`}
        />
      </Stack>
    </Stack>
  );
};

export default PaymentGraphs;
