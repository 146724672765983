import { format } from 'date-fns';
import { GridColDef } from '@mui/x-data-grid';
import { getRoundNumber } from './decorators';
import isNumber from 'lodash/isNumber';
import { PatchListItem } from '@interfaces';
import { colors } from '@theme';

interface PercentFormatterProp {
  value: number;
  roundTo?: number;
  returnValue?: string;
}

export const dateFormatter = ({ date, withTime = false, onlyTime = false }) => {
  if (!date) return null;

  /*
  if it's 'date only' data (like '2022-06-01') we need to avoid the next problem
  ISO 8601 is the international standard for the representation of dates and times.
  The ISO 8601 syntax (YYYY-MM-DD) is also the preferred JavaScript date format:
    const d = new Date("2015-03-25");
  The computed date will be relative to your time zone.
  Depending on your time zone, the result above will vary between March 24 and March 25.

  But when we pass with comma list of the year, month and day it's the same for any time zone
  */

  // date regex for 'yyyy-mm-dd'
  const singleDateRegex = /^\d{4}-(0[1-9]|1[012])-(0[1-9]|[12]\d|3[01])$/;

  if (date.toString().match(singleDateRegex)) {
    const dateArray = date.split('-');
    const toJSMonth = (value) => +(value.charAt(0) === '0' ? value.charAt(1) : value) - 1;
    const formattedDate = new Date(dateArray[0], toJSMonth(dateArray[1]), dateArray[2]);

    return format(formattedDate, 'MMM dd, yyyy');
  }

  if (withTime) return format(new Date(date), 'MMM dd, yyyy h:mm aa zzz');
  if (onlyTime) return format(new Date(date), 'h:mm aa zzz');
  return format(new Date(date), 'MMM dd, yyyy');
};

export const formatAccNumber = (accNumber: string) => `#XXXXXX${accNumber?.slice(-4)}`;

export const percentFormatter = ({
  value,
  roundTo = 2,
  returnValue = '-',
}: PercentFormatterProp) => {
  if (value === null && returnValue) return returnValue;
  const result = getRoundNumber(value, roundTo);
  return result >= 0 ? `${result}%` : `-${Math.abs(result)}%`;
};

export const currencyFormatter = (value: string | number, returnValue: string | null = null) => {
  if (!isNumber(value) || isNaN(+value)) return returnValue;

  const result = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  }).format(Math.abs(+value));
  return +value >= 0 ? result : `-${result}`;
};

export const getBasicUrl = ({
  requestType,
  projectId,
  requestId,
  milestoneId,
  inspectionId,
  documentId,
  isPHBProject = false,
}: {
  requestType?: string;
  projectId: string;
  requestId?: string;
  milestoneId?: string;
  inspectionId?: string;
  documentId?: string;
  isPHBProject?: boolean;
}) => {
  const basicUrl = `projects/${projectId}`;

  if (isPHBProject && milestoneId) return `${basicUrl}/milestone_groups/${milestoneId}/comments/`;

  if (requestType === 'post' && milestoneId && inspectionId)
    return `${basicUrl}/inspections/${inspectionId}/milestones/${milestoneId}/comments/`;

  if (requestType === 'post' && requestId && milestoneId)
    return `${basicUrl}/draw_requests/${requestId}/milestones/${milestoneId}/comments/`;

  if (milestoneId) return `${basicUrl}/milestones/${milestoneId}/comments/`;

  if (inspectionId) return `${basicUrl}/inspections/${inspectionId}/comments/`;

  if (documentId) return `${basicUrl}/documents/${documentId}/comments/`;

  if (requestId) return `${basicUrl}/draw_requests/${requestId}/comments/`;

  return `${basicUrl}/comments/`;
};

export const getDrawCellKey = (vars: PatchListItem): string =>
  `${vars.milestone}-${Object.keys(vars.json).join('')}`;

export const formatError = (error: string) => {
  return `<span style='color: ${colors.status.information.medium}'><b>${error}</b></span>`;
};

export const phoneFormatter = (phoneNumber) => {
  // Ensure the phone number starts with +1 and has 11 digits
  if (phoneNumber?.startsWith('+1') && phoneNumber?.length === 12) {
    const areaCode = phoneNumber.slice(2, 5);
    const centralOfficeCode = phoneNumber.slice(5, 8);
    const lineNumber = phoneNumber.slice(8, 12);
    return `(${areaCode}) ${centralOfficeCode}-${lineNumber}`;
  } else {
    console.log('Invalid phone number format. Expected +1XXXXXXXXXX');
    return phoneNumber;
  }
};

export const convertReactTableColumnsToMuiDataGridColumns = (oldColumns): GridColDef[] => {
  return oldColumns.map((oldCol) => {
    const { Header, accessor, disableFilters, disableSortBy, Cell, ...rest } = oldCol;

    const newCol = {
      field: accessor || '',
      headerName: Header || '',
      sortable: !disableSortBy,
      filterable: !disableFilters,
      minWidth: 120,
      flex: 1,
      ...rest,
    };

    if (Cell) {
      newCol.renderCell = (params) => {
        const row = { original: params.row };
        const value = params.value;
        return Cell({ row, cell: { value } });
      };
    }

    return newCol;
  });
};

export const transformObjectToFalseKeysArray = (obj) => {
  return Object.entries(obj)
    .filter(([_key, value]) => !value)
    .map(([key]) => key);
};
