import { colors } from '@theme';
import {
  ProjectStatusEnum,
  UserStatusDisplayEnum,
  InspectionStatusEnum,
  RequestCombinedStatus,
} from '@interfaces';
import { DrawRequestListStatusMap, ProjectStatusMap, inspectionStatusMap } from '@constants';

// statuses for draw requests, payments, users, project
const statusMap = (value, staticStatuses, type) => {
  if (!staticStatuses) return {};
  const map = {
    draw_request: {
      IN_PROGRESS: {
        text: staticStatuses.draw_request['IN_PROGRESS'],
        color: colors.secondaryLight,
      },
      IN_REVIEW: {
        text: staticStatuses.draw_request['IN_REVIEW'],
        color: colors.status.information.medium,
        backgroundColor: colors.status.information.lighter,
      },
      APPROVED: {
        text: staticStatuses.draw_request['APPROVED'],
        color: colors.status.success.medium,
        backgroundColor: colors.status.success.lighter,
      },
      COMPLETED: {
        text: staticStatuses.draw_request['COMPLETED'],
        color: colors.text.medium,
        backgroundColor: colors.neutral.lighter,
      },
      REJECTED: {
        text: staticStatuses.draw_request['REJECTED'],
        color: colors.status.error.medium,
        backgroundColor: colors.status.error.lighter,
      },
      DRAFT: {
        text: staticStatuses.draw_request['DRAFT'],
        color: colors.neutral.dark,
        backgroundColor: colors.neutral.lightest,
      },
      REVISED: {
        text: value,
        color: colors.status.warning.medium,
        backgroundColor: colors.status.warning.lighter,
      },
      SUBMITTED: {
        text: value,
        color: colors.status.success.medium,
        backgroundColor: colors.status.success.lighter,
      },
      FLAGGED: {
        text: value,
        color: colors.status.error.medium,
        backgroundColor: colors.status.error.lighter,
      },
      // syntetic statuses
      ON_HOLD: {
        text: 'On hold',
        color: colors.status.error.medium,
        backgroundColor: colors.status.error.lighter,
      },
      [RequestCombinedStatus.AWAITING_YOU]: {
        text: DrawRequestListStatusMap[RequestCombinedStatus.AWAITING_YOU],
        color: colors.status.information.medium,
        backgroundColor: colors.status.information.lighter,
      },
      [RequestCombinedStatus.AWAITING_OTHERS]: {
        text: DrawRequestListStatusMap[RequestCombinedStatus.AWAITING_OTHERS],
        color: colors.status.information.medium,
        backgroundColor: colors.status.information.lighter,
      },
    },
    users: {
      [UserStatusDisplayEnum.ACTIVE]: {
        color: colors.status.success.medium,
        backgroundColor: colors.status.success.lighter,
      },
      [UserStatusDisplayEnum.AWAITING_INVITE]: {
        color: colors.status.information.medium,
        backgroundColor: colors.status.information.lighter,
      },
      [UserStatusDisplayEnum.INVITED]: {
        color: colors.status.information.medium,
        backgroundColor: colors.status.information.lighter,
      },
      [UserStatusDisplayEnum.INACTIVE]: {
        color: colors.text.medium,
        backgroundColor: colors.background.gray,
      },
    },
    project: {
      [ProjectStatusEnum.ACTIVE]: {
        color: colors.status.success.medium,
        backgroundColor: colors.status.success.lighter,
        text: ProjectStatusMap[value],
      },
      [ProjectStatusEnum.CREATED]: {
        color: colors.status.information.medium,
        backgroundColor: colors.status.information.lighter,
        text: ProjectStatusMap[value],
      },
      [ProjectStatusEnum.INACTIVE_COMPLETE]: {
        color: colors.text.medium,
        backgroundColor: colors.neutral.lightest,
        text: ProjectStatusMap[value],
      },
      [ProjectStatusEnum.INACTIVE_INCOMPLETE]: {
        color: colors.text.medium,
        backgroundColor: colors.neutral.lightest,
        text: ProjectStatusMap[value],
      },
      [ProjectStatusEnum.PAUSED]: {
        color: colors.status.error.medium,
        backgroundColor: colors.status.error.lighter,
        text: ProjectStatusMap[value],
      },
    },
    inspection: {
      [InspectionStatusEnum.CREATED]: {
        color: colors.text.medium,
        backgroundColor: colors.neutral.lightest,
        text: inspectionStatusMap[InspectionStatusEnum.CREATED],
      },
      [InspectionStatusEnum.PENDING]: {
        color: colors.text.medium,
        backgroundColor: colors.status.information.lightest,
        text: inspectionStatusMap[InspectionStatusEnum.PENDING],
      },
      [InspectionStatusEnum.ORDERED]: {
        color: colors.status.information.medium,
        backgroundColor: colors.status.information.lighter,
        text: inspectionStatusMap[value],
      },
      [InspectionStatusEnum.COMPLETED]: {
        color: colors.status.violet.medium,
        backgroundColor: colors.status.violet.lighter,
        text: inspectionStatusMap[value],
      },
      [InspectionStatusEnum.ERROR]: {
        color: colors.status.error.medium,
        backgroundColor: colors.status.error.lighter,
        text: inspectionStatusMap[value],
      },
      [InspectionStatusEnum.CANCELLED]: {
        color: colors.status.error.medium,
        backgroundColor: colors.status.error.lighter,
        text: inspectionStatusMap[value],
      },
      [InspectionStatusEnum.CANCEL_REQUESTED]: {
        color: colors.status.error.medium,
        backgroundColor: colors.status.error.lighter,
        text: inspectionStatusMap[value],
      },
    },
  };

  if (!map[type])
    return {
      text: '',
      color: colors.secondary,
    };

  if (!map[type][value])
    return {
      text: value,
      color: colors.secondary,
    };

  return map[type][value];
};

export default statusMap;
