import React, { FC } from 'react';
import { Divider, Stack } from '@mui/material';
import { IDocument, IProjectDocument, MediaFile } from '@interfaces';
import { DocumentSummary, LabelAndValue } from '@components';
import { documentCanBeProcessed } from '@utils';

const DocumentSummaryList: FC<{
  projectName: string;
  source: string;
  rows?: IProjectDocument[] | IDocument[];
  documentId?: string;
  openPdfViewer?: (file: IProjectDocument | MediaFile) => void;
}> = ({ projectName, source, rows = [], documentId, openPdfViewer }) => {
  const docsToDisplay = documentId ? rows.filter((doc) => doc.id === documentId) : rows;
  return (
    <Stack sx={{ px: 2 }}>
      <LabelAndValue
        label="Project:"
        text={projectName}
        size="body2"
        justifyContent="flex-start"
        marginRightAfterLabel={false}
      />
      {docsToDisplay.map((row: IProjectDocument | IDocument, index) => {
        return (
          <Stack key={row.id}>
            <DocumentSummary
              row={row as IProjectDocument}
              source={`${source}__item__index_${index}`}
              canBeProcessed={documentCanBeProcessed(row)}
              openPdfViewer={openPdfViewer}
            />
            <Divider sx={{ pt: 2 }} />
          </Stack>
        );
      })}
    </Stack>
  );
};

export default DocumentSummaryList;
