import { useEffect, useMemo, useState } from 'react';
import { useQueries, useQuery } from 'react-query';
import _ from 'lodash';

import { useDropdownFieldModel, useStringFieldModel } from '@models';
import {
  DocumentTypeEnum,
  EnumTypeForList,
  IProject,
  IProjectDetailsFields,
  IProjectProperty,
  IPropertyDetailLocal,
  QueryNamesEnums,
} from '@interfaces';
import { regexValidation } from '@utils';
import { states } from '@constants';
import {
  getProjectBuildings,
  getProjectDocumentTypeByName,
  getProjectExitStrategiesList,
  getProjectPropertyDetailsNames,
  getProjectPropertyTypes,
  getProjectTypes,
} from '@globalService';

interface HookInterface {
  projectDetailsFields: IProjectDetailsFields;
  isProjectDetailsUpdated: boolean;
  exitStrategiesList: EnumTypeForList[];
  projectTypesList: EnumTypeForList[];
  propertyTypesList: EnumTypeForList[];
  propertyDetailsNames: EnumTypeForList[];
  projectProperty: IProjectProperty;
  propertyDetails: {
    update: (key: keyof IPropertyDetailLocal, value: string | number, index: number) => void;
    delete: (index: number) => void;
    add: () => void;
    names: EnumTypeForList[];
    list: IPropertyDetailLocal[];
    isEmptyDetailExist: boolean;
    hasDetailsDuplicates: boolean;
    id: string;
    isChanged: boolean;
  };
  isAllProjectDataValid: boolean;
}

export const useProjectDetailsFields = ({ project }: { project: IProject }): HookInterface => {
  const requestedDataQueries = useQueries([
    {
      queryKey: [QueryNamesEnums.GET_PROJECT_TYPES],
      queryFn: getProjectTypes.bind(this, ''),
    },
    {
      queryKey: [QueryNamesEnums.GET_PROJECT_PROPERTY_TYPES],
      queryFn: getProjectPropertyTypes.bind(this, ''),
    },
    {
      queryKey: [QueryNamesEnums.GET_PROJECT_PROPERTY_DETAILS_NAMES],
      queryFn: getProjectPropertyDetailsNames,
    },
    {
      queryKey: [QueryNamesEnums.GET_PROJECT_EXIT_STRATEGIES],
      queryFn: getProjectExitStrategiesList,
    },
    {
      queryKey: [QueryNamesEnums.GET_PROJECT_DOCUMENT_THUMB_TYPES],
      queryFn: getProjectDocumentTypeByName.bind(this, DocumentTypeEnum.THUMB),
    },
  ]);

  const projectTypesQuery = requestedDataQueries[0].data;
  const propertyTypesQuery = requestedDataQueries[1].data;
  const propertyDetailsNamesQuery = requestedDataQueries[2].data;
  const exitStrategiesQuery = requestedDataQueries[3].data;

  const scopeOfWork = useStringFieldModel({
    initValue: project.scope_of_work,
  });

  const projectName = useStringFieldModel({
    initValue: project.name,
    validationRule: (value) => Boolean(value?.trim()),
  });

  // project type
  const projectType = useDropdownFieldModel({
    initValue: null,
    validationRule: (value) => Boolean(value?.id),
  });
  useEffect(() => {
    if (projectTypesQuery?.results) {
      const projectTypeValue = _.find(projectTypesQuery.results, { name_display: project.type });
      projectType.setInitValue(projectTypeValue);
    }
  }, [projectTypesQuery]);

  // exit strategy
  const exitStrategy = useDropdownFieldModel({
    initValue: null,
  });
  useEffect(() => {
    if (exitStrategiesQuery?.results) {
      const exitStrategyValue = _.find(exitStrategiesQuery.results, {
        name_display: project.exit_strategy,
      });
      exitStrategy.setInitValue(exitStrategyValue);
    }
  }, [exitStrategiesQuery]);

  // property types
  const existingPropertyType = useDropdownFieldModel({
    initValue: null,
    validationRule: (value) => Boolean(value?.id),
  });
  const proposedPropertyType = useDropdownFieldModel({
    initValue: null,
    validationRule: (value) => Boolean(value?.id),
  });
  useEffect(() => {
    if (propertyTypesQuery?.results) {
      const existingPropertyTypeValue = _.find(propertyTypesQuery.results, {
        name_display: project.property_existing_type,
      });
      existingPropertyTypeValue && existingPropertyType.setInitValue(existingPropertyTypeValue);

      const proposedPropertyTypeValue = _.find(propertyTypesQuery.results, {
        name_display: project.property_proposed_type,
      });
      proposedPropertyTypeValue && proposedPropertyType.setInitValue(proposedPropertyTypeValue);
    }
  }, [propertyTypesQuery]);

  // address
  const address_1 = useStringFieldModel({
    initValue: project.address?.address_1 || '',
    validationRule: (value) => Boolean(value?.trim()),
    validateOnChange: true,
  });
  const city = useStringFieldModel({
    initValue: project.address?.city || '',
    validationRule: (value) => Boolean(value?.trim()),
    validateOnChange: true,
  });
  const state = useDropdownFieldModel({
    initValue: project.address?.state
      ? states.find((state) => state.name === project.address?.state)
      : null,
    validationRule: (value) => Boolean(value?.name),
  });
  const zipCode = useStringFieldModel({
    initValue: project.address?.zip_code || '',
    validationRule: (value) => regexValidation('zip_code', value) && Boolean(value),
    validateOnChange: true,
  });

  // property details names
  const [propertyDetailsLocal, setPropertyDetailsLocal] = useState<IPropertyDetailLocal[]>([]);

  const projectPropertyQuery = useQuery<{ results: IProjectProperty[]; count: number }, Error>(
    [QueryNamesEnums.GET_PROJECT_BUILDING, { projectId: project?.id }],
    getProjectBuildings.bind(this, project?.id),
    { enabled: Boolean(project?.id) },
  );

  useEffect(() => {
    if (projectPropertyQuery.data?.results.length && propertyDetailsNamesQuery?.results?.length) {
      setPropertyDetailsLocal(
        projectPropertyQuery.data?.results[0]?.details.map((detail) => ({
          ...detail,
          id: propertyDetailsNamesQuery.results.find((item) => item.name === detail.name)?.id,
        })),
      );
    }
  }, [projectPropertyQuery.data?.results, propertyDetailsNamesQuery?.results]);

  const updatePropertyDetail = (
    key: keyof IPropertyDetailLocal,
    value: string | number,
    index: number,
  ) =>
    setPropertyDetailsLocal((details) =>
      Object.assign([...details], { [index]: { ...details[index], [key]: value } }),
    );

  const isEmptyDetailExist = useMemo(
    () =>
      propertyDetailsLocal.some(
        (detail) => !detail.name || !detail.existing_value || !detail.proposed_value,
      ),
    [propertyDetailsLocal],
  );

  const deletePropertyDetail = (index: number) =>
    setPropertyDetailsLocal(propertyDetailsLocal.filter((details, i) => i !== index));

  const hasDetailsDuplicates = useMemo(
    () => _.sortedUniqBy(propertyDetailsLocal, 'name')?.length !== propertyDetailsLocal.length,
    [propertyDetailsLocal],
  );

  const addPropertyDetail = () => {
    if (isEmptyDetailExist) return;
    setPropertyDetailsLocal((details) => [
      {
        name_display: '',
        name: '',
        existing_value: '',
        proposed_value: '',
      },
      ...details,
    ]);
  };

  // Comparator function that omits the 'id' property from comparison
  const compareWithoutId = (a, b) => {
    if (_.isObject(a) && _.isObject(b)) {
      return _.isEqual(_.omit(a, 'id'), _.omit(b, 'id'));
    }
  };

  // Function to compare two arrays of objects ignoring the 'id' property and order
  const areArraysEquivalent = (arr1, arr2) => {
    // Sort both arrays by their properties (excluding 'id') to ensure order doesn't affect comparison
    const sortedArr1 = _.sortBy(
      arr1?.map((item) => _.omit(item, 'id')),
      Object.keys,
    );
    const sortedArr2 = _.sortBy(
      arr2?.map((item) => _.omit(item, 'id')),
      Object.keys,
    );

    // Compare the sorted arrays with the custom comparator
    return _.isEqualWith(sortedArr1, sortedArr2, compareWithoutId);
  };

  const isPropertyDetailsChanged = useMemo(
    () =>
      !_.isEqualWith(
        propertyDetailsLocal,
        projectPropertyQuery.data?.results?.[0]?.details,
        areArraysEquivalent,
      ),
    [propertyDetailsLocal, projectPropertyQuery.data?.results],
  );

  // check if project details are updated
  const isProjectDetailsUpdated = useMemo(
    () =>
      [
        scopeOfWork,
        projectName,
        exitStrategy,
        projectType,
        existingPropertyType,
        proposedPropertyType,
        address_1,
        city,
        state,
        zipCode,
      ].some((field) => field.isChanged) || isPropertyDetailsChanged,
    [
      scopeOfWork.isChanged,
      projectName.isChanged,
      exitStrategy.isChanged,
      projectType.isChanged,
      existingPropertyType.isChanged,
      proposedPropertyType.isChanged,
      address_1.isChanged,
      city.isChanged,
      state.isChanged,
      zipCode.isChanged,
      isPropertyDetailsChanged,
    ],
  );

  // exepting property details (isEmptyDetailExist, hasDetailsDuplicates) from the comparison
  // their errors are displayed in snackbar because they cann't be errored in red during validation
  const isAllProjectDataValid = useMemo(
    () =>
      [
        scopeOfWork,
        projectName,
        exitStrategy,
        projectType,
        existingPropertyType,
        proposedPropertyType,
        address_1,
        city,
        state,
        zipCode,
      ].every((field) => field.isValid),
    [
      scopeOfWork.isValid,
      projectName.isValid,
      exitStrategy.isValid,
      projectType.isValid,
      existingPropertyType.isValid,
      proposedPropertyType.isValid,
      address_1.isValid,
      city.isValid,
      state.isValid,
      zipCode.isValid,
    ],
  );
  return {
    projectDetailsFields: {
      projectName,
      scopeOfWork,
      exitStrategy,
      projectType,
      existingPropertyType,
      proposedPropertyType,
      address_1,
      city,
      state,
      zipCode,
    },
    exitStrategiesList: exitStrategiesQuery?.results || [],
    projectTypesList: projectTypesQuery?.results || [],
    propertyTypesList: propertyTypesQuery?.results || [],
    propertyDetailsNames: propertyDetailsNamesQuery?.results,
    projectProperty: projectPropertyQuery.data?.results?.[0],
    isProjectDetailsUpdated,
    propertyDetails: {
      update: updatePropertyDetail,
      delete: deletePropertyDetail,
      add: addPropertyDetail,
      names: propertyDetailsNamesQuery?.results,
      list: propertyDetailsLocal,
      isEmptyDetailExist,
      hasDetailsDuplicates,
      id: projectPropertyQuery.data?.results?.[0]?.id,
      isChanged: isPropertyDetailsChanged,
    },
    isAllProjectDataValid,
  };
};
