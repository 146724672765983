import React, { FC } from 'react';
import { useMilestoneListPatch } from './controller';
import { MilestoneListWithPatchInterface } from './interface';
import { MilestoneList } from '@components';

const MilestoneListWithPatch: FC<MilestoneListWithPatchInterface> = ({
  requestId,
  refetch,
  containerRef,
  ...props
}) => {
  const { patchMilestone, resetMutation, deleteMilestone, updateListData } = useMilestoneListPatch({
    requestId,
    refetch,
  });
  return (
    <MilestoneList
      refetch={refetch}
      deleteMilestone={deleteMilestone}
      patchMilestone={patchMilestone}
      resetMutation={resetMutation}
      updateData={updateListData}
      containerRef={containerRef}
      {...props}
    />
  );
};

export default MilestoneListWithPatch;
