import React, { SyntheticEvent, useContext, useEffect, useMemo, useState } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useLocation, useNavigate } from 'react-router-dom';
import { isEqual } from 'date-fns';
import { Button, IconButton, Skeleton, Stack } from '@mui/material';
import { GridColumnVisibilityModel } from '@mui/x-data-grid';

import {
  checkIsCustomerSuccess,
  enhanceItemsWithCommentsPreview,
  findLastCompletedInspectionDate,
  getHookState,
  getInspectionName,
  getTeamRole,
  getTooltipText,
  isActiveInspection,
  isCompletedInspection,
  isCreatedInspection,
  isCreatedProject,
  isInspectionServiceManual,
  isOrderedInspection,
  tableHeaders as headers,
  WithPermission,
  isAutomatedInspection,
} from '@utils';
import {
  CommonInspectionPayload,
  DeleteInspectionDocument,
  HookState,
  IDrawRequest,
  IInspection,
  InspectionActionEnum,
  IProject,
  MediaFile,
  PermissionNamesEnums,
  QueryNamesEnums,
  IRightMenu,
  TabSwitcherType,
  TableKeyEnum,
} from '@interfaces';
import { AuthContext, SettingsContext } from '@context';
import {
  useCommentsPreview,
  useConfirmationModal,
  useImagePicker,
  useRightMenu,
  useSafeSnackbar,
  useColumnFilterV2,
} from '@hooks';
import {
  CommentIconWithIndicator,
  DeleteIcon,
  DocumentsIconWithIndicator,
  DownloadIcon,
  EditIcon,
} from '@svgAsComponents';
import {
  cancelInspection,
  deleteInspection,
  deleteInspectionDocument,
  getDrawRequest,
  getDrawRequestInspectionsList,
  getProject,
  getProjectInspectionsList,
} from '@globalService';
import { TOOLTIP_TEXTS } from '@constants';
import { IconButtonWithTooltip } from '@components';
import get from 'lodash/get';

enum ActionTypeEnum {
  DELETE_INSPECTION_DOC = 'delete_inspection_doc',
  CANCEL_INSPECTION = 'cancel_inspection',
  DELETE_INSPECTION = 'delete_inspection',
}

export interface ControllerInterface {
  state: HookState;
  rows: IInspection[];
  columns: object[];
  pdf: MediaFile[];
  gallery: MediaFile[];
  close: () => void;
  isConfirmModalOpened: boolean;
  modalText: { title: string; text: string };
  askConfirm: (info: object) => void;
  closeConfirmModal: () => void;
  confirmCallback: () => void;
  rightDrawerParams: { inspectionId: string; projectId: string };
  rightMenu: IRightMenu;
  isUpdating: boolean;
  inspectionCadence: number;
  TABS: { [key: string]: TabSwitcherType };
  tab: string;
  handleTabChange: (event: SyntheticEvent<Element, Event>, value: string) => void;
  hiddenColumns: string[];
  setColumnVisibilityModel: (newModel: GridColumnVisibilityModel) => void;
  isColumnFilterUpdating: boolean;
}

const TABS = {
  documents: { label: 'Documents', value: 'documents' },
  comments: { label: 'Comments', value: 'comments' },
};

export const useServices = (projectId: string, drawRequestId: string): ControllerInterface => {
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSafeSnackbar();
  const { user } = useContext(AuthContext);
  const teamRole = getTeamRole(user);
  const { isCurrentProjectArchived } = useContext(SettingsContext);
  const navigate = useNavigate();
  const location = useLocation();
  const [tab, setTab] = useState(TABS.documents.value);

  const handleTabChange = (_event: React.SyntheticEvent, newValue: string) => {
    setTab(newValue);
  };

  const [rightDrawerParams, setRightDrawerParams] = useState<{ inspectionId: string }>({
    inspectionId: '',
  });

  const { hiddenColumns, setColumnVisibilityModel, isColumnFilterUpdating } = useColumnFilterV2(
    TableKeyEnum.PROJECT_SERVICES,
  );

  const { updateCommentsPreviewInfo } = useCommentsPreview({
    projectId,
    ...(drawRequestId ? { drawRequestId } : {}),
    inspectionId: rightDrawerParams.inspectionId,
  });

  const allInspectionsQuery = useQuery<{ results: IInspection[] }, Error>(
    [QueryNamesEnums.GET_PROJECT_INSPECTIONS, { projectId }],
    getProjectInspectionsList.bind(this, { projectId }),
    { enabled: Boolean(projectId) },
  );

  const drawRequestInspectionsQuery = useQuery<{ results: IInspection[] }, Error>(
    [QueryNamesEnums.GET_DRAW_REQUEST_INSPECTIONS, { projectId, drawRequestId }],
    getDrawRequestInspectionsList.bind(this, {
      projectId,
      drawRequestId,
    }),
    { enabled: Boolean(drawRequestId) },
  );

  // get comments for inspections
  const queryComments = `{id,comments_preview}`;
  const drawRequestInspectionsCommentsQuery = useQuery<{ results: IInspection[] }, Error>(
    [
      QueryNamesEnums.GET_DRAW_REQUEST_INSPECTIONS,
      { projectId, drawRequestId, query: queryComments },
    ],
    getDrawRequestInspectionsList.bind(this, { projectId, drawRequestId, query: queryComments }),
    { enabled: Boolean(drawRequestId) },
  );
  const projectInspectionsCommentsQuery = useQuery<{ results: IInspection[] }, Error>(
    [QueryNamesEnums.GET_PROJECT_INSPECTIONS, { projectId, query: queryComments }],
    getProjectInspectionsList.bind(this, { projectId, query: queryComments }),
    { enabled: Boolean(projectId && !drawRequestId) },
  );

  // DR to detect his status (active or not)
  const drawRequestQuery = useQuery<IDrawRequest, Error>(
    [QueryNamesEnums.GET_DRAW_REQUEST, { projectId, drawRequestId }],
    getDrawRequest.bind(this, { projectId, drawRequestId }),
    { enabled: Boolean(drawRequestId) },
  );

  const projectQuery = useQuery<IProject, Error>(
    [QueryNamesEnums.GET_PROJECT, { projectId }],
    getProject.bind(this, projectId),
    { enabled: !!projectId },
  );

  const { isConfirmModalOpened, askConfirm, closeConfirmModal } = useConfirmationModal();

  const { pdf, gallery, close } = useImagePicker();

  const [activeInspection, setActiveInspection] = useState(null);
  const [actionType, setActionType] = useState('');
  const [modalText, setModalText] = useState({ title: '', text: '' });

  // to clear selected activeInspection on tab change
  useEffect(() => {
    setActiveInspection(null);
  }, [drawRequestId]);

  // delete DR Inspection Report
  const deleteDrawRequestInspectionDocument = useMutation<
    Response,
    Error,
    DeleteInspectionDocument
  >(deleteInspectionDocument, {
    onSuccess: () => {
      // Query Invalidations to refetch DR inspections list
      queryClient.invalidateQueries(QueryNamesEnums.GET_DRAW_REQUEST_INSPECTIONS);
    },
    onError: (error) => {
      enqueueSnackbar(error.message, { variant: 'error' });
    },
  });

  const deleteInspectionMutation = useMutation<Response, Error, CommonInspectionPayload>(
    deleteInspection,
    {
      onSuccess: () => {
        queryClient.invalidateQueries([
          QueryNamesEnums.GET_DRAW_REQUEST_INSPECTIONS,
          { projectId, drawRequestId },
        ]);
        queryClient.invalidateQueries([QueryNamesEnums.GET_PROJECT_INSPECTIONS, { projectId }]);
      },
      onError: (error) => {
        enqueueSnackbar(error.message, { variant: 'error' });
      },
    },
  );

  const isLoading = useMemo(
    () => (drawRequestId ? drawRequestInspectionsQuery.isLoading : allInspectionsQuery.isLoading),
    [allInspectionsQuery, drawRequestInspectionsQuery],
  );

  const inspectionsList = useMemo(() => {
    const results = get(drawRequestInspectionsQuery, 'data.results', []);
    const projectInspections = get(allInspectionsQuery, 'data.results', []);
    const requestComments = get(drawRequestInspectionsCommentsQuery, 'data.results', []);
    const projectComments = get(projectInspectionsCommentsQuery, 'data.results', []);

    if (!results.length && !projectInspections.length) return [];

    // Enhance project inspections with comments
    const projectInspectionsSet = new Set(projectInspections.map((o) => o.draw_request));
    const enhancedProjectInspections = Array.from(projectInspectionsSet).flatMap((drawRequestId) =>
      enhanceItemsWithCommentsPreview(
        projectInspections.filter((b) => b.draw_request === drawRequestId),
        projectComments,
      ),
    );

    // Enhance request inspections with comments
    const enhancedRequestInspections = enhanceItemsWithCommentsPreview(results, requestComments);

    return (drawRequestId ? enhancedRequestInspections : enhancedProjectInspections)?.filter(
      (o) => !isCreatedInspection(o.status),
    );
  }, [
    drawRequestInspectionsQuery.data,
    allInspectionsQuery.data,
    drawRequestId,
    drawRequestQuery?.data,
    drawRequestInspectionsCommentsQuery.data,
    projectInspectionsCommentsQuery.data,
  ]);

  const lastCompletedInspection = useMemo(() => {
    const filteredArr =
      allInspectionsQuery.data?.results?.filter(({ status }) => isCompletedInspection(status)) ||
      [];
    const lastCompletedDate = findLastCompletedInspectionDate(filteredArr);

    return filteredArr.find(({ completed_at }) =>
      isEqual(new Date(completed_at), lastCompletedDate),
    );
  }, [allInspectionsQuery.data]);

  const lastInactiveInspection = useMemo(
    () => allInspectionsQuery.data?.results?.find((item) => !isActiveInspection(item.status)),
    [allInspectionsQuery.data?.results],
  );

  const { handleRightDrawerOpenerClick, ...rightMenu } = useRightMenu({
    onClose: updateCommentsPreviewInfo,
  });

  const updateRightDrawer =
    ({ inspection, tab }) =>
    () => {
      setTab(tab);
      handleRightDrawerOpenerClick({
        title: getInspectionName(inspection),
      });

      setRightDrawerParams({ inspectionId: inspection?.id });
    };
  const cancelInspectionMutation = useMutation<Response, Error, CommonInspectionPayload>(
    cancelInspection,
    {
      onSuccess: () => {
        queryClient.invalidateQueries([
          QueryNamesEnums.GET_DRAW_REQUEST_INSPECTIONS,
          { projectId, drawRequestId },
        ]);
        queryClient.invalidateQueries([QueryNamesEnums.GET_PROJECT_INSPECTIONS, { projectId }]);
      },
      onError: (error) => {
        enqueueSnackbar(error.message, { variant: 'error' });
      },
    },
  );

  const shouldShowDeleteButton = (inspectionId) =>
    lastInactiveInspection?.id === inspectionId &&
    (checkIsCustomerSuccess(teamRole) || isCreatedProject(projectQuery.data?.status));

  const icons = () => ({
    Header: '',
    accessor: 'icons',
    disableFilters: true,
    disableSortBy: true,
    maxWidth: 100,
    Cell: ({ row }) => {
      if (isLoading) return <Skeleton />;

      const { comments_preview } = row?.original || {};

      return (
        <>
          <WithPermission permissionKey={PermissionNamesEnums.DRAW_REQUEST_INSPECTION_INFO}>
            <IconButton
              onClick={updateRightDrawer({ inspection: row?.original, tab: TABS.comments.value })}
              data-cy="services__list__comments__icon"
            >
              <CommentIconWithIndicator
                hasComments={comments_preview?.has_comments}
                hasUnreadComments={comments_preview?.has_unread_comments}
              />
            </IconButton>
          </WithPermission>

          <IconButton
            onClick={updateRightDrawer({ inspection: row?.original, tab: TABS.documents.value })}
            data-cy={`services__list__documents__icon`}
          >
            <DocumentsIconWithIndicator size={22} />
          </IconButton>
        </>
      );
    },
  });

  const actions = () => ({
    Header: 'Actions',
    accessor: 'actions',
    disableFilters: true,
    disableSortBy: true,
    minWidth: 220,
    flex: 0,
    hidable: false,
    Cell: ({ row }) => {
      if (isLoading) return <Skeleton />;

      const { report, status, id: inspectionId, inspection_agency } = row?.original || {};
      const navigateToInspection = (action: InspectionActionEnum) =>
        navigate(`/projects/${projectId}/inspection/${row?.original?.id}/${action}`, {
          state: location.pathname,
        });

      const isInspectionServiceAutomated = isAutomatedInspection(inspection_agency?.service);

      return (
        <Stack spacing={2} direction="row">
          {isOrderedInspection(status) && isInspectionServiceManual(inspection_agency?.service) && (
            <WithPermission permissionKey={PermissionNamesEnums.PROJECT__INSPECTION__ENTER_RESULTS}>
              <Button
                size="small"
                variant="new"
                color="secondary"
                onClick={() => navigateToInspection(InspectionActionEnum.EDIT)}
                disabled={isCurrentProjectArchived}
                data-cy="services__list__enter_results__button"
                sx={{ minWidth: '110px' }}
              >
                Enter results
              </Button>
            </WithPermission>
          )}

          {isOrderedInspection(status) && (
            <WithPermission permissionKey={PermissionNamesEnums.PROJECT__INSPECTION__CREATE}>
              <Button
                size="small"
                color="error"
                onClick={() => {
                  setModalText({
                    title: isInspectionServiceAutomated
                      ? 'Request inspection cancellation'
                      : 'Cancel inspection',
                    text: isInspectionServiceAutomated
                      ? 'Are you sure you want to request the cancellation of this inspection?'
                      : 'Are you sure you want to cancel this inspection?',
                  });
                  setActionType(ActionTypeEnum.CANCEL_INSPECTION);
                  askConfirm();
                }}
                disabled={isCurrentProjectArchived}
                data-cy="services__list__cancel_inspection__button"
                sx={{ minWidth: isInspectionServiceAutomated ? '155px' : 'auto' }}
              >
                {isInspectionServiceAutomated ? 'Request cancellation' : 'Cancel'}
              </Button>
            </WithPermission>
          )}
          {report?.link && (
            <WithPermission permissionKey={PermissionNamesEnums.DRAW_REQUEST_INSPECTION_INFO}>
              <a href={report.link} download>
                <IconButton data-cy="services__list__download_result__icon">
                  <DownloadIcon size={20} />
                </IconButton>
              </a>
            </WithPermission>
          )}

          {inspectionId === lastCompletedInspection?.id && (
            <WithPermission permissionKey={PermissionNamesEnums.PROJECT__INSPECTION__ENTER_RESULTS}>
              <IconButtonWithTooltip
                onClick={() => navigateToInspection(InspectionActionEnum.EDIT)}
                tooltipText={getTooltipText({ isCurrentProjectArchived })}
                disabled={isCurrentProjectArchived}
                data-cy="services__list__edit_inspection__icon"
              >
                <EditIcon size={20} />
              </IconButtonWithTooltip>
            </WithPermission>
          )}

          {shouldShowDeleteButton(inspectionId) && (
            <WithPermission permissionKey={PermissionNamesEnums.PROJECT__INSPECTION__DELETE}>
              <IconButtonWithTooltip
                onClick={() => {
                  setModalText({
                    title: 'Delete inspection',
                    text: 'Are you sure you want to delete this inspection?',
                  });
                  setActionType(ActionTypeEnum.DELETE_INSPECTION);
                  askConfirm();
                }}
                tooltipText={
                  isCurrentProjectArchived
                    ? TOOLTIP_TEXTS.isCurrentProjectArchived
                    : 'Delete inspection'
                }
                disabled={isCurrentProjectArchived}
                data-cy="services__list__delete_inspection__icon"
              >
                <DeleteIcon size={20} />
              </IconButtonWithTooltip>
            </WithPermission>
          )}
        </Stack>
      );
    },
  });
  const getConfirmCallback = async ({ activeInspection }) => {
    closeConfirmModal();
    setModalText({ title: '', text: '' });
    if (actionType === ActionTypeEnum.DELETE_INSPECTION) {
      await deleteInspectionMutation.mutateAsync({
        projectId,
        inspectionId: activeInspection?.id,
      });
    }
    if (actionType === ActionTypeEnum.DELETE_INSPECTION_DOC) {
      await deleteDrawRequestInspectionDocument.mutateAsync({
        projectId,
        inspectionId: activeInspection?.id,
        documentId: activeInspection?.report?.id,
      });
    }
    if (actionType === ActionTypeEnum.CANCEL_INSPECTION) {
      await cancelInspectionMutation.mutateAsync({
        projectId,
        inspectionId: activeInspection?.id,
      });
    }
    setActiveInspection(null);
    setActionType('');
  };

  const columns = useMemo(
    () => [
      headers.serviceName({
        isLoading,
      }),
      headers.serviceType({
        isLoading,
      }),
      headers.serviceStatus({
        isLoading,
      }),
      headers.inspectionAgencyStatus({
        isLoading,
      }),
      headers.date({
        accessor: 'gc_requested_at',
        header: 'Date requested',
        isLoading,
        disableSortBy: true,
      }),
      headers.scheduledInspectionDate({
        accessor: 'scheduled_at',
        header: 'Date scheduled',
        isLoading,
        disableSortBy: true,
      }),
      headers.date({
        accessor: 'completed_at',
        header: 'Date completed',
        isLoading,
        disableSortBy: true,
      }),
      headers.inspectionServiceColumn({
        isLoading,
        teamRole,
      }),
      headers.headerTemplate({
        headerName: 'External Id',
        accessor: 'provider_order_id',
        isLoading,
        disableSortBy: true,
      }),
      ...(drawRequestId
        ? []
        : [
            headers.drNumber({
              isLoading,
            }),
          ]),
      headers.inspectorAllowanceColumn({
        isLoading,
      }),
      actions(),
      icons(),
    ],
    [
      isLoading,
      drawRequestId,
      lastCompletedInspection,
      lastInactiveInspection,
      projectQuery.data,
      teamRole,
    ],
  );

  return {
    state: drawRequestId
      ? getHookState(drawRequestInspectionsQuery)
      : getHookState(allInspectionsQuery),
    rows: inspectionsList,
    columns,
    pdf,
    gallery,
    close,
    isConfirmModalOpened,
    modalText,
    askConfirm,
    closeConfirmModal,
    confirmCallback: () => getConfirmCallback({ activeInspection }),
    rightDrawerParams: { ...rightDrawerParams, projectId },
    rightMenu,
    isUpdating: drawRequestInspectionsQuery.isFetching || allInspectionsQuery.isFetching,
    inspectionCadence: projectQuery.data?.inspection_cadence,
    TABS,
    tab,
    handleTabChange,
    hiddenColumns,
    setColumnVisibilityModel,
    isColumnFilterUpdating,
  };
};
