import React, { useCallback } from 'react';

export interface ITablePaginationHook {
  onPageChange: (event, newPage) => void;
  onRowsPerPageChange: (event) => void;
  page: number;
  rowsPerPage: number;
  rowsPerPageOptions: number[];
  setPage: React.Dispatch<React.SetStateAction<number>>;
}

export const useTablePagination = (): ITablePaginationHook => {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(50);

  return {
    onPageChange: useCallback((event, newPage) => {
      setPage(newPage);
    }, []),
    onRowsPerPageChange: useCallback((event) => {
      setRowsPerPage(parseInt(event.target.value, 10));
      setPage(0);
    }, []),
    page,
    rowsPerPage,
    rowsPerPageOptions: [50, 75, 100],
    setPage,
  };
};
