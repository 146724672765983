import React, { FC, memo, useContext, useMemo } from 'react';
import ReactDOM from 'react-dom';
import { useParams } from 'react-router-dom';
import { useQuery, useQueryClient } from 'react-query';
import { CommonRowType, MilestoneListColumnType } from './common';
import { TableContext } from '../controller';
import { AddDocumentIcon, AddPhotoIcon, AddProofpointIcon } from '@svgAsComponents';
import {
  ColumnV2Width,
  DocumentContentTypeEnum,
  IDocumentType,
  QueryNamesEnums,
  TransloaditTemplateEnum,
} from '@interfaces';
import { DocumentUploaderWithForm, FilesUploader, VerticalMenu } from '@components';
import { IconButton } from '@mui/material';
import { colors } from '@theme';
import { useCommentsPreview, useFilesUploader } from '@hooks';
import { getDrawRequestDocumentsTypeList, getProjectDocumentTypes } from '@globalService';
import { onlyProgressPhotos, proofpointsQueryFields } from '@constants';
import { SettingsContext, useLaunchDarklyFlags } from '@context';

const DocumentsPhotosUploaderMenu: FC<{ row: CommonRowType }> = ({ row }) => {
  const { source } = useContext(TableContext);
  const { projectId, requestId } = useParams();
  const queryClient = useQueryClient();
  const { isPHBProject } = useContext(SettingsContext);
  const flags = useLaunchDarklyFlags();

  const { updateCommentsPreviewInfo } = useCommentsPreview({
    projectId,
    drawRequestId: requestId,
    milestoneId: row.id,
  });

  const {
    isFilesUploaderOpened,
    transloaditSignature,
    uploadMedia,
    filesUploaderClose,
    restrictions,
    refetchCallback,
  } = useFilesUploader();
  const [modalType, setModalType] = React.useState<TransloaditTemplateEnum>(null);

  const projectDocumentTypesQuery = useQuery<IDocumentType[], Error>(
    [QueryNamesEnums.GET_PROJECT_DOCUMENT_TYPES],
    getProjectDocumentTypes.bind(this),
  );
  const drawRequestDocumentsTypesQuery = useQuery<IDocumentType[], Error>(
    [QueryNamesEnums.GET_DRAW_REQUEST_DOCUMENTS_TYPES],
    getDrawRequestDocumentsTypeList.bind(this),
  );
  const getContentType = () => {
    if (isPHBProject) return DocumentContentTypeEnum.MILESTONE_GROUP;
    if (row.milestone_submit_id) return DocumentContentTypeEnum.MILESTONE_SUBMIT;
    return DocumentContentTypeEnum.MILESTONE;
  };

  const menuItems = useMemo(
    () => [
      {
        action: () => {
          setModalType(TransloaditTemplateEnum.DOCUMENTS);
          uploadMedia({
            fields: {
              content_type: getContentType(),
              object_id: row.milestone_submit_id || row.id,
            },
            templateType: TransloaditTemplateEnum.DOCUMENTS,
          });
        },
        text: 'Upload document(s)',
        icon: <AddDocumentIcon />,
        dataTestName: `${source}__body__documents_uploader__menu_item`,
      },
      ...(isPHBProject
        ? []
        : [
            {
              action: () => {
                setModalType(TransloaditTemplateEnum.PROOFPOINTS);
                uploadMedia({
                  fields: {
                    draw_request_id: requestId,
                    project_id: projectId,
                    milestone_id: row.id,
                  },
                  templateType: TransloaditTemplateEnum.PROOFPOINTS,
                });
              },
              text: 'Upload progress photo(s)',
              icon: <AddPhotoIcon color={colors.icons.green} />,
              disabled: !row.canAddPhotos,
              disabledTooltipText: 'You cannot upload progress photos here',
              dataTestName: `${source}__body__photos_uploader__menu_item`,
            },
          ]),
    ],
    [row, projectId, requestId, source, isPHBProject],
  );

  const refetchDocumentsQueries = () => {
    queryClient.invalidateQueries([QueryNamesEnums.GET_PROJECT_DOCUMENTS, { projectId }]);
    if (isPHBProject) {
      queryClient.invalidateQueries([
        QueryNamesEnums.GET_PROJECT_MILESTONE_GROUP_DOCS,
        { projectId, milestoneId: row.id },
      ]);
    } else {
      queryClient.invalidateQueries([
        QueryNamesEnums.GET_PROJECT_MILESTONE_DOCS,
        { projectId, milestoneId: row.id },
      ]);
      updateCommentsPreviewInfo();
    }
    if (requestId && !isPHBProject) {
      queryClient.invalidateQueries([
        QueryNamesEnums.GET_DRAW_REQUEST_DOCUMENTS,
        { projectId, drawRequestId: requestId },
      ]);
      queryClient.invalidateQueries([
        QueryNamesEnums.GET_DRAW_REQUEST_MILESTONE_DOCS,
        { projectId, drawRequestId: requestId, milestoneId: row.id },
      ]);
    }
  };

  const refetchPhotosQueries = () => {
    queryClient.invalidateQueries([QueryNamesEnums.GET_PROJECT_PHOTOS, { projectId }]);
    flags?.['ENG_8203_migrate_proofpoints_to_documents']
      ? queryClient.invalidateQueries([
          QueryNamesEnums.GET_PROJECT_MILESTONE_DOCS,
          { projectId, milestoneId: row.id, query: onlyProgressPhotos },
        ])
      : queryClient.invalidateQueries([
          QueryNamesEnums.GET_PROJECT_MILESTONE,
          { projectId, milestoneId: row.id, query: proofpointsQueryFields },
        ]);
    if (requestId) {
      queryClient.invalidateQueries([
        QueryNamesEnums.GET_DRAW_REQUEST_PHOTOS,
        { projectId, drawRequestId: requestId },
      ]);
      flags?.['ENG_8203_migrate_proofpoints_to_documents']
        ? queryClient.invalidateQueries([
            QueryNamesEnums.GET_DRAW_REQUEST_MILESTONE_DOCS,
            { projectId, drawRequestId: requestId, milestoneId: row.id, query: onlyProgressPhotos },
          ])
        : queryClient.invalidateQueries([
            QueryNamesEnums.GET_DRAW_REQUEST_MILESTONE,
            {
              projectId,
              drawRequestId: requestId,
              milestoneId: row.id,
              query: proofpointsQueryFields,
            },
          ]);
    }
    updateCommentsPreviewInfo();
  };

  return (
    <>
      <VerticalMenu menuItems={menuItems}>
        <IconButton
          data-cy={`${source}__body__photos_uploader__icon__index_${row.project_milestone?.index}`}
        >
          <AddProofpointIcon />
        </IconButton>
      </VerticalMenu>
      {transloaditSignature?.signature && modalType === TransloaditTemplateEnum.DOCUMENTS && (
        <DocumentUploaderWithForm
          isOpen={isFilesUploaderOpened}
          documentTypes={
            requestId ? drawRequestDocumentsTypesQuery.data : projectDocumentTypesQuery.data
          }
          closeUploader={filesUploaderClose}
          transloaditSignature={transloaditSignature}
          refetchCallback={refetchCallback}
          refetch={[() => refetchDocumentsQueries()]}
          restrictions={restrictions}
          source={source}
          drawRequestId={requestId}
        />
      )}
      {isFilesUploaderOpened &&
        transloaditSignature &&
        modalType === TransloaditTemplateEnum.PROOFPOINTS &&
        ReactDOM.createPortal(
          <FilesUploader
            open={isFilesUploaderOpened}
            onClose={() => {
              filesUploaderClose();
            }}
            transloaditSignature={transloaditSignature}
            restrictions={restrictions}
            refetchCallback={refetchCallback}
            refetch={[() => refetchPhotosQueries()]}
            source={source}
          />,
          document.body,
        )}
    </>
  );
};

const Memoized = memo(DocumentsPhotosUploaderMenu);

const documentsPhotosUploaderMenu: MilestoneListColumnType = {
  name: 'documents_photos_uploader_menu',
  columnText: '',
  Header: '',
  justifyContent: 'center',
  noBorder: true,
  noMargin: true,
  renderCell: ({ row }) => <Memoized row={row} />,
  minWidth: () => ColumnV2Width.ICON,
  maxWidth: () => ColumnV2Width.ICON,
};

export default documentsPhotosUploaderMenu;
