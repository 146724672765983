import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { Grid, Stack } from '@mui/material';

import {
  checkIsExternalUser,
  checkIsInvestor,
  checkIsLender,
  getLink,
  getSortQueryString,
  getTeamRole,
  isRestricted,
  tableHeaders,
} from '@utils';
import {
  ColumnsFilter,
  DownloadCSVButton,
  DrawRequestsListFiltersV2,
  FiltersV2,
  ProjectsPortfolioFilters,
  ProjectsPortfolioStats,
  ReactTable,
  ServiceMessage,
  SortingWidget,
  StyledBox,
  TablePagination,
} from '@components';
import { useColumnFilterV2, useMultiFiltering, useSorting, useTablePagination } from '@hooks';
import { AuthContext, PermissionsContext, useLaunchDarklyFlags } from '@context';
import * as Controller from './controller';
import { colors } from '@theme';
import {
  ColumnWidth,
  IProjectsPortfolioItem,
  PermissionNamesEnums,
  QueryNamesEnums,
  TableKeyEnum,
} from '@interfaces';
import { getPortfolioList } from '@globalService';

const ProjectsPortfolio = () => {
  const flags = useLaunchDarklyFlags();
  const navigate = useNavigate();
  const [filterStringQuery, setFilterStringQuery] = useState<string>('');
  const { page, rowsPerPage, rowsPerPageOptions, onPageChange, onRowsPerPageChange } =
    useTablePagination();
  const { handleSortClick, sortValue } = useSorting();
  const { handleFiltersChange, resetFiltersToDefault, filters } = useMultiFiltering(
    TableKeyEnum.RISK_RADAR,
  );

  const [search, setSearch] = useState('');
  const handleSearchSubmit = setSearch;
  const clearSearch = () => setSearch('');

  const params = useMemo(
    () => ({
      pagination: true,
      offset: page * rowsPerPage,
      limit: rowsPerPage,
      sorting: getSortQueryString(sortValue),
      filtering: filters,
      search,
    }),
    [page, rowsPerPage, sortValue, filters, search],
  );

  const {
    data,
    data: { results } = {},
    isLoading,
    isFetching,
    isError,
  } = useQuery<{ results: IProjectsPortfolioItem[]; count: number }, Error>(
    [QueryNamesEnums.GET_PORTFOLIO_LIST, { params, filterStringQuery }],
    getPortfolioList.bind(this, { params, filterStringQuery }),
    {
      keepPreviousData: true,
    },
  );

  function transformScoresToKeys(dataArray) {
    return dataArray.map((data) => {
      if (data?.scores && Array.isArray(data.scores?.scores)) {
        const updatedScores = {};

        data.scores.scores.forEach((score) => {
          if (score?.agent_id) {
            updatedScores[score.agent_id] = score;
          }
        });
        updatedScores[data.scores?.project_score?.agent_id] = data.scores?.project_score;

        data.scores = updatedScores;
      }

      return data;
    });
  }

  const [filteredData, setFilteredData] = useState([]);
  const { hiddenColumns, changeFieldVisibility, isColumnFilterUpdating } = useColumnFilterV2(
    TableKeyEnum.RISK_RADAR,
  );
  const { projectPropertyTypes } = Controller.useProjectFilters();
  const { user } = useContext(AuthContext);
  const { permissions } = useContext(PermissionsContext);

  const teamRole = getTeamRole(user);
  const isLender = useMemo(() => checkIsLender(teamRole), [teamRole]);
  const isInvestor = useMemo(() => checkIsInvestor(teamRole), [teamRole]);
  const isInternalUser = useMemo(() => !checkIsExternalUser(teamRole), [teamRole]);

  useEffect(() => {
    if (results?.length) {
      setFilteredData(transformScoresToKeys(results));
    } else {
      setFilteredData([]);
    }
  }, [results]);

  const handleRowClick = (row) => {
    const link = getLink({ row, tableKey: TableKeyEnum.RISK_RADAR, teamRole });
    link && navigate(link);
  };

  // Array of strings
  // Items Enum: “-created_at” “-name” “-project_type” “-property_type” “-state” “-status” “created_at” “name” “project_type” “property_type” “state” “status”
  const columnsList = {
    property_existing_type: 'Property type (existing)',
    property_proposed_type: 'Property type (proposed)',
    project_type: 'Project type',
    status: 'Status',
    state: 'State',
  };

  const columns = React.useMemo(
    () => [
      tableHeaders.headerTemplate({
        headerName: 'Loan #',
        accessor: 'loan.external_id',
        isLoading,
        disableSortBy: true,
      }),
      tableHeaders.headerTemplate({
        headerName: 'Project name',
        accessor: 'name',
        isLoading,
        minWidth: ColumnWidth.WIDE_TEXT,
      }),
      tableHeaders.headerTemplate({
        headerName: 'Address',
        accessor: 'address.address_1',
        isLoading,
        disableSortBy: true,
      }),
      tableHeaders.headerTemplate({
        headerName: 'City',
        accessor: 'address.city',
        isLoading,
        disableSortBy: true,
      }),
      tableHeaders.state({ isLoading, accessor: 'address.state' }),
      tableHeaders.headerTemplate({
        headerName: 'Project type',
        accessor: 'project_type',
        isLoading,
      }),
      tableHeaders.propertyType({
        header: 'Existing property type',
        isLoading,
        valuesList: projectPropertyTypes,
        accessor: 'property_existing_type',
        minWidth: ColumnWidth.WIDE_TEXT,
      }),
      tableHeaders.propertyType({
        header: 'Proposed property type',
        isLoading,
        valuesList: projectPropertyTypes,
        accessor: 'property_proposed_type',
      }),
      tableHeaders.projectStatus({
        isLoading,
        accessor: 'status',
      }),
      tableHeaders.requestName({ isLoading }),
      tableHeaders.statusWithRole({
        header: 'Request status',
        accessor: 'draw_request.status',
        isLoading,
        riskRadar: true,
      }),
      ...(!isRestricted(PermissionNamesEnums.RISK_RADAR__CUSTOMER__VIEW, permissions)
        ? [
            tableHeaders.headerTemplate({
              headerName: 'Customer',
              accessor: 'customer.name',
              isLoading,
              disableSortBy: true,
            }),
          ]
        : []),
      ...(isLender || isInvestor || isInternalUser
        ? [
            tableHeaders.headerTemplate({
              headerName: 'Borrower',
              accessor: 'borrower_name',
              isLoading,
              disableSortBy: true,
            }),
          ]
        : []),
      ...(isInvestor || isInternalUser
        ? [
            tableHeaders.headerTemplate({
              headerName: 'Lender',
              accessor: 'lender_name',
              isLoading,
              disableSortBy: true,
            }),
          ]
        : []),
      ...(!isRestricted(PermissionNamesEnums.RISK_RADAR__INVESTOR__VIEW, permissions)
        ? [
            tableHeaders.headerTemplate({
              headerName: 'Investor',
              accessor: 'investor_name',
              isLoading,
              disableSortBy: true,
            }),
          ]
        : []),
      ...(!isRestricted(PermissionNamesEnums.REQUESTS__COORDINATOR, permissions)
        ? [
            tableHeaders.headerTemplate({
              headerName: 'Coordinator',
              accessor: 'coordinator.full_name',
              isLoading,
              disableSortBy: true,
            }),
          ]
        : []),
      tableHeaders.amount({
        header: 'Loan commitment',
        accessor: 'loan.loc_commitment',
        isLoading,
        disableSortBy: true,
      }),
      tableHeaders.amount({
        header: 'Original scheduled value ($)',
        accessor: 'project_funds.original_estimate',
        isLoading,
        disableSortBy: true,
      }),
      tableHeaders.amount({
        header: 'Revised scheduled value ($)',
        accessor: 'project_funds.revised_estimate',
        isLoading,
        disableSortBy: true,
        minWidth: ColumnWidth.WIDE_TEXT,
      }),
      tableHeaders.amount({
        header: 'Total construction holdback ($)',
        accessor: 'construction_holdback_total',
        isLoading,
        disableSortBy: true,
        minWidth: ColumnWidth.WIDE_TEXT,
      }),
      tableHeaders.amount({
        header: 'Disbursed construction holdback ($)',
        accessor: 'construction_holdback_current',
        isLoading,
        disableSortBy: true,
        minWidth: ColumnWidth.WIDE_TEXT,
      }),
      tableHeaders.amount({
        header: 'Remaining construction holdback ($)',
        accessor: 'construction_holdback_available',
        isLoading,
        disableSortBy: true,
        minWidth: ColumnWidth.WIDE_TEXT,
      }),
      tableHeaders.date({
        header: 'Funding date',
        accessor: 'loan.close_date',
        isLoading,
        disableSortBy: true,
      }),
      tableHeaders.date({
        header: 'Original maturity date',
        accessor: 'loan.maturity_date',
        isLoading,
        disableSortBy: true,
      }),
      tableHeaders.date({
        header: 'Extended maturity date ',
        accessor: 'loan.extended_maturity_date',
        isLoading,
        disableSortBy: true,
      }),
      tableHeaders.headerTemplate({
        isLoading,
        headerName: 'Length of project (month)',
        accessor: 'duration',
        disableSortBy: true,
        minWidth: ColumnWidth.WIDE_TEXT,
      }),
      tableHeaders.percentage({
        accessor: 'inspector_allowance_rate',
        header: 'Inspector allowance (%)',
        isLoading,
        disableSortBy: true,
        minWidth: ColumnWidth.TEXT,
      }),
      tableHeaders.percentage({
        accessor: 'lender_allowance_rate',
        header: 'Lender allowance (%)',
        isLoading,
        disableSortBy: true,
        minWidth: ColumnWidth.TEXT,
      }),
      tableHeaders.percentage({
        accessor: 'gap',
        header: 'Variance to lender allowance (%)',
        isLoading,
        disableSortBy: true,
        minWidth: ColumnWidth.WIDE_TEXT,
      }),
      ...(!isRestricted(PermissionNamesEnums.RISK_RADAR__SCORES__VIEW, permissions)
        ? [
            tableHeaders.projectScore({
              headerName: 'Project health',
              accessor: 'scores.overall_score',
              isLoading,
            }),
            tableHeaders.projectScore({
              headerName: 'Budget health',
              accessor: 'scores.budget_score',
              isLoading,
              size: 'small',
            }),
            tableHeaders.projectScore({
              headerName: 'Policy risk',
              accessor: 'scores.policy_score',
              isLoading,
              size: 'small',
            }),
            tableHeaders.projectScore({
              headerName: 'Schedule health',
              accessor: 'scores.schedule_score',
              isLoading,
              size: 'small',
            }),
            tableHeaders.projectScore({
              headerName: 'Borrower risk',
              accessor: 'scores.borrower_score',
              isLoading,
              size: 'small',
            }),
          ]
        : []),
    ],
    [open, hiddenColumns, filteredData, isLoading, permissions],
  );

  const csvHeaders = React.useMemo(
    () =>
      columns.map((column) => ({
        label: column.Header,
        key: column.accessor.includes('scores') ? `${column.accessor}.color` : column.accessor,
      })),
    [columns],
  );

  return (
    <Stack sx={{ height: '100%' }}>
      <Stack sx={{ backgroundColor: colors.background.gray, flexGrow: 1, p: { md: 3, xs: 2 } }}>
        <ProjectsPortfolioStats filtering={filters} />
        <StyledBox sx={{ p: 0 }}>
          <Grid
            container
            direction="row"
            flexWrap="nowrap"
            justifyContent="space-between"
            spacing={1}
          >
            <Grid item>
              {flags?.['ENG_7702_filters_v2'] ? (
                <FiltersV2
                  source="risk_radar"
                  setFilterStringQuery={setFilterStringQuery}
                  LeftComponent={DrawRequestsListFiltersV2}
                  filters={[
                    'textSearchFilter',
                    'propertyExistingTypeFilter',
                    'propertyProposedTypeFilter',
                    'projectTypeFilter',
                    'projectStatusFilter',
                    'inverstorFilter',
                    'lenderFilter',
                    'borrowerFilter',
                    'customerFilter',
                    'projectHealthFilter',
                    'budgetHealthFilter',
                    'policyScoreFilter',
                    'scheduleScoreFilter',
                    'borrowerScoreFilter',
                  ]}
                />
              ) : (
                <ProjectsPortfolioFilters
                  handleFiltersChange={handleFiltersChange}
                  filters={filters}
                  resetFiltersToDefault={resetFiltersToDefault}
                  handleSearchSubmit={handleSearchSubmit}
                  clearSearch={clearSearch}
                  search={search}
                />
              )}
            </Grid>
            <Grid item mr={2} mt={1}>
              <Stack flexDirection="row" sx={{ alignItems: 'center' }}>
                <Stack mr={1}>
                  <ColumnsFilter
                    columns={columns}
                    hiddenColumns={hiddenColumns}
                    changeFieldVisibility={changeFieldVisibility}
                    isUpdating={isColumnFilterUpdating}
                    source="risk_radar__table"
                  />
                </Stack>
                <Stack mr={1}>
                  <SortingWidget
                    columnsList={columnsList}
                    sortValue={sortValue}
                    handleSortClick={handleSortClick}
                  />
                </Stack>
                <Stack>
                  <DownloadCSVButton
                    data={filteredData}
                    headers={csvHeaders}
                    filename={`risk-radar-list.csv`}
                  />
                </Stack>
              </Stack>
            </Grid>
          </Grid>
          {isError && <ServiceMessage text="project portfolio" />}
          {!isLoading && !isError && !filteredData?.length && (
            <ServiceMessage>There are no loans yet in this category</ServiceMessage>
          )}
          {(isLoading || Boolean(results?.length)) && (
            <>
              <Table
                initialData={filteredData}
                columns={columns}
                onRowClick={handleRowClick}
                initialSortBy={sortValue}
                handleSortClick={handleSortClick}
                isUpdating={isFetching}
                isLoading={isLoading}
                hiddenColumns={hiddenColumns}
              />
              <Grid
                container
                justifyContent="flex-end"
                alignItems="center"
                sx={{ borderTop: `1px solid ${colors.neutral.lighter}` }}
              >
                <TablePagination
                  page={page}
                  rowsPerPage={rowsPerPage}
                  rowsPerPageOptions={rowsPerPageOptions}
                  itemsCount={data?.count}
                  onPageChange={onPageChange}
                  onRowsPerPageChange={onRowsPerPageChange}
                />
              </Grid>
            </>
          )}
        </StyledBox>
      </Stack>
    </Stack>
  );
};

const Table = ({
  initialData,
  columns,
  onRowClick,
  initialSortBy,
  handleSortClick,
  isUpdating,
  isLoading,
  hiddenColumns,
}) => {
  const data = React.useMemo(
    () => (isLoading ? Array(10).fill({}) : initialData),
    [initialData, isLoading],
  );
  const sortData = React.useMemo(() => initialSortBy, [initialSortBy]);

  return (
    <div style={{ opacity: isUpdating ? 0.3 : 1 }}>
      <ReactTable
        data={data}
        columns={columns}
        onRowClick={onRowClick}
        footer={false}
        maxHeight={'calc(100vh - 338px)'}
        minHeight={'calc(100vh - 338px)'}
        hiddenColumns={hiddenColumns}
        dataTestName={status}
        initialSortBy={sortData}
        handleSortClick={handleSortClick}
        manualSortBy
        source="risk_radar__table"
        tableKey={TableKeyEnum.RISK_RADAR}
        showContextMenu
      />
    </div>
  );
};

export default ProjectsPortfolio;
