import React, { createContext, useState, useMemo, Dispatch, SetStateAction } from 'react';

type BlockBy = Record<string, boolean>;

type EngineContextType = {
  blockBy?: BlockBy;
  scrollHeight: number;
  blockedKeys: string[];
  isBlockNavigation: boolean;
  setBlockedBy: Dispatch<SetStateAction<BlockBy>>;
  setScrollHeight: Dispatch<SetStateAction<number>>;
};

const initContext = {
  blockBy: {},
  scrollHeight: 0,
  blockedKeys: [],
  isBlockNavigation: false,
  setBlockedBy: () => null,
  setScrollHeight: () => null,
};

export const EngineContext = createContext<EngineContextType>(initContext);

export const EngineProvider = ({ children }) => {
  const [blockBy, setBlockedBy] = useState<BlockBy>({});
  const [scrollHeight, setScrollHeight] = useState<number>(0);

  const blockedKeys = useMemo(() => {
    return Object.keys(blockBy).filter((key) => blockBy[key]);
  }, [blockBy]);

  const isBlockNavigation = useMemo(
    () => Boolean(Object.values(blockBy || {}).filter((item) => !!item).length),
    [blockBy],
  );

  return (
    <EngineContext.Provider
      value={{
        isBlockNavigation,
        blockedKeys,
        setBlockedBy,
        blockBy,
        scrollHeight,
        setScrollHeight,
      }}
    >
      {children}
    </EngineContext.Provider>
  );
};
