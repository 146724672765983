import Geocode from 'react-geocode';
import { config } from '@config';

Geocode.setApiKey(config.googleMapApiKey);
Geocode.setLanguage('en');

export const getLocationFromAddress = async (address) => {
  try {
    const response = await Geocode.fromAddress(address);
    return response.results[0].geometry.location;
  } catch (err) {
    console.log(err);
  }
};
