import React, { Dispatch, FC } from 'react';
import {
  Checkbox,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { ChecklistItemLocal as ChecklistItem, ChecklistItemReason } from '@interfaces';
import { useMultiSelect } from './controller';

const ChecklistReasonsMultipleSelect: FC<{
  setChecklistReasons: Dispatch<React.SetStateAction<ChecklistItemReason[]>>;
  checklistItem: ChecklistItem;
  reasonsList: { reason: string }[];
  shouldValidate: boolean;
  isRequired: boolean;
  source: string;
}> = ({ setChecklistReasons, checklistItem, reasonsList, shouldValidate, isRequired, source }) => {
  const { otherReason, reasons, handleTextAreaChange, handleSelectChange } = useMultiSelect(
    checklistItem,
    setChecklistReasons,
    reasonsList,
  );

  return (
    <Stack>
      <FormControl
        sx={{ mt: '0.5rem', width: '100%' }}
        required={isRequired}
        error={shouldValidate && reasons.length < 1}
      >
        <InputLabel>Select reason(s)</InputLabel>
        <Select
          multiple
          value={reasons}
          onChange={handleSelectChange}
          renderValue={(selected) => selected.join(', ')}
          input={<OutlinedInput label="Select reason(s)" />}
          data-cy={source}
        >
          {reasonsList?.map((item, index) => (
            <MenuItem key={index} value={item.reason}>
              <Checkbox
                checked={reasons.includes(item.reason)}
                data-cy={`${source}__item__index_${index}`}
              />
              <Typography variant="body2">{item.reason}</Typography>
            </MenuItem>
          ))}
          <MenuItem value="Other">
            <Checkbox
              checked={reasons.includes('Other')}
              data-cy={`${source}__item__index_${reasonsList?.length}`}
            />
            <Typography variant="body2">Other</Typography>
          </MenuItem>
        </Select>
        {shouldValidate && reasons.length < 1 && (
          <FormHelperText>Please, select reason</FormHelperText>
        )}
      </FormControl>
      {reasons.find((reason) => reason === 'Other') ? (
        <TextField
          required={isRequired}
          fullWidth
          variant="outlined"
          value={otherReason.value}
          error={shouldValidate && !otherReason.value}
          helperText={shouldValidate && !otherReason.value && 'Reason description is required'}
          placeholder="Please, describe reason here"
          sx={{ mt: '0.75rem' }}
          onChange={handleTextAreaChange}
          multiline
          rows={3}
        />
      ) : null}
    </Stack>
  );
};

export default ChecklistReasonsMultipleSelect;
