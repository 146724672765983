import { useParams } from 'react-router-dom';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import {
  ChecklistItemLocal,
  IDrawRequest,
  IProjectChecklist,
  IProjectUserData,
  PolicyItemTypesEnum,
  QueryNamesEnums,
} from '@interfaces';
import { getDrawRequest, getDrawRequestItemChecklist, getProjectUserData } from '@globalService';
import {
  checkIsAllNamesFill,
  checkIsReallocateComplete,
  checkIsResubmit,
  checkIsTableEdit,
  checkRequestedRetainageCorrect,
  emptyHTML,
  getCheckListItemsByRole,
  getDefaultChecklistItem,
  getTeamRole,
  isChangeRequest,
  isDocumentPolicyItem,
  isMilestoneFieldsContainErrors,
  isRequestHasChangedValues,
  isRequiredDocumentPolicyItem,
  isTextPolicyItem,
  sortAdditionalRequirementsFirst,
} from '@utils';
import isEmpty from 'lodash/isEmpty';
import each from 'lodash/each';
import { useQuery } from 'react-query';
import {
  IChecklistSections,
  IHandleDoneParams,
  ISectionButtonsClick,
  ISections,
  IValidationController,
} from '../interfaces';
import { AuthContext, SettingsContext } from '@context';
import {
  excludeCommentsWithTotalsAllQueryFields,
  REQUEST_EDITABLE_KEYS,
  SubmissionSectionKeyMap,
  SubmissionSectionTitle,
} from '@constants';
import snakeCase from 'lodash/snakeCase';

export const useValidation = ({
  completionDateValid,
  isInspectionValid,
  handleDone,
}: {
  completionDateValid: boolean;
  isInspectionValid: boolean;
  handleDone: (params: IHandleDoneParams) => void;
}): IValidationController => {
  const { projectId, requestId, action } = useParams();
  const isResubmit = useMemo(() => checkIsResubmit(action), [action]);
  const isEditTable = useMemo(() => checkIsTableEdit(action), [action]);

  const { user } = useContext(AuthContext);
  const { settings } = useContext(SettingsContext);
  const teamRole = getTeamRole(user);
  const [validatedSection, setValidatedSections] = useState<ISections>(null);
  const [validatedChecklist, setValidatedChecklist] = useState<IChecklistSections>(null);

  const drawRequestData = useQuery<IDrawRequest, Error>(
    [
      QueryNamesEnums.GET_DRAW_REQUEST,
      { projectId, drawRequestId: requestId, query: excludeCommentsWithTotalsAllQueryFields },
    ],
    getDrawRequest.bind(this, {
      projectId,
      drawRequestId: requestId,
      query: excludeCommentsWithTotalsAllQueryFields,
    }),
  );

  const projectUserDataQuery = useQuery<IProjectUserData, Error>(
    [QueryNamesEnums.GET_PROJECT_USER_DATA, { projectId }],
    getProjectUserData.bind(this, projectId),
  );

  const checklistQuery = useQuery<IProjectChecklist[], Error>(
    [QueryNamesEnums.GET_DRAW_REQUEST_ITEM_CHECKLIST, { projectId, drawRequestId: requestId }],
    getDrawRequestItemChecklist.bind(this, projectId, requestId),
  );

  const checklistItems = useMemo(() => {
    if (!checklistQuery.data) return [];
    return getCheckListItemsByRole({
      policies: checklistQuery.data,
      teamRole,
      teamId: user?.active_team?.id,
      itemsCanBeDeleted: false,
    });
  }, [checklistQuery.data]);

  const doneSections = useMemo(() => {
    if (!checklistItems) return;
    const sections = {};
    for (const key in SubmissionSectionKeyMap) {
      sections[SubmissionSectionKeyMap[key]] = getDefaultChecklistItem(
        checklistItems,
        PolicyItemTypesEnum[key],
      )?.checked;
    }
    return sections;
  }, [checklistItems]);

  const isSectionSkipped = (sectionKey) =>
    !doneSections[SubmissionSectionKeyMap[sectionKey]] &&
    Boolean(getDefaultChecklistItem(checklistItems, sectionKey)?.exception_reasons?.length);

  const validateItem = useCallback((item: ChecklistItemLocal) => {
    if (isRequiredDocumentPolicyItem(item.type)) {
      return item.documents?.length > 0;
    }
    if (isTextPolicyItem(item.type)) {
      return item.checked || (Boolean(item.note) && !emptyHTML(item.note));
    }
    return true;
  }, []);

  const updateChecklistItem = useCallback(
    (obj) => {
      const item = checklistItems.find((i) => i.id === obj.checklistItem?.id);
      if (item) {
        obj.checklistItem = item;
        obj.isValid = validateItem(obj.checklistItem);
      }
    },
    [checklistItems],
  );

  useEffect(() => {
    if (isEmpty(validatedChecklist)) {
      const checklistForValidation = checklistItems
        ?.filter((item) => item.is_custom)
        .reduce(
          (acc, item) => ({
            ...acc,
            [snakeCase(item.label)]: {
              checklistItem: item,
              isValid: validateItem(item),
              isDone: item.checked,
              isSkipped: !item.checked && Boolean(item.exception_reasons.length),
              isChecklist: true,
            },
          }),
          {},
        ) as IChecklistSections;
      setValidatedChecklist(checklistForValidation);
    } else {
      setValidatedChecklist((prev) => each(prev, updateChecklistItem));
    }
  }, [checklistItems]);

  const isReallocateComplete = useMemo(
    () => checkIsReallocateComplete(drawRequestData.data),
    [drawRequestData.data],
  );

  useEffect(() => {
    if (isEmpty(validatedSection) && drawRequestData.data && doneSections) {
      const drSections = {
        [SubmissionSectionKeyMap[PolicyItemTypesEnum.DRAW_REQUEST_FORM]]: {
          title: SubmissionSectionTitle[PolicyItemTypesEnum.DRAW_REQUEST_FORM],
          isValid: false,
          isDone:
            isResubmit ||
            doneSections[SubmissionSectionKeyMap[PolicyItemTypesEnum.DRAW_REQUEST_FORM]],
        },
        [SubmissionSectionKeyMap[PolicyItemTypesEnum.INSPECTION_ORDERING]]: {
          title: SubmissionSectionTitle[PolicyItemTypesEnum.INSPECTION_ORDERING],
          isValid: true,
          isDone:
            isResubmit ||
            doneSections[SubmissionSectionKeyMap[PolicyItemTypesEnum.INSPECTION_ORDERING]],
          isSkipped: isSectionSkipped(PolicyItemTypesEnum.INSPECTION_ORDERING),
        },
        [SubmissionSectionKeyMap[PolicyItemTypesEnum.COMPLETION_DATE_RENEWAL]]: {
          title: SubmissionSectionTitle[PolicyItemTypesEnum.COMPLETION_DATE_RENEWAL],
          isValid: completionDateValid,
          isDone:
            isResubmit ||
            doneSections[SubmissionSectionKeyMap[PolicyItemTypesEnum.COMPLETION_DATE_RENEWAL]],
          isSkipped: isSectionSkipped(PolicyItemTypesEnum.COMPLETION_DATE_RENEWAL),
        },
      };
      const crSections = {
        line_items_table: {
          title: SubmissionSectionTitle[PolicyItemTypesEnum.DRAW_REQUEST_FORM],
          isValid: false,
          isDone: false,
        },
      };
      setValidatedSections(() =>
        validateSections(isChangeRequest(drawRequestData.data) ? crSections : drSections),
      );
    }
  }, [drawRequestData.data, settings, doneSections, completionDateValid]);

  useEffect(() => {
    if (isEmpty(validatedSection)) return;
    setValidatedSections((prev) => validateSections(prev));
  }, [
    drawRequestData.data,
    isReallocateComplete,
    projectUserDataQuery.data,
    completionDateValid,
    isInspectionValid,
    doneSections,
  ]);

  const validateSections = (sections) => {
    const isDrawValid =
      checkRequestedRetainageCorrect(drawRequestData.data) &&
      checkIsAllNamesFill(drawRequestData.data) &&
      isRequestHasChangedValues(drawRequestData.data) &&
      isReallocateComplete &&
      !isMilestoneFieldsContainErrors(REQUEST_EDITABLE_KEYS, drawRequestData.data);

    return {
      ...sections,
      line_items_table: {
        title: SubmissionSectionTitle[PolicyItemTypesEnum.DRAW_REQUEST_FORM],
        isValid: isDrawValid,
        isDone:
          ((doneSections[SubmissionSectionKeyMap[PolicyItemTypesEnum.DRAW_REQUEST_FORM]] !==
          undefined
            ? doneSections[SubmissionSectionKeyMap[PolicyItemTypesEnum.DRAW_REQUEST_FORM]]
            : sections.line_items_table.isDone) &&
            isDrawValid) ||
          isResubmit,
      },
      ...(sections.inspection && {
        inspection: {
          title: SubmissionSectionTitle[PolicyItemTypesEnum.INSPECTION_ORDERING],
          isValid: isInspectionValid,
          isDone:
            doneSections[SubmissionSectionKeyMap[PolicyItemTypesEnum.INSPECTION_ORDERING]] !==
            undefined
              ? doneSections[SubmissionSectionKeyMap[PolicyItemTypesEnum.INSPECTION_ORDERING]]
              : sections.inspection.isDone && isInspectionValid,
          isSkipped: isSectionSkipped(PolicyItemTypesEnum.INSPECTION_ORDERING),
        },
      }),
      ...(sections.completion && {
        completion: {
          title: SubmissionSectionTitle[PolicyItemTypesEnum.COMPLETION_DATE_RENEWAL],
          isValid: completionDateValid,
          isDone:
            (doneSections[SubmissionSectionKeyMap[PolicyItemTypesEnum.COMPLETION_DATE_RENEWAL]] !==
            undefined
              ? doneSections[SubmissionSectionKeyMap[PolicyItemTypesEnum.COMPLETION_DATE_RENEWAL]]
              : sections.completion.isDone) && completionDateValid,
          isSkipped: isSectionSkipped(PolicyItemTypesEnum.COMPLETION_DATE_RENEWAL),
        },
      }),
    };
  };

  const isAllDone = useMemo(() => {
    if (validatedSection)
      return Object.values({ ...validatedSection, ...validatedChecklist }).every(
        (item) => item?.isDone || item?.isSkipped,
      );
  }, [validatedSection, validatedChecklist]);

  const undoneSections = useMemo(() => {
    if (!validatedSection || !checklistItems) return [];

    const standardItems = checklistItems.reduce((acc, item) => {
      if (item.is_custom) {
        return acc;
      }
      const section = validatedSection[SubmissionSectionKeyMap[item.type]];
      if (!section?.isDone && !section?.isSkipped) {
        acc.push({
          title: item.label,
          itemRef: item.itemRef,
          id: item.id,
          is_submitted: item.is_submitted,
        });
      }
      return acc;
    }, []);

    const checklist = Object.values(validatedChecklist).reduce((acc, item) => {
      if (item && !item.isDone && !item.isSkipped && item.checklistItem) {
        acc.push({
          title: item.checklistItem.label,
          itemRef: item.checklistItem.itemRef,
          id: item.checklistItem.id,
          is_submitted: item.checklistItem.is_submitted,
        });
      }
      return acc;
    }, []);

    return [...standardItems, ...checklist].sort(sortAdditionalRequirementsFirst);
  }, [validatedSection, validatedChecklist, checklistItems]);

  const onSectionButtonsClick = ({ sectionKey, key, isChecklist }: ISectionButtonsClick) => {
    isChecklist
      ? setValidatedChecklist((prev) => ({
          ...prev,
          [sectionKey]: {
            ...prev[sectionKey],
            [key]: !prev[sectionKey]?.[key],
            ...(!prev[sectionKey]?.isDone && !prev[sectionKey]?.isSkipped ? { isValid: true } : {}),
          },
        }))
      : setValidatedSections((prev) => ({
          ...prev,
          [sectionKey]: { ...prev?.[sectionKey], [key]: !prev?.[sectionKey]?.[key] },
        }));
  };

  const barChartInfo = useMemo(() => {
    const sectionsArr = Object.values({ ...validatedSection, ...validatedChecklist });
    const allSteps = sectionsArr.length;
    let completedSteps = 0;
    sectionsArr.forEach((obj) => {
      if (obj.isSkipped || obj.isDone) {
        completedSteps++;
      }
    });
    const incompletedSteps = allSteps - completedSteps;
    return {
      completedSteps,
      incompletedSteps,
      allSteps,
    };
  }, [validatedSection, validatedChecklist]);

  const handleNextClick = () => {
    setValidatedChecklist((prev) => ({
      ...each(prev, (obj) => {
        obj.highlightBorder = true;
      }),
    }));
    setValidatedSections((prev) => ({
      ...each(prev, (obj) => {
        obj.highlightBorder = true;
      }),
    }));
  };

  const onUploadComplete = (checklistItemId: string) => {
    const checklistItem = checklistItems.find((item) => item.id === checklistItemId);
    if (!checklistItem || !isDocumentPolicyItem(checklistItem?.type)) return;

    const sectionKey = snakeCase(checklistItem.label);
    const validateItem = validatedChecklist[sectionKey];
    if (validateItem.isDone) return;

    if (handleDone) {
      handleDone({
        checklistItemId,
        shouldComplete: true,
      });
    }
    onSectionButtonsClick({ sectionKey, key: 'isDone', isChecklist: true });
  };

  // mark item as undone if all documents are deleted
  const onDocumentDelete = (checklistItemId: string) => {
    const checklistItem = checklistItems.find((item) => item.id === checklistItemId);
    if (!checklistItem || !isDocumentPolicyItem(checklistItem?.type)) return;

    const sectionKey = snakeCase(checklistItem.label);
    const validateItem = validatedChecklist[sectionKey];
    if (checklistItem.documents?.length > 1) return;

    if (validateItem.isDone && handleDone) {
      handleDone({
        checklistItemId,
        shouldComplete: false,
      });
    }
    onSectionButtonsClick({ sectionKey, key: 'isDone', isChecklist: true });
  };

  return {
    validatedSection,
    onSectionButtonsClick,
    isAllDone,
    validatedChecklist,
    barChartInfo,
    isResubmit,
    isEditTable,
    undoneSections,
    handleNextClick,
    onUploadComplete,
    onDocumentDelete,
  };
};
