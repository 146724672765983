import { useCallback } from 'react';
import { ButtonProps } from '@mui/material/Button';

import { ImagePicker, useImagePicker } from '@hooks';
import { IDocument, IProjectDocument } from '@interfaces';
import { ENABLED_MIME_TYPES } from '@constants';

export interface ControllerInterface {
  buttonAction: () => void;
  text: string;
  imageZoom: ImagePicker;
}

export interface DocumentButtonInterface {
  handleDelete?: ({
    documentId,
    fileName,
    checklistId,
  }: {
    documentId?: string;
    fileName?: string;
    checklistId?: string;
  }) => void;
  document?: IDocument | IProjectDocument;
  projectDocument?: IProjectDocument;
  handleDeleteProps?: { checklistItemId?: string; fileName?: string };
  buttonName?: string;
  dataTestName?: string;
  size?: ButtonProps['size'];
  onButtonClick?: () => void;
  isActive?: boolean;
  width?: string;
}

export const useDocument = (document): ControllerInterface => {
  const imageZoom = useImagePicker();

  const showDocument = useCallback(
    () => (document ? imageZoom.open([document]) : null),
    [document],
  );

  return {
    text: document ? `${document.document_type || ''} / ${document.original_filename}` : '',
    imageZoom,
    buttonAction: ENABLED_MIME_TYPES.includes(document?.mime_type) ? showDocument : () => null,
  };
};
