import { useContext, useMemo, useState } from 'react';
import { useQuery } from 'react-query';
import { matchPath, useLocation, useNavigate } from 'react-router-dom';

import { getHookState, getPoliciesListByRole } from '@utils';
import {
  IProjectChecklist,
  PoliciesTypeEnum,
  PolicyListSourceEnums,
  QueryNamesEnums,
  TTeamRole,
} from '@interfaces';
import { getPlatformPoliciesTemplates } from '@globalService';
import { PermissionsContext } from '@context';
import { PoliciesTypeLabel, PoliciesTypeMap, TEAM_ROLES } from '@constants';
import { ControllerInterface } from './interface';
import { useLeavePageBlocker } from '@hooks';
import snakeCase from 'lodash/snakeCase';

export const usePlatformPolicies = (): ControllerInterface => {
  const { permissions } = useContext(PermissionsContext);
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const match = matchPath('/platform-settings/policies/*', pathname);
  const activeTab = match?.params['*'].split('/')[0];
  const [editMode, setEditMode] = useState<PolicyListSourceEnums>();

  const { getLeavePageConfirmModal } = useLeavePageBlocker({
    currentPagePathname: `/platform-settings/policies/${activeTab}`,
    confirmTitle: 'Exit policy editing',
    isUpdated: Boolean(editMode),
  });

  const switcherTabs = [
    {
      label: PoliciesTypeLabel.PROJECT,
      value: PoliciesTypeEnum.PROJECT,
      isActive: activeTab === PoliciesTypeEnum.PROJECT,
      dataTestName: snakeCase(PoliciesTypeLabel.PROJECT),
    },
    {
      label: PoliciesTypeLabel.FIRST_DRAW_REQUEST,
      value: PoliciesTypeEnum.FIRST_DRAW,
      isActive: activeTab === PoliciesTypeEnum.FIRST_DRAW,
      dataTestName: snakeCase(PoliciesTypeLabel.FIRST_DRAW_REQUEST),
    },
    {
      label: PoliciesTypeLabel.DRAW_REQUEST,
      value: PoliciesTypeEnum.FUTURE_DRAWS,
      isActive: activeTab === PoliciesTypeEnum.FUTURE_DRAWS,
      dataTestName: snakeCase(PoliciesTypeLabel.DRAW_REQUEST),
    },
    {
      label: PoliciesTypeLabel.CHANGE_REQUEST,
      value: PoliciesTypeEnum.FUTURE_CHANGES,
      isActive: activeTab === PoliciesTypeEnum.FUTURE_CHANGES,
      dataTestName: snakeCase(PoliciesTypeLabel.CHANGE_REQUEST),
    },
  ];

  const platformPoliciesTemplateQuery = useQuery<IProjectChecklist[], Error>(
    [QueryNamesEnums.GET_PLATFORM_POLICIES_TEMPLATES],
    getPlatformPoliciesTemplates.bind(this),
  );

  const policiesToProcess = useMemo(
    () =>
      platformPoliciesTemplateQuery.data?.filter((o) => o.type === PoliciesTypeMap[activeTab]) ||
      [],
    [platformPoliciesTemplateQuery.data, activeTab],
  );

  const approvalPolicyTemplates = useMemo(
    () =>
      getPoliciesListByRole({
        policies: policiesToProcess,
        teamRole: TEAM_ROLES.Lender as TTeamRole,
      }),
    [policiesToProcess, permissions],
  );

  const submissionPolicyTemplates = useMemo(
    () =>
      getPoliciesListByRole({
        policies: policiesToProcess,
        teamRole: TEAM_ROLES.Owner as TTeamRole,
      }),
    [policiesToProcess, permissions],
  );

  const handleTabChange = (value: string) => {
    navigate(`/platform-settings/policies/${value}`);
  };

  return {
    state: getHookState(platformPoliciesTemplateQuery),
    switcherTabs,
    activeTab,
    handleTabChange,
    approvalPolicyTemplates,
    submissionPolicyTemplates,
    isProjectPolicies: activeTab === PoliciesTypeEnum.PROJECT,
    editMode,
    setEditMode,
    getLeavePageConfirmModal,
  };
};
