import { useQuery } from 'react-query';
import { useCallback, useContext, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { AuthContext, PermissionsContext, SettingsContext } from '@context';
import {
  checkIsCreator,
  checkIsOwner,
  dateFormatter,
  getHookState,
  getTeamRole,
  isReallocationEnabled,
  isRequestApproved,
  isRequestCompleted,
  isRequestDraft,
  isRequestInReview,
  isRestricted,
  percentFormatter,
} from '@utils';
import {
  DrawRequestStatus,
  IDrawRequest,
  IProject,
  IProjectProgress,
  PermissionNamesEnums,
  QueryNamesEnums,
} from '@interfaces';
import {
  getDrawRequest,
  getProject,
  getProjectDrawRequestsList,
  getProjectProgress,
} from '@globalService';
import { excludeCommentsWithTotalsAllQueryFields } from '@constants';
import { ControllerInterface, ProgressType } from './interface';

export const PROGRESS_TYPES = {
  CONTRIBUTED: 'Contributed',
  REMAINING: 'Remaining',
};

export const useDrawRequestCard = (): ControllerInterface => {
  const { projectId } = useParams();
  const { user } = useContext(AuthContext);
  const { permissions } = useContext(PermissionsContext);
  const { isCurrentProjectActive } = useContext(SettingsContext);
  const navigate = useNavigate();
  const teamRole = getTeamRole(user);

  const [progressType, setProgressType] = useState<ProgressType>('Contributed');

  const handleProgressTypeChange = (_, progressType: ProgressType) =>
    progressType && setProgressType(progressType);

  const getCurrentShareByProgressType = useCallback(
    (data) =>
      progressType === PROGRESS_TYPES.CONTRIBUTED
        ? percentFormatter({ value: data?.before, roundTo: 0 })
        : progressType === PROGRESS_TYPES.REMAINING
          ? percentFormatter({ value: 100 - data?.before, roundTo: 0 })
          : null,
    [progressType],
  );

  const getNextShareByProgressType = useCallback(
    (data) =>
      progressType === PROGRESS_TYPES.CONTRIBUTED
        ? percentFormatter({ value: data?.after, roundTo: 0 })
        : progressType === PROGRESS_TYPES.REMAINING
          ? percentFormatter({ value: 100 - data?.after, roundTo: 0 })
          : null,
    [progressType],
  );

  const getCurrentAmountByProgressType = useCallback(
    (data) =>
      progressType === PROGRESS_TYPES.CONTRIBUTED
        ? data?.before_value
        : progressType === PROGRESS_TYPES.REMAINING
          ? data?.total - data?.before_value
          : null,
    [progressType],
  );

  const getNextAmountByProgressType = useCallback(
    (data) =>
      progressType === PROGRESS_TYPES.CONTRIBUTED
        ? data?.after_value
        : progressType === PROGRESS_TYPES.REMAINING
          ? data?.total - data?.after_value
          : null,
    [progressType],
  );

  const drawRequestsQuery = useQuery<{ results: IDrawRequest[] }, Error>(
    [QueryNamesEnums.GET_PROJECT_DRAW_REQUEST_LIST, { projectId }],
    getProjectDrawRequestsList.bind(this, projectId),
  );

  const drawRequestId = useMemo(
    () => drawRequestsQuery?.data?.results?.[0]?.id,
    [drawRequestsQuery],
  );

  // to get DR details
  const drawRequestQuery = useQuery<IDrawRequest, Error>(
    [
      QueryNamesEnums.GET_DRAW_REQUEST,
      { projectId, drawRequestId, query: excludeCommentsWithTotalsAllQueryFields },
    ],
    getDrawRequest.bind(this, {
      projectId,
      drawRequestId,
      query: excludeCommentsWithTotalsAllQueryFields,
    }),
    { enabled: Boolean(drawRequestId) },
  );
  // progress
  const progressQuery = useQuery<IProjectProgress, Error>(
    [QueryNamesEnums.GET_PROJECT_PROGRESS, { projectId }],
    getProjectProgress.bind(this, projectId),
  );

  const projectQuery = useQuery<IProject, Error>(
    [QueryNamesEnums.GET_PROJECT, { projectId }],
    getProject.bind(this, projectId),
    { enabled: Boolean(projectId) },
  );

  const inReview = useMemo(
    () => isRequestInReview(drawRequestQuery.data?.status),
    [drawRequestQuery.data],
  );
  const isDraft = useMemo(
    () => isRequestDraft(drawRequestQuery.data?.status),
    [drawRequestQuery.data],
  );

  // Contributed/remaining start
  const getProgressData = (data, showProgress) => ({
    title: data?.display_name,
    currentShare: getCurrentShareByProgressType(data),
    nextShare: getNextShareByProgressType(data),
    currentAmount: getCurrentAmountByProgressType(data),
    nextAmount: getNextAmountByProgressType(data),
    getTextStyleForActive: showProgress,
    showProgress,
  });
  const constuctionBudgetProgress = useMemo(() => {
    const data = progressQuery?.data?.total_construction_budget;
    return getProgressData(data, inReview);
  }, [progressQuery?.data, progressType, inReview]);

  const constuctionHoldbackProgress = useMemo(() => {
    const data = progressQuery?.data?.construction_holdback;
    return getProgressData(data, inReview);
  }, [progressQuery?.data, progressType, inReview]);

  const borrowerEquityProgress = useMemo(() => {
    const data = progressQuery?.data?.borrower_equity;
    return getProgressData(data, inReview);
  }, [progressQuery?.data, progressType, inReview]);

  const originalBorrowerEquityProgress = useMemo(() => {
    const data = progressQuery?.data?.original_borrower_equity;
    return getProgressData(data, inReview);
  }, [progressQuery?.data, progressType, inReview]);

  const additionalBorrowerEquityProgress = useMemo(() => {
    const data = progressQuery?.data?.additional_borrower_equity_used;
    return getProgressData(data, inReview);
  }, [progressQuery?.data, progressType, inReview]);
  // Contributed/remaining end

  // variances start
  const inspectorAllowanceProgress = useMemo(() => {
    const { inspector_allowance_rate_before } = drawRequestQuery.data || {};
    const { inspector_allowance_rate } = drawRequestQuery.data?.totals?.all || {};
    return {
      title: 'Inspection allowance',
      currentShare: percentFormatter({
        value: inReview ? inspector_allowance_rate_before : inspector_allowance_rate,
        roundTo: 0,
      }),
      nextShare: percentFormatter({ value: inspector_allowance_rate, roundTo: 0 }),
      showProgress: inReview,
    };
  }, [drawRequestQuery.data]);

  const lenderAllowanceProgress = useMemo(() => {
    const { previous_lender_allowance_rate, lender_allowance_rate } =
      drawRequestQuery.data?.totals?.all || {};
    return {
      title: 'Lender allowance',
      currentShare: percentFormatter({
        value: inReview ? previous_lender_allowance_rate : lender_allowance_rate,
        roundTo: 0,
      }),
      nextShare: percentFormatter({ value: lender_allowance_rate, roundTo: 0 }),
      getTextStyleForActive: inReview,
      showProgress: inReview,
    };
  }, [drawRequestQuery.data]);

  const gapProgress = useMemo(() => {
    const { totals, gap_before } = drawRequestQuery.data || {};
    return {
      title: 'Variance to lender allowance',
      currentShare: percentFormatter({
        value: inReview ? gap_before : totals?.all?.variance_to_lender_allowance_rate,
        roundTo: 0,
      }),
      nextShare: percentFormatter({
        value: totals?.all?.variance_to_lender_allowance_rate,
        roundTo: 0,
      }),
      getTextStyleForActive: inReview,
      showProgress: inReview,
    };
  }, [drawRequestQuery.data]);
  // variances end

  const drawRequestDate = useMemo(() => {
    switch (drawRequestQuery.data?.status) {
      case DrawRequestStatus.IN_REVIEW:
        return {
          label: 'Submitted date',
          value: dateFormatter({ date: drawRequestQuery.data.submitted_at }),
        };
      case DrawRequestStatus.APPROVED:
      case DrawRequestStatus.COMPLETED:
        return {
          label: 'Approved date',
          value: dateFormatter({ date: drawRequestQuery.data.approved_at }),
        };
      case DrawRequestStatus.DRAFT:
        if (drawRequestQuery.data?.is_resubmit) {
          return {
            label: 'Submitted date',
            value: dateFormatter({ date: drawRequestQuery.data.submitted_at }),
          };
        } else {
          return null;
        }

      default:
        return null;
    }
  }, [drawRequestQuery.data]);

  const showAdditionalRequirements = useMemo(
    () =>
      drawRequestQuery.data?.submission_checklist_incomplete_items_total &&
      checkIsCreator(drawRequestQuery.data, teamRole) &&
      inReview &&
      checkIsOwner(teamRole),
    [inReview, teamRole, drawRequestQuery.data],
  );

  const isReallocationAllowed = useMemo(
    () => isReallocationEnabled(drawRequestQuery.data, projectQuery.data),
    [drawRequestQuery.data, projectQuery.data],
  );

  const showContinueDraftButton = useMemo(
    () =>
      isDraft &&
      !isRestricted(PermissionNamesEnums.PROJECT__DRAW_REQUEST__SUBMIT, permissions) &&
      checkIsCreator(drawRequestQuery.data, teamRole),
    [drawRequestQuery.data, permissions, teamRole, isDraft],
  );

  const openDraft = () => {
    if (isDraft && drawRequestQuery.data?.id)
      navigate(`/projects/${projectId}/requests/${drawRequestQuery.data.id}/submission/`);
  };

  return {
    isEmptyDrawRequestsList:
      drawRequestsQuery.isSuccess && !drawRequestsQuery.data?.results?.length,
    drawRequest: drawRequestQuery.data,
    state: getHookState([drawRequestQuery, drawRequestsQuery]),
    inspectorAllowanceProgress,
    gapProgress,
    constuctionBudgetProgress,
    constuctionHoldbackProgress,
    borrowerEquityProgress,
    originalBorrowerEquityProgress,
    additionalBorrowerEquityProgress,
    progressType,
    handleProgressTypeChange,
    requestedAmount: drawRequestQuery.data?.totals?.current?.requested_amount || 0,
    approvedAmount: drawRequestQuery.data?.totals?.current?.approved_amount || 0,
    currentReviewer: drawRequestQuery.data?.current_reviewer_team?.name,
    approvedReallocation: drawRequestQuery.data?.approved_reallocation,
    requestedReallocation: drawRequestQuery.data?.requested_reallocation,
    revisedScheduledValues: drawRequestQuery.data?.totals?.all?.revised_estimate,
    requestedScheduledValues:
      (drawRequestQuery.data?.totals?.all?.revised_estimate || 0) +
      (drawRequestQuery.data?.totals?.all?.requested_budget_change || 0),
    inReview,
    isDraft,
    hasBeenReviewed:
      isRequestApproved(drawRequestQuery.data?.status) ||
      isRequestCompleted(drawRequestQuery.data?.status),
    drawRequestDate,
    isLoading:
      progressQuery.isFetching || drawRequestQuery.isFetching || drawRequestsQuery.isFetching,
    requestLink: `/projects/${projectId}/draws/draw-requests/${drawRequestQuery.data?.id}`,
    lenderAllowanceProgress,
    showAdditionalRequirements,
    isReallocationAllowed,
    showContinueDraftButton,
    openDraft,
    isCurrentProjectActive,
  };
};
