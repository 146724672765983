import React, { FC, PropsWithChildren } from 'react';
import { ComponentProps, defaultProps, ComponentWithPreloadProps } from './interface';
import { Stack, Typography, Skeleton, Box } from '@mui/material';
import { getEllipsisStyle } from '@utils/decorators';
import { styled } from '@mui/material/styles';
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';

const EllipsisStyle = (maxWidth: string | number) => ({
  ...getEllipsisStyle(maxWidth),
});

export const LabelAndValue: FC<PropsWithChildren<ComponentProps>> = ({
  size = defaultProps.size,
  children,
  label = defaultProps.label,
  text,
  justifyContent = defaultProps.justifyContent,
  color = defaultProps.color,
  colorLabel = defaultProps.colorLabel,
  icon,
  withEllipsis,
  tooltipText = defaultProps.tooltipText,
  marginRightAfterLabel = defaultProps.marginRightAfterLabel,
  minHeight = defaultProps.minHeight,
  labelStyle = defaultProps.labelStyle,
  textStyle,
  valueIcon,
  valueIconTooltip,
  noWrap,
  iconPosition = defaultProps.iconPosition,
  textDataTestName,
  valueIconDataTestName,
  iconDataTestName,
  labelDataTestName,
  fullWidth,
}) => {
  return (
    <Stack
      minHeight={minHeight}
      justifyContent={justifyContent}
      alignItems="center"
      direction="row"
      flexWrap={noWrap ? 'nowrap' : 'wrap'}
      sx={fullWidth ? { width: '100%' } : {}}
    >
      <Stack
        direction="row"
        justifyContent="flex-start"
        alignItems="center"
        sx={[withEllipsis && EllipsisStyle('70%'), marginRightAfterLabel && { mr: 3 }]}
      >
        {iconPosition === 'left' && (
          <Stack alignItems="center" pr={0.5} data-cy={iconDataTestName}>
            {icon}
          </Stack>
        )}
        <Tooltip title={tooltipText}>
          <Typography
            variant={size}
            sx={{
              color: colorLabel,
              cursor: tooltipText ? 'pointer' : 'unset',
              whiteSpace: !noWrap ? 'nowrap' : 'wrap',
              ...labelStyle,
            }}
            data-cy={labelDataTestName}
          >
            {label}&nbsp;
          </Typography>
        </Tooltip>

        {iconPosition === 'right' && icon}
      </Stack>

      <Stack direction="row" alignItems="center">
        {text ? (
          <Stack direction="row" justifyContent="flex-start" alignItems="center">
            <Typography
              variant={`${size}SemiBold`}
              align="right"
              sx={{ color, whiteSpace: 'pre-wrap', ...textStyle }}
              data-cy={textDataTestName}
            >
              {text}
            </Typography>
          </Stack>
        ) : (
          <Box sx={withEllipsis ? EllipsisStyle('30%') : {}}>{children}</Box>
        )}
        {valueIcon && (
          <NoMaxWidthTooltip
            title={
              typeof valueIconTooltip === 'string' ? (
                <Typography
                  variant="label"
                  dangerouslySetInnerHTML={{ __html: valueIconTooltip }}
                ></Typography>
              ) : (
                valueIconTooltip
              )
            }
          >
            <Stack sx={{ p: 0, pl: 1 }} data-cy={valueIconDataTestName}>
              {valueIcon}
            </Stack>
          </NoMaxWidthTooltip>
        )}
      </Stack>
    </Stack>
  );
};

export const LabelAndValueWithPreload: FC<PropsWithChildren<ComponentWithPreloadProps>> = ({
  isLoading,
  ...props
}) => {
  return isLoading ? <Skeleton sx={{ my: 0, minHeight: 24 }} /> : <LabelAndValue {...props} />;
};

const NoMaxWidthTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: 'none',
    padding: 16,
  },
});
