import React from 'react';
import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import { colors } from '@theme';

const StyledBox = ({ children, ...props }) => {
  return <Wrapper {...props}>{children}</Wrapper>;
};

export const CenteredStyledBox = ({ children, sx = {} }) => (
  <StyledBox sx={{ ...sx, width: 800 }}>{children}</StyledBox>
);

export default StyledBox;

const Wrapper = styled(Box)(({ theme }) => ({
  backgroundColor: colors.background.white,
  borderRadius: theme.spacing(0.5),
  overflow: 'hidden',
  padding: theme.spacing(2),
}));
