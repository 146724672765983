import { useUpdateUiSettings, useUrlParams } from '@hooks';
import { useCallback, useContext, useEffect, useMemo } from 'react';
import { ComponentProps, DEFAULT_SET_TO_SETTINGS, DEFAULT_SET_TO_URL } from './interface';
import * as FiltersItems from './filters';
import { AuthContext, PermissionsContext, SettingsContext } from '@context';
import { isRestricted } from '@utils';
import { useDebouncedEffect } from '@models';

function serializeFilters(filters: Record<string, Array<string>>): string {
  const params = new URLSearchParams();

  for (const key in filters) {
    if (Object.prototype.hasOwnProperty.call(filters, key)) {
      if (filters[key].length) params.set(key, filters[key].join(','));
    }
  }

  return params.toString();
}

function deserializeFilters(queryString: string): Record<string, Array<string>> {
  const params = new URLSearchParams(queryString);
  const filters: Record<string, Array<string>> = {};

  params.forEach((value, key) => {
    filters[key] = value.split(',');
  });

  return filters;
}

export const useFiltersV2 = ({ filters, setFilterStringQuery }: ComponentProps) => {
  const { user } = useContext(AuthContext);
  const { permissions } = useContext(PermissionsContext);
  const { updateSettings } = useUpdateUiSettings();
  const { settings } = useContext(SettingsContext);

  const availableFilters = useMemo(
    () =>
      filters.filter((item) => {
        const filterConfig = FiltersItems[item];
        if (
          !filterConfig ||
          (filterConfig.permissionKey && isRestricted(filterConfig.permissionKey, permissions)) ||
          filterConfig.noNeedToRender
        )
          return false;
        if (filterConfig.userPermission) return filterConfig.userPermission(user);
        return true;
      }),
    [user, permissions, filters],
  );

  const initialData = useMemo(() => {
    const data: Record<string, Array<string>> = {};
    filters.map((item) => {
      const filterConfig = FiltersItems[item];
      if (filterConfig.defaultFilters?.length) {
        data[filterConfig.filterKey] = filterConfig.defaultFilters;
      }
    });
    return data;
  }, [filters]);

  const [filtersData, setData, onSetValue] = useUrlParams(
    initialData,
    'filters',
    serializeFilters,
    deserializeFilters,
  );

  const handleFiltersChange = useCallback(
    (key: string, value: string[], skipSettings?: boolean) => {
      const filterConfig = Object.values(FiltersItems).find((item) => item.filterKey === key);
      const needToUpdateUrl = filterConfig?.needToUpdateUrl || DEFAULT_SET_TO_URL;
      const needToUpdateSetting = filterConfig?.needToUpdateSetting || DEFAULT_SET_TO_SETTINGS;

      onSetValue({ [key]: value }, !needToUpdateUrl);
      if (needToUpdateSetting && !skipSettings)
        updateSettings({
          personal_setting: {
            ...settings.personal_setting,
            filters: {
              ...(settings.personal_setting.filters || {}),
              [key]: value,
            },
          },
        });
    },
    [filtersData, settings],
  );

  const setDataFromSettings = useCallback(
    (filters: Record<string, Array<string>>) => {
      const availableFiltersName = availableFilters.map((filter) => FiltersItems[filter].filterKey);
      Object.keys(filters).map((key) => {
        if (!filters[key] || filtersData[key] || !availableFiltersName.includes(key as any)) return;
        handleFiltersChange(key, filters[key], true);
      });
    },
    [filtersData, availableFilters],
  );

  useEffect(() => {
    if (settings?.personal_setting?.filters) {
      setDataFromSettings(settings?.personal_setting?.filters);
    }
  }, [settings?.personal_setting?.filters]);

  const updateFilters = () => {
    const string = serializeFilters(filtersData);
    setFilterStringQuery(string);
  };

  useDebouncedEffect(
    () => {
      updateFilters();
    },
    [filtersData],
    500,
  );

  useEffect(() => {
    updateFilters();
  }, []);

  const resetFiltersToDefault = useCallback(() => {
    setData(initialData);
    updateSettings({
      personal_setting: {
        ...settings.personal_setting,
        filters: {
          ...initialData,
        },
      },
    });
  }, [settings, initialData]);

  return {
    filtersData,
    handleFiltersChange,
    availableFilters,
    resetFiltersToDefault,
  };
};
