import React, { FC } from 'react';
import { PieChart } from '@mui/x-charts';
import { IPieChartData } from '@interfaces';
import { typography } from '@theme';

const PieChartItem: FC<{
  data: IPieChartData[];
  legend?: string;
  padding?: number;
  height?: number;
}> = ({ data, legend, padding = 16, height = 120 }) => (
  <PieChart
    height={height}
    series={[
      {
        data,
        cx: '100%',
      },
    ]}
    slotProps={{
      legend: {
        position: { vertical: 'middle', horizontal: 'left' },
        padding,
        ...(legend ? { labelStyle: { ...typography[legend] } } : {}),
      },
    }}
    tooltip={{
      trigger: 'none',
    }}
  />
);

export default PieChartItem;
