import { useContext, useMemo } from 'react';
import { useQuery } from 'react-query';
import { IDrawRequest, QueryNamesEnums, TableKeyEnum } from '@interfaces';
import {
  checkIsOwner,
  enhanceItemsWithCommentsPreview,
  getColorCodingScheme,
  getHookState,
  getSortQueryString,
  getTeamRole,
  isChangeRequest,
  transformFiltersToQueryString,
} from '@utils';
import { getDrawRequestList } from '@globalService';
import { drawRequestFields, statusMap } from '@constants';
import { AuthContext, SettingsContext, useLaunchDarklyFlags } from '@context';

export const useDrawRequestList = ({
  page,
  rowsPerPage,
  sortValue,
  filters,
  search,
  filterStringQuery = '',
}) => {
  const { user } = useContext(AuthContext);
  const { settings } = useContext(SettingsContext);
  const flags = useLaunchDarklyFlags();
  const teamRole = getTeamRole(user);
  const isOwner = checkIsOwner(teamRole);
  const sortingString = getSortQueryString(sortValue);
  const sorting =
    isOwner && flags?.['ENG_7931_returned_request_visibility']
      ? `draw_request_status_with_resubmit${sortingString ? ',' + sortingString : ''}`
      : sortingString;

  const params = {
    pagination: 'true',
    offset: (page * rowsPerPage).toString(),
    limit: rowsPerPage,
    ...(sorting && { sorting }),
  };

  const queryParams = new URLSearchParams(params);
  let stringQueryParams = queryParams.toString();
  if (filterStringQuery) {
    stringQueryParams += `&${filterStringQuery}`;
  } else {
    stringQueryParams += `&${new URLSearchParams({ q: search })}`;
    stringQueryParams += transformFiltersToQueryString({
      filtering: filters,
      tableKey: TableKeyEnum.REQUESTS,
      teamRole,
    });
  }

  const query = stringQueryParams + `&query={${drawRequestFields.join(',')}}`;
  const queryComments = stringQueryParams + `&query={id,comments_preview}`;

  const requestsQuery = useQuery<{ results: IDrawRequest[]; count: number }, Error>(
    [QueryNamesEnums.GET_DRAW_REQUEST_LIST, { queryURL: query }],
    getDrawRequestList.bind(this, {
      queryURL: query,
      useV2: filterStringQuery || flags?.['ENG_7891_multiple_inspections_status_for_draw_request'],
    }),
  );

  const requestsQueryComments = useQuery<{ results: IDrawRequest[]; count: number }, Error>(
    [QueryNamesEnums.GET_DRAW_REQUEST_LIST, { queryURL: queryComments }],
    getDrawRequestList.bind(this, { queryURL: queryComments }),
    { enabled: Boolean(requestsQuery.data?.results) },
  );

  const formattedData = useMemo(() => {
    const requests = requestsQuery.data?.results || [];
    const comments = requestsQueryComments.data?.results || [];

    // Enhance requests with comments
    const enhancedRequests = enhanceItemsWithCommentsPreview(requests, comments);

    return enhancedRequests.map((request) => ({
      ...request,
      name: `${isChangeRequest(request) ? 'Change' : 'Draw'}\u00A0#${request.number}`,
      // TODO: remove requestStatus, projectStatus after export to csv will be implemented at BE ENG-6131
      requestStatus:
        statusMap(request.status, settings.display, 'draw_request')?.text || request.status,
      projectStatus:
        statusMap(request.project.status, settings.display, 'project')?.text ||
        request.project.status,
      colorCodingScheme: getColorCodingScheme({ request }),
    }));
  }, [requestsQuery.data, requestsQueryComments.data]);

  return {
    state: getHookState(requestsQuery),
    count: requestsQuery.data?.count,
    results: requestsQuery.data?.results,
    formattedData,
  };
};
