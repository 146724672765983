import {
  checkApprovedRetainageCorrect,
  checkIsAllNamesFill,
  checkIsApprovedNegativeAmount,
  checkIsCreator,
  checkIsCreditInDraw,
  checkIsOwner,
  checkIsReallocateComplete,
  checkIsReallocateCompleteByLender,
  getBasicUrl,
  getTeamRole,
  getTooltipText,
  hasApprovedAmount,
  hasApprovedReallocation,
  hasApprovedRetainage,
  isActionEnabled,
  isChangeRequest,
  isCreatedProject,
  isDrawRequest,
  isInactiveProject,
  isMilestoneFieldsContainErrors,
  isRequestApproved,
  isRequestCompleted,
  isRequestHistorical,
  isRestricted,
} from '@utils';
import {
  ApproveActionEnum,
  ChecklistItemLocal as ChecklistItem,
  IDrawRequest,
  IProject,
  IRequestReview,
  IRequestReviewParams,
  IRequestTerminationParams,
  MutationKeyEnum,
  PatchDrawRequestParam,
  PermissionNamesEnums,
  PostDrawRequestReviewParam,
  QueryNamesEnums,
  RequestOperationsModalTypeEnum,
  RequestReviewStatusEnum,
} from '@interfaces';
import {
  getDrawRequest,
  getProject,
  getRequestReviews,
  patchDrawRequest,
  postComment,
  postDrawRequestReview,
} from '@globalService';
import { useNavigate } from 'react-router-dom';
import { useIsFetching, useIsMutating, useMutation, useQuery, useQueryClient } from 'react-query';
import { useContext, useEffect, useMemo, useState } from 'react';
import { useReviewRequestInvalidation, useSafeSnackbar } from '@hooks';
import isEmpty from 'lodash/isEmpty';
import { AuthContext, PermissionsContext, useLaunchDarklyFlags } from '@context';

import {
  APPROVAL_EDITABLE_KEYS,
  excludeCommentsWithTotalsAllQueryFields,
  REQUEST_EDITABLE_KEYS,
} from '@constants';

export interface ControllerInterface {
  drawRequest: IDrawRequest;
  modalType: RequestOperationsModalTypeEnum;
  closeModal: () => void;
  handleActionButtonClick: () => Promise<void>;
  handleCloseApproveModal: () => void;
  handleCloseChecklistApproveModal: () => void;
  sendRequestReview: (params: IRequestReviewParams) => Promise<void>;
  dataIsLoading: boolean;
  nextReviewer: string;
  isChecklistReasonsRequired: boolean;
  isApproveDisabled: boolean;
  disableButtonTooltip: string;
  approveActionType: ApproveActionEnum;
  isFinalApprove: boolean;
  showRejectModal: () => void;
  handleTermination: (params: IRequestTerminationParams) => Promise<void>;
  showRejectButton: boolean;
  isAddReviewMutating: boolean;
  showPutOnHoldModal: () => void;
  showReleaseFromHoldModal: () => void;
  showMarkAsDisbursedModal: () => void;
  isRequestForDisbursement: boolean;
  isUserHasPermissionForAction: boolean;
}

type DR_MODAL = RequestOperationsModalTypeEnum | null;

export const useApprovalButtonsAndModals = ({
  checklistItems,
  projectId,
  drawRequestId,
}: {
  checklistItems?: ChecklistItem[];
  projectId: string;
  drawRequestId: string;
}): ControllerInterface => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSafeSnackbar();
  const { user } = useContext(AuthContext);
  const { permissions } = useContext(PermissionsContext);
  const teamRole = getTeamRole(user);
  const [drModal, setDrModal] = useState<DR_MODAL>(null);
  const [approveActionType, setApproveActionType] = useState<ApproveActionEnum | null>(null);
  const [nextReviewer, setNextReviewer] = useState('');
  const [isChecklistReasonsRequired, setChecklistReasonsRequired] = useState(true);
  const handleRequestReviewInvalidation = useReviewRequestInvalidation();
  const flags = useLaunchDarklyFlags();

  const isMutating = useIsMutating({
    predicate: ({ options }) =>
      options.mutationKey === MutationKeyEnum.MILESTONE_PATCH ||
      options.mutationKey === MutationKeyEnum.DRAW_REQUEST_PATCH,
  });

  const isFetchingRequest = useIsFetching({
    queryKey: [QueryNamesEnums.GET_DRAW_REQUEST_TOTALS],
    exact: false,
  });

  const drawRequestQuery = useQuery<IDrawRequest, Error>(
    [
      QueryNamesEnums.GET_DRAW_REQUEST,
      { projectId, drawRequestId, query: excludeCommentsWithTotalsAllQueryFields },
    ],
    getDrawRequest.bind(this, {
      projectId,
      drawRequestId,
      query: excludeCommentsWithTotalsAllQueryFields,
    }),
    { enabled: Boolean(drawRequestId) },
  );

  const projectQuery = useQuery<IProject, Error>(
    [QueryNamesEnums.GET_PROJECT, { projectId }],
    getProject.bind(this, projectId),
    { enabled: !!projectId },
  );

  const restQlParams = '{status,created_by{id}}';
  const reviewsQuery = useQuery<{ results: IRequestReview[] }, Error>(
    [QueryNamesEnums.GET_DRAW_REQUEST_REVIEWS, { projectId, drawRequestId, restQlParams }],
    getRequestReviews.bind(this, { projectId, drawRequestId, restQlParams }),
    { enabled: Boolean(projectId && drawRequestId) },
  );

  const drawRequest = useMemo(() => drawRequestQuery.data, [drawRequestQuery.data]);

  const missedReviewQuantity = useMemo(
    () => drawRequest?.reviews_required_quantity - drawRequest?.customer_approve_reviews_quantity,
    [drawRequest],
  );

  const isFundAllowed = useMemo(
    () =>
      drawRequest?.funding_approve_required &&
      !isRestricted(PermissionNamesEnums.PROJECT__DRAW_REQUEST__FUND, permissions),
    [permissions, drawRequest],
  );

  const isApprovedAllowed = useMemo(
    () => !isRestricted(PermissionNamesEnums.PROJECT__DRAW_REQUEST__APPROVE, permissions),
    [permissions],
  );

  const isApproveAlreadyDone = useMemo(() => {
    const review = reviewsQuery.data?.results?.find(
      (item) => item.created_by?.id === user?.id && item.status === RequestReviewStatusEnum.APPROVE,
    );
    return Boolean(review);
  }, [reviewsQuery.data, user?.id]);

  const isFundUnavailable = useMemo(
    () =>
      approveActionType === ApproveActionEnum.FUND &&
      missedReviewQuantity >= 1 &&
      (isApproveAlreadyDone || !isApprovedAllowed),
    [isApproveAlreadyDone, approveActionType, missedReviewQuantity, isApprovedAllowed],
  );

  const isFinalApprove = useMemo(() => {
    if (isRequestApproved(drawRequest?.status) || isRequestCompleted(drawRequest?.status))
      return true;

    return drawRequest?.funding_approve_required && isDrawRequest(drawRequest)
      ? approveActionType === ApproveActionEnum.FUND
      : missedReviewQuantity === 1;
  }, [drawRequest, missedReviewQuantity, approveActionType]);

  useEffect(() => {
    if (!drawRequest || !permissions?.length) return;

    const isApprovedAndFundAllowed = isFundAllowed && isApprovedAllowed;
    const isApprovedAndFundNotAllowed = !isFundAllowed && !isApprovedAllowed;

    if (isApprovedAndFundAllowed) {
      setApproveActionType(
        missedReviewQuantity <= 0 && isDrawRequest(drawRequest)
          ? ApproveActionEnum.FUND
          : ApproveActionEnum.APPROVE,
      );
      return;
    }
    if (isApprovedAndFundNotAllowed) {
      setApproveActionType(ApproveActionEnum.SEND_FOR_APPROVAL);
      return;
    }

    if (isFundAllowed && isDrawRequest(drawRequest)) {
      setApproveActionType(ApproveActionEnum.FUND);
      return;
    }
    setApproveActionType(ApproveActionEnum.APPROVE);
  }, [
    permissions,
    isApproveAlreadyDone,
    drawRequest,
    isFundAllowed,
    isApprovedAllowed,
    missedReviewQuantity,
  ]);

  const closeModal = () => setDrModal(null);

  const handleCloseChecklistApproveModal = () =>
    setDrModal(
      approveActionType === ApproveActionEnum.SEND_FOR_APPROVAL
        ? RequestOperationsModalTypeEnum.SWITCH_APPROVER
        : RequestOperationsModalTypeEnum.DRAW_REQUEST,
    );

  const handleCloseApproveModal = (navigateLink = `/projects/${projectId}/overview`) => {
    closeModal();
    if (flags?.['ENG_7910_updates_based_on_draw_events']) {
      handleRequestReviewInvalidation({
        projectId,
        drawRequestId,
      });
    } else {
      invalidateQueryList();
    }
    navigate(navigateLink);
  };

  // TODO - remove after ENG_7910_updates_based_on_draw_events
  const invalidateQueryList = () => {
    queryClient.invalidateQueries(QueryNamesEnums.GET_PROJECT_DRAW_REQUEST_LIST);
    queryClient.invalidateQueries(QueryNamesEnums.GET_PROJECT_PROGRESS);
    queryClient.invalidateQueries(QueryNamesEnums.GET_PROJECT_FUNDS);
    queryClient.invalidateQueries(QueryNamesEnums.GET_PROJECT_LIST);
    queryClient.invalidateQueries(QueryNamesEnums.GET_PORTFOLIO_LIST);
    queryClient.invalidateQueries(QueryNamesEnums.GET_DRAW_REQUEST);
    queryClient.invalidateQueries(QueryNamesEnums.GET_DRAW_REQUEST_REVIEWS);
    queryClient.invalidateQueries(QueryNamesEnums.GET_DRAW_REQUEST_FOR_APPROVAL);
    queryClient.invalidateQueries(QueryNamesEnums.GET_DRAW_REQUEST_LIST);
    queryClient.invalidateQueries(QueryNamesEnums.GET_PROJECT_COMMENTS);
    queryClient.invalidateQueries([QueryNamesEnums.GET_PROJECT_MILESTONES_COLUMNS, { projectId }]);
    queryClient.invalidateQueries(QueryNamesEnums.GET_DRAW_REQUEST_AUDIT_LOG);
  };

  const handleActionButtonClick = async () => {
    const isEmptyChecklist = isEmpty(checklistItems);
    const modalType = RequestOperationsModalTypeEnum;

    if (approveActionType === ApproveActionEnum.SEND_FOR_APPROVAL) {
      setChecklistReasonsRequired(false);
      setDrModal(isEmptyChecklist ? modalType.SWITCH_APPROVER : modalType.CHECKLIST);
      return;
    }

    if (!isEmptyChecklist) {
      setChecklistReasonsRequired(approveActionType === ApproveActionEnum.FUND);
      setDrModal(modalType.CHECKLIST);
      return;
    }

    setDrModal(modalType.DRAW_REQUEST);
  };

  const sendRequestReview = async ({ teamId, teamName, notes }: IRequestReviewParams) => {
    if (teamName) setNextReviewer(teamName);
    await addReviewMutation.mutateAsync({
      id: projectId,
      drawRequest: drawRequestId,
      ...(teamId && { next_team_reviewer: teamId }),
      status:
        approveActionType === ApproveActionEnum.FUND
          ? RequestReviewStatusEnum.FUND
          : RequestReviewStatusEnum.APPROVE,
      ...(notes && { notes }),
    });
  };

  const addReviewMutation = useMutation<
    Response,
    Error & { review?: string },
    PostDrawRequestReviewParam
  >(postDrawRequestReview, {
    mutationKey: MutationKeyEnum.DRAW_REQUEST_PATCH,
    onSuccess: (_, variables) => {
      if (variables.status === RequestReviewStatusEnum.REJECT) {
        setDrModal(RequestOperationsModalTypeEnum.REJECT_SUCCESS);
        return;
      }
      setDrModal(RequestOperationsModalTypeEnum.APPROVED);
      if (isFinalApprove && isDrawRequest(drawRequest)) {
        queryClient.invalidateQueries(QueryNamesEnums.GET_DRAW_REQUEST);
      }
    },
    onError: (error) => {
      enqueueSnackbar(error?.review || error?.message, { variant: 'error' });
    },
  });

  const tableHasInputError = useMemo(() => {
    const listOfKeysForValidation = checkIsCreator(drawRequest, teamRole)
      ? [...APPROVAL_EDITABLE_KEYS, ...REQUEST_EDITABLE_KEYS]
      : APPROVAL_EDITABLE_KEYS;
    return (
      isMilestoneFieldsContainErrors(listOfKeysForValidation, drawRequest) ||
      !checkIsReallocateCompleteByLender(drawRequest) ||
      !checkIsReallocateComplete(drawRequest)
    );
  }, [drawRequest, teamRole]);

  const patchRequest = useMutation<Response, Error & { review?: string }, PatchDrawRequestParam>(
    patchDrawRequest,
    {
      mutationKey: MutationKeyEnum.DRAW_REQUEST_PATCH,
      onSuccess: (_, variables) => {
        setDrModal(
          variables?.is_on_hold
            ? RequestOperationsModalTypeEnum.PUT_ON_HOLD_SUCCESS
            : RequestOperationsModalTypeEnum.RELEASE_FROM_HOLD_SUCCESS,
        );
      },
      onError: (error) => {
        enqueueSnackbar(error?.review || error?.message, { variant: 'error' });
      },
    },
  );

  const postCommentMutation = useMutation<
    Response,
    Error,
    { url: string; value: { message: string } }
  >(postComment, {
    onSuccess: () => {
      queryClient.invalidateQueries(QueryNamesEnums.GET_PROJECT_COMMENTS);
    },
    onError: (error) => {
      enqueueSnackbar(error?.message, { variant: 'error' });
    },
  });

  const handleTermination = async ({ reasons, notes, type }: IRequestTerminationParams) => {
    if (type === RequestOperationsModalTypeEnum.REJECT) {
      await addReviewMutation.mutateAsync({
        id: projectId,
        drawRequest: drawRequestId,
        status: RequestReviewStatusEnum.REJECT,
        notes: `${reasons.join(', ')}. ${notes?.value && '<br/>Notes: ' + notes.value}`,
      });
      return;
    }

    const notesText = `Reason for changing on hold status: ${reasons.join(', ')}.`;
    await patchRequest.mutateAsync({
      id: projectId,
      drawRequest: drawRequestId,
      is_on_hold: type === RequestOperationsModalTypeEnum.PUT_ON_HOLD,
      is_on_hold_change_reason: notesText,
    });

    const message = `<b>${
      type === RequestOperationsModalTypeEnum.PUT_ON_HOLD ? 'Put on' : 'Released from'
    } hold:</b> Reason: ${reasons.join(', ')}. ${notes?.value && '<br/>Notes: ' + notes.value}`;
    const postCommentUrl = getBasicUrl({
      requestType: 'post',
      projectId,
      requestId: drawRequestId,
    });
    await postCommentMutation.mutateAsync({
      url: postCommentUrl,
      value: { message },
    });
  };

  const showRejectModal = () => {
    setDrModal(RequestOperationsModalTypeEnum.REJECT);
  };

  const showPutOnHoldModal = () => {
    setDrModal(RequestOperationsModalTypeEnum.PUT_ON_HOLD);
  };

  const showReleaseFromHoldModal = () => {
    setDrModal(RequestOperationsModalTypeEnum.RELEASE_FROM_HOLD);
  };

  const showMarkAsDisbursedModal = () => {
    setDrModal(RequestOperationsModalTypeEnum.MARK_AS_DISBURSED);
  };

  // only request created by owner can be rejected, if it's created by lender it can be just assigned to another lender team
  const showRejectButton = useMemo(
    () => checkIsOwner(drawRequest?.team?.team_role),
    [drawRequest?.team?.team_role],
  );

  const zeroApprovedAmount = useMemo(
    () =>
      (isFundAllowed || isApprovedAllowed) &&
      isDrawRequest(drawRequest) &&
      !hasApprovedAmount(drawRequest) &&
      !hasApprovedRetainage(drawRequest) &&
      (!isRequestHistorical(drawRequest?.source) || !hasApprovedReallocation(drawRequest)),
    [drawRequest, isFundAllowed, isApprovedAllowed],
  );

  const zeroApprovedReallocation = useMemo(
    () =>
      (isFundAllowed || isApprovedAllowed) &&
      isChangeRequest(drawRequest) &&
      !hasApprovedReallocation(drawRequest),
    [drawRequest, isFundAllowed, isApprovedAllowed],
  );

  const isRequestForDisbursement = useMemo(
    () => isRequestApproved(drawRequest?.status),
    [drawRequest?.status],
  );

  const isUserHasPermissionForAction = useMemo(() => {
    switch (approveActionType) {
      case ApproveActionEnum.SEND_FOR_APPROVAL:
        return !isRestricted(
          PermissionNamesEnums.PROJECT__DRAW_REQUEST__REVIEWER_TEAM__EDIT,
          permissions,
        );
      case ApproveActionEnum.APPROVE:
        return !isRestricted(PermissionNamesEnums.PROJECT__DRAW_REQUEST__APPROVE, permissions);
      case ApproveActionEnum.FUND:
        return !isRestricted(PermissionNamesEnums.PROJECT__DRAW_REQUEST__FUND, permissions);
      default:
        return true;
    }
  }, [approveActionType, permissions]);

  return {
    drawRequest,
    modalType: drModal,
    closeModal,
    handleActionButtonClick,
    handleCloseApproveModal,
    handleCloseChecklistApproveModal,
    sendRequestReview,
    dataIsLoading: Boolean(isMutating || isFetchingRequest || drawRequestQuery.isFetching),
    nextReviewer,
    isChecklistReasonsRequired,
    isApproveDisabled: !isActionEnabled(
      isInactiveProject(projectQuery?.data?.status),
      isRequestHistorical(drawRequestQuery.data?.source)
        ? false
        : isCreatedProject(projectQuery?.data?.status),
      drawRequestQuery.isLoading,
      addReviewMutation?.isLoading,
      tableHasInputError,
      !checkIsAllNamesFill(drawRequest),
      isApproveAlreadyDone && !isFinalApprove,
      isFundUnavailable,
      !checkApprovedRetainageCorrect(drawRequest),
      zeroApprovedAmount,
      zeroApprovedReallocation,
      checkIsApprovedNegativeAmount(drawRequestQuery.data),
    ),
    disableButtonTooltip: getTooltipText({
      isCurrentProjectArchived: isInactiveProject(projectQuery?.data?.status),
      nonActiveProject: isRequestHistorical(drawRequestQuery.data?.source)
        ? false
        : isCreatedProject(projectQuery?.data?.status),
      isAllNamesNotFill: !checkIsAllNamesFill(drawRequest),
      tableHasInputError,
      isRetainageApprove: !checkApprovedRetainageCorrect(drawRequest),
      isApproveAlreadyDone: isApproveAlreadyDone && !isFinalApprove,
      isFundUnavailable,
      zeroApprovedAmount,
      zeroApprovedReallocation,
      isCreditIssue:
        checkIsApprovedNegativeAmount(drawRequestQuery.data) &&
        checkIsCreditInDraw(drawRequestQuery.data),
    }),
    approveActionType,
    isFinalApprove,
    showRejectModal,
    handleTermination,
    showRejectButton,
    isAddReviewMutating: addReviewMutation?.isLoading,
    showPutOnHoldModal,
    showReleaseFromHoldModal,
    showMarkAsDisbursedModal,
    isUserHasPermissionForAction,
    isRequestForDisbursement,
  };
};
