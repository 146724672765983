import React, { memo } from 'react';
import GoogleMapReact from 'google-map-react';
import { Box } from '@mui/material';

import { ComponentProps } from './interface';
import { config } from '@config';
import { LocationImageIcon } from '@svgAsComponents';
import { isProduction } from '@utils';

const key = config.googleMapApiKey;

const LocationPin = () => (
  <div>
    <LocationImageIcon size={30} />
  </div>
);

const GoogleMap = ({ location, zoomLevel = 11 }: ComponentProps) => {
  const { lat, long } = location || {};
  if (!lat || !long || !isProduction) return null;
  return (
    <Box sx={{ height: 250, width: '100%' }}>
      <GoogleMapReact
        bootstrapURLKeys={{ key }}
        defaultCenter={{ ...location, lng: long }}
        defaultZoom={zoomLevel}
      >
        <LocationPin />
      </GoogleMapReact>
    </Box>
  );
};

const MemoizedMapComponent = memo(GoogleMap);

export default MemoizedMapComponent;
