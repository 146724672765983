import { useCallback } from 'react';
import { EventType, IWSMessageData } from '@interfaces';
import {
  useAssignCoordinatorInvalidation,
  useDocumentUploadedInvalidation,
  useReviewRequestInvalidation,
  useSwitchReviewerInvalidation,
  useUpdateProjectStatusInvalidation,
} from '@hooks';
import { useNavigate } from 'react-router-dom';

export const useEventInvalidationHandlers = () => {
  const navigate = useNavigate();
  const handleProjectStatusUpdateInvalidation = useUpdateProjectStatusInvalidation();
  const handleRequestReviewInvalidation = useReviewRequestInvalidation();
  const handleAssignCoordinatorInvalidation = useAssignCoordinatorInvalidation();
  const handleDocumentUploadedInvalidation = useDocumentUploadedInvalidation();
  const handleSwitchApproverInvalidation = useSwitchReviewerInvalidation();

  const projectEventInvalidation = useCallback(
    (params: IWSMessageData) =>
      handleProjectStatusUpdateInvalidation({ projectId: params?.project?.id }),
    [handleProjectStatusUpdateInvalidation],
  );

  const requestStatusEventInvalidation = useCallback(
    (params: IWSMessageData) => {
      if (
        params.event_type === EventType.DRAW_REQUEST_DELETED &&
        window.location.href.includes(params?.draw_request?.id)
      ) {
        navigate(`/projects/${params?.project?.id}/overview`);
      }
      handleRequestReviewInvalidation({
        projectId: params?.project?.id,
        drawRequestId: params?.draw_request?.id,
        event_type: params.event_type,
      });
    },
    [navigate, handleRequestReviewInvalidation],
  );

  const assignCoordinatorEventInvalidation = useCallback(
    (params: IWSMessageData) =>
      handleAssignCoordinatorInvalidation({
        projectId: params?.project?.id,
        drawRequestId: params?.draw_request?.id,
      }),
    [handleAssignCoordinatorInvalidation],
  );

  const switchApproverEventInvalidation = useCallback(
    (params: IWSMessageData) =>
      handleSwitchApproverInvalidation({
        projectId: params?.project?.id,
        drawRequestId: params?.draw_request?.id,
      }),
    [handleSwitchApproverInvalidation],
  );

  const documentUploadedEventInvalidation = useCallback(
    (params: IWSMessageData) =>
      handleDocumentUploadedInvalidation({
        projectId: params?.project?.id,
        document_type: params?.document_type,
        drawRequestId: params?.draw_request?.id,
        milestoneId: params?.milestone?.id,
      }),
    [handleDocumentUploadedInvalidation],
  );

  const invalidateQueriesByEventType = {
    [EventType.PROJECT_ACTIVATED]: projectEventInvalidation,
    [EventType.PROJECT_DEACTIVATED]: projectEventInvalidation,
    [EventType.PROJECT_PAUSED]: projectEventInvalidation,
    [EventType.PROJECT_ARCHIVED]: projectEventInvalidation,
    [EventType.PROJECT_COMPLETED]: projectEventInvalidation,
    [EventType.PROJECT_CREATED]: projectEventInvalidation,
    [EventType.DRAW_REQUEST_SUBMITTED]: requestStatusEventInvalidation,
    [EventType.DRAW_REQUEST_RETURNED]: requestStatusEventInvalidation,
    [EventType.DRAW_REQUEST_FINALIZED]: requestStatusEventInvalidation,
    [EventType.DRAW_REQUEST_APPROVED]: requestStatusEventInvalidation,
    [EventType.DRAW_REQUEST_MARKED_AS_DISBURSED]: requestStatusEventInvalidation,
    [EventType.DRAW_REQUEST_DELETED]: requestStatusEventInvalidation,
    [EventType.DRAW_REQUEST_CREATED]: requestStatusEventInvalidation,
    [EventType.DRAW_REQUEST_IS_ON_HOLD_CHANGED]: requestStatusEventInvalidation,
    [EventType.DRAW_REQUEST_COORDINATOR_CHANGED]: assignCoordinatorEventInvalidation,
    [EventType.DRAW_REQUEST_REVIEWER_TEAM_CHANGED]: switchApproverEventInvalidation,
    [EventType.DOCUMENT_ADDED]: documentUploadedEventInvalidation,
  };

  return { invalidateQueriesByEventType };
};
