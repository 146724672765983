import React, { FC } from 'react';
import { Button, Grid } from '@mui/material';
import * as FiltersItems from './filters';
import FilterButtonV2 from '../FilterButtonV2';
import { ComponentProps } from './interface';
import { useFiltersV2 } from './controller';

const FiltersV2: FC<ComponentProps> = ({
  filters,
  LeftComponent,
  setFilterStringQuery,
  source,
  padding = 2,
}) => {
  const { handleFiltersChange, resetFiltersToDefault, availableFilters, filtersData } =
    useFiltersV2({
      filters,
      setFilterStringQuery,
      source,
    });
  return (
    <Grid container justifyContent="space-between" wrap="nowrap" p={padding}>
      <Grid item sx={{ flexGrow: 1, alignItems: 'center' }}>
        <Grid container wrap="wrap" spacing={1}>
          {LeftComponent ? (
            <LeftComponent filtersData={filtersData} handleFiltersChange={handleFiltersChange} />
          ) : null}
          {availableFilters.map((item) => {
            const filterConfig = FiltersItems[item];

            return (
              <Grid key={filterConfig.filterKey} item>
                <FilterButtonV2
                  handleFiltersChange={handleFiltersChange}
                  {...filterConfig}
                  cypressSelector={`${source}__${filterConfig.cypressSelector}`}
                  initValues={filtersData?.[filterConfig.filterKey] || []}
                />
              </Grid>
            );
          })}

          <Grid item>
            <Button
              size="small"
              variant="text"
              onClick={resetFiltersToDefault}
              data-cy="requests_queue__reset_filters__button"
            >
              Reset filters
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default FiltersV2;
