import React, { FC } from 'react';
import { Box, IconButton, Stack, Typography } from '@mui/material';
import { useParams } from 'react-router-dom';

import {
  calculateFraction,
  currencyFormatter,
  dateFormatter,
  getStatusChip,
  getTextOrDash,
  getValueColor,
  percentFormatter,
  WithPermission,
} from '@utils';
import { HookState, PermissionNamesEnums, ValueTypeEnum } from '@interfaces';
import {
  IconButtonWithTooltip,
  InspectionLabelAndValue,
  LabelAndValue,
  LabelAndValueWithPreload,
  LoadingSkeleton,
  ProgressWithArrow,
  ServiceMessage,
  StyledBox,
  TeamsAndApprovals,
} from '@components';
import { EditIcon, WarningIcon } from '@svgAsComponents';
import { colors } from '@theme';
import * as Controller from './controller';
import { SummaryEditableDataEnum } from './controller';
import { RequestCoordinator } from '../index';
import ChangeDRDataPopup from '../ChangeDRDataPopup';
import {
  ApprovedToDate,
  BalanceToFinish,
  CreatedBy,
  RemainingReallocationBalanceWithProgress,
  RequestStatusWithReason,
  RetainageBalanceToDate,
} from './subcomponents';
import { useLaunchDarklyFlags } from '@context';

const RequestSummary = () => {
  const { projectId, requestId: drawRequestId } = useParams();
  const controller = Controller.useRequestSummary(projectId, drawRequestId);

  switch (controller.state) {
    case HookState.FETCHING: {
      return <LoadingSkeleton type="twoBlocks" />;
    }
    case HookState.ERROR: {
      return <ServiceMessage text="Draws" />;
    }

    case HookState.SUCCESS: {
      return <RequestSummaryBody controller={controller} />;
    }

    default:
      return null;
  }
};

const RequestSummaryBody: FC<{
  controller: Controller.ControllerInterface;
}> = ({ controller }) => {
  const { projectId } = useParams();
  const flags = useLaunchDarklyFlags();

  const {
    drawRequest,
    isCompleted,
    isInReview,
    isApproved,
    isDraft,
    isUpdating,
    isEditable,
    isHistorical,
    updateRequestData,
    lastInspection,
    isDRFetching,
    isDrawRequest,
    retainageRate,
    projectLoanData,
    budgetChangeError,
    isApprovedOrCompleted,
    changedData,
    setChangedData,
    expanded,
    getExpandButton,
    teamsList,
    isInspectionViewProhibited,
    isInspectionAllowanceEditable,
    isReallocationAllowed,
    isDisbursementDateEditable,
    outOfBudgetError,
  } = controller;

  const {
    approved_budget_change,
    requested_budget_change,
    requested_amount,
    requested_amount_relative,
    approved_amount,
    approved_amount_relative,
    revised_estimate,
    previous_lender_allowance_rate,
    approved_amount_cumulative,
    lender_allowance_rate,
    requested_revised_estimate,
    variance_to_lender_allowance_rate,
  } = drawRequest?.totals?.all || {};

  return (
    <>
      {expanded ? (
        <Stack direction={{ lg: 'row', md: 'column', sm: 'column', xs: 'column' }} spacing={1}>
          <StyledBox sx={{ flexGrow: 1, maxWidth: { lg: '30%', md: '100%' } }}>
            <Stack flex={1} spacing={1}>
              {isEditable && (
                <WithPermission
                  permissionKey={PermissionNamesEnums.PROJECT__HISTORICAL_DRAW_REQUEST__CREATE}
                >
                  <LabelAndValueWithPreload
                    label="Draw #"
                    isLoading={isDRFetching}
                    text={drawRequest.counter_per_request_type.toString() || '-'}
                    icon={
                      <IconButton
                        onClick={() =>
                          setChangedData({
                            title: 'Edit draw #',
                            type: SummaryEditableDataEnum.NUMBER,
                            data: drawRequest.counter_per_request_type,
                          })
                        }
                        sx={{ p: 0, pl: 1 }}
                        data-cy="draw_number_edit"
                      >
                        <EditIcon size={20} />
                      </IconButton>
                    }
                    textDataTestName="draw_number"
                  />
                </WithPermission>
              )}
              <LabelAndValueWithPreload
                label="Submitted date"
                isLoading={isDRFetching}
                text={dateFormatter({ date: drawRequest.submitted_at }) || '-'}
                icon={
                  isEditable && (
                    <WithPermission
                      permissionKey={PermissionNamesEnums.PROJECT__HISTORICAL_DRAW_REQUEST__CREATE}
                    >
                      <IconButton
                        onClick={() =>
                          setChangedData({
                            title: 'Date submitted',
                            type: SummaryEditableDataEnum.SUBMITTED,
                            data: drawRequest.submitted_at,
                          })
                        }
                        sx={{ p: 0, pl: 1 }}
                        data-cy="draw_submitted_date_edit"
                      >
                        <EditIcon size={20} />
                      </IconButton>
                    </WithPermission>
                  )
                }
                textDataTestName="draw_submitted_date"
              />
              {isDrawRequest && (
                <WithPermission permissionKey={PermissionNamesEnums.PROJECT__INSPECTION}>
                  <InspectionLabelAndValue inspection={lastInspection} />
                </WithPermission>
              )}
              <LabelAndValueWithPreload
                label="Approved date"
                isLoading={isDRFetching}
                text={dateFormatter({ date: drawRequest.approved_at }) || '-'}
                icon={
                  isEditable && (
                    <WithPermission
                      permissionKey={PermissionNamesEnums.PROJECT__HISTORICAL_DRAW_REQUEST__CREATE}
                    >
                      <IconButton
                        onClick={() =>
                          setChangedData({
                            title: 'Date approved',
                            type: SummaryEditableDataEnum.APPROVED,
                            data: drawRequest.approved_at,
                          })
                        }
                        sx={{ p: 0, pl: 1 }}
                        data-cy="draw_approved_date_edit"
                      >
                        <EditIcon size={20} />
                      </IconButton>
                    </WithPermission>
                  )
                }
                textDataTestName="draw_approved_date"
              />
              {isDrawRequest && (
                <LabelAndValueWithPreload
                  label="Estimated disbursement date"
                  isLoading={isDRFetching}
                  text={dateFormatter({ date: drawRequest.estimated_disbursement_date }) || '-'}
                  icon={
                    isDisbursementDateEditable && (
                      <WithPermission
                        permissionKey={
                          PermissionNamesEnums.PROJECT__DRAW_REQUEST__ESTIMATED_DISBURSEMENT_DATE__EDIT
                        }
                      >
                        <IconButton
                          onClick={() =>
                            setChangedData({
                              title: 'Estimated disbursement date',
                              type: SummaryEditableDataEnum.ESTIMATED_DISBURSEMENT_DATE,
                              data: drawRequest.estimated_disbursement_date,
                            })
                          }
                          sx={{ p: 0, pl: 1 }}
                          data-cy="requests_tab__summary__estimated_disbursement_date__edit__icon"
                        >
                          <EditIcon size={20} />
                        </IconButton>
                      </WithPermission>
                    )
                  }
                  textDataTestName="requests_tab__summary__estimated_disbursement_date__value"
                />
              )}
              <RequestStatusWithReason drawRequest={drawRequest} source="requests_tab__summary" />

              {drawRequest?.submitted_by && (
                <CreatedBy
                  team={drawRequest?.team}
                  submitted_by={drawRequest.submitted_by}
                  isUpdating={isDRFetching}
                />
              )}
              <TeamsAndApprovals
                request={drawRequest}
                projectId={projectId}
                teamsList={teamsList}
              />
            </Stack>
          </StyledBox>

          <StyledBox sx={{ flexGrow: 1 }}>
            <Stack flex={1} direction="column" spacing={1}>
              <LabelAndValue
                label="This request only"
                text="Requested  ➝ Approved"
                color={colors.text.medium}
                labelStyle={{ fontWeight: 600 }}
              />

              {isDrawRequest && (
                <LabelAndValueWithPreload
                  isLoading={isUpdating}
                  label="Draw amount"
                  valueIcon={outOfBudgetError ? <WarningIcon size={14} /> : null}
                  valueIconTooltip={outOfBudgetError}
                >
                  <ProgressWithArrow
                    prevValue={getTextOrDash(requested_amount, requested_amount_relative)}
                    nextValue={getTextOrDash(
                      approved_amount,
                      drawRequest?.lender_allowance_rate_incremental,
                    )}
                    showProgress
                    getTextStyleForActive={isInReview}
                    prevValueDataTestName="draw_amount_previous"
                    nextValueDataTestName="draw_amount"
                    nextValueType={
                      isInReview
                        ? outOfBudgetError
                          ? { [ValueTypeEnum.IS_ERROR]: true }
                          : { [ValueTypeEnum.IS_IN_REVIEW]: true }
                        : null
                    }
                  />
                </LabelAndValueWithPreload>
              )}

              {isReallocationAllowed && (
                <LabelAndValueWithPreload
                  isLoading={isUpdating}
                  label="Reallocations"
                  valueIcon={budgetChangeError ? <WarningIcon size={14} /> : null}
                  valueIconTooltip={budgetChangeError}
                  valueIconDataTestName="requests_tab__summary__reallocation__error_icon"
                >
                  <ProgressWithArrow
                    prevValue={getTextOrDash(
                      drawRequest.requested_reallocation,
                      drawRequest.requested_reallocation_rate,
                    )}
                    nextValue={getTextOrDash(
                      drawRequest.approved_reallocation,
                      drawRequest.approved_reallocation_rate,
                    )}
                    showProgress
                    nextValueType={
                      isInReview
                        ? budgetChangeError
                          ? { [ValueTypeEnum.IS_ERROR]: true }
                          : { [ValueTypeEnum.IS_IN_REVIEW]: true }
                        : null
                    }
                    prevValueDataTestName="draw_reallocation_previous"
                    nextValueDataTestName="draw_reallocation"
                  />
                </LabelAndValueWithPreload>
              )}
              {Boolean(retainageRate) && (
                <LabelAndValueWithPreload isLoading={isUpdating} label={`Retainage released`}>
                  <ProgressWithArrow
                    prevValue={currencyFormatter(
                      drawRequest.totals.all.retainage_release_requested || 0,
                    )}
                    nextValue={currencyFormatter(
                      drawRequest.totals.all.retainage_release_approved || 0,
                    )}
                    getTextStyleForActive={isInReview}
                    prevValueDataTestName="draw__retainage_release_requested"
                    nextValueDataTestName="draw__retainage_release_approved"
                  />
                </LabelAndValueWithPreload>
              )}
              {flags?.['ENG_3812_borrower_equity_in_request_summary'] && (
                <LabelAndValueWithPreload isLoading={isUpdating} label="Additional borrower equity">
                  <ProgressWithArrow
                    prevValue={getTextOrDash(
                      drawRequest.totals?.all?.requested_additional_borrower_equity,
                      drawRequest.requested_additional_borrower_equity_rate,
                    )}
                    nextValue={getTextOrDash(
                      drawRequest.totals?.all?.additional_borrower_equity,
                      drawRequest.additional_borrower_equity_rate,
                    )}
                    showProgress
                    getTextStyleForActive={isInReview}
                    nextValueDataTestName="draw_borrower_equity"
                  />
                </LabelAndValueWithPreload>
              )}

              {isDrawRequest && (
                <WithPermission permissionKey={PermissionNamesEnums.PROJECT__INSPECTION}>
                  <LabelAndValue
                    label="Inspection recommendation"
                    text={getTextOrDash(
                      drawRequest.totals.all.inspector_allowance_incremental,
                      drawRequest.totals.all.inspector_allowance_rate_incremental,
                    )}
                  />
                </WithPermission>
              )}

              <RequestCoordinator request={drawRequest} isCompleted={isCompleted} />
            </Stack>
          </StyledBox>

          <StyledBox sx={{ flexGrow: 1 }}>
            <Stack direction="row">
              <Stack flex={1} spacing={1}>
                <LabelAndValue
                  label="Project totals"
                  text="Pre ➝ Post draw"
                  color={colors.text.medium}
                  labelStyle={{ fontWeight: 600 }}
                />
                <LabelAndValueWithPreload
                  isLoading={isUpdating}
                  label="Revised scheduled values"
                  valueIcon={budgetChangeError ? <WarningIcon size={14} /> : null}
                  valueIconTooltip={budgetChangeError}
                  valueIconDataTestName="requests_tab__summary__revised_estimate__error_icon"
                >
                  <ProgressWithArrow
                    prevValue={currencyFormatter(requested_revised_estimate, '-')}
                    nextValue={currencyFormatter(revised_estimate, '-')}
                    showProgress
                    prevValueType={
                      requested_budget_change ? { [ValueTypeEnum.IS_ERROR]: true } : null
                    }
                    nextValueType={
                      isInReview
                        ? approved_budget_change
                          ? { [ValueTypeEnum.IS_ERROR]: true }
                          : { [ValueTypeEnum.IS_IN_REVIEW]: true }
                        : null
                    }
                    prevValueDataTestName="requests_tab__summary__revised_estimate__value"
                    nextValueDataTestName="requests_tab__summary__revised_estimate__nextValue"
                  />
                </LabelAndValueWithPreload>
                {isDrawRequest && (
                  <ApprovedToDate
                    isUpdating={isUpdating}
                    totals={drawRequest?.totals?.all || {}}
                    isInReview={isInReview}
                    outOfBudgetError={!!outOfBudgetError}
                  />
                )}

                {isDrawRequest && (
                  <BalanceToFinish
                    isUpdating={isUpdating}
                    totals={drawRequest?.totals?.all || {}}
                    isInReview={isInReview}
                    outOfBudgetError={!!outOfBudgetError}
                  />
                )}

                {Boolean(retainageRate) && (
                  <RetainageBalanceToDate
                    retainageRate={retainageRate}
                    isUpdating={isUpdating}
                    totals={drawRequest?.totals?.all || {}}
                  />
                )}
                <LabelAndValueWithPreload
                  isLoading={isUpdating}
                  label="Original borrower equity"
                  text={getTextOrDash(
                    projectLoanData?.original_borrower_equity || 0,
                    calculateFraction(
                      projectLoanData?.original_borrower_equity || 0,
                      projectLoanData?.total_construction_budget,
                    ),
                  )}
                  textDataTestName="requests_tab__summary__borrower_equity__value"
                />
                <LabelAndValueWithPreload
                  isLoading={isUpdating}
                  label="Construction holdback"
                  text={getTextOrDash(
                    projectLoanData?.construction_holdback || 0,
                    calculateFraction(
                      projectLoanData?.construction_holdback,
                      projectLoanData?.total_construction_budget,
                    ),
                  )}
                  textDataTestName="requests_tab__summary__construction_holdback__value"
                />
              </Stack>
              {isInspectionViewProhibited && <Box sx={{ mt: 1 }}>{getExpandButton()}</Box>}
            </Stack>
          </StyledBox>

          <Stack spacing={1}>
            {isDrawRequest && (
              <WithPermission permissionKey={PermissionNamesEnums.PROJECT__INSPECTION}>
                <StyledBox sx={{ flexGrow: 1 }}>
                  <Stack direction="row">
                    <Stack flex={1} spacing={1}>
                      <LabelAndValue
                        label="Project totals"
                        text="Pre ➝ Post draw"
                        color={colors.text.medium}
                        labelStyle={{ fontWeight: 600 }}
                      />
                      <LabelAndValueWithPreload
                        label="Lender allowance"
                        isLoading={isUpdating}
                        valueIcon={outOfBudgetError ? <WarningIcon size={14} /> : null}
                      >
                        <ProgressWithArrow
                          prevValue={percentFormatter({
                            value: previous_lender_allowance_rate,
                            roundTo: 0,
                            returnValue: '0%',
                          })}
                          nextValue={percentFormatter({
                            value: lender_allowance_rate,
                            roundTo: 0,
                            returnValue: '0%',
                          })}
                          getTextStyleForActive={isInReview}
                          prevValueDataTestName="requests_tab__summary__lender_allowance__value"
                          nextValueDataTestName="requests_tab__summary__lender_allowance__next_value"
                          nextValueType={
                            isInReview
                              ? outOfBudgetError
                                ? { [ValueTypeEnum.IS_ERROR]: true }
                                : { [ValueTypeEnum.IS_IN_REVIEW]: true }
                              : null
                          }
                        />
                      </LabelAndValueWithPreload>
                      <LabelAndValue
                        label="Inspection allowance"
                        icon={
                          isHistorical && (
                            <WithPermission
                              permissionKey={
                                PermissionNamesEnums.PROJECT__HISTORICAL_DRAW_REQUEST__CREATE
                              }
                            >
                              <IconButtonWithTooltip
                                onClick={() =>
                                  setChangedData({
                                    title: 'Inspection allowance',
                                    type: SummaryEditableDataEnum.RATE,
                                    data: drawRequest.inspector_allowance_rate,
                                  })
                                }
                                sx={{ p: 0, pl: 1 }}
                                tooltipText={
                                  isInspectionAllowanceEditable
                                    ? ''
                                    : 'The total inspection allowance rate cannot be edited if an inspection allowance rate has been entered for a line item.'
                                }
                                disabled={!isInspectionAllowanceEditable}
                                data-cy="requests_tab__summary__inspection_allowance__edit__icon"
                              >
                                <EditIcon size={20} />
                              </IconButtonWithTooltip>
                            </WithPermission>
                          )
                        }
                        textDataTestName="draw_inspection_allowance"
                      >
                        <ProgressWithArrow
                          prevValue={percentFormatter({
                            value: drawRequest.inspector_allowance_rate_before,
                            returnValue: '0%',
                          })}
                          nextValue={percentFormatter({
                            value: drawRequest.totals?.all?.inspector_allowance_rate,
                            returnValue: '0%',
                          })}
                          prevValueDataTestName="draw_inspection_allowance_rate_previous"
                          nextValueDataTestName="draw_inspection_allowance_rate"
                        />
                      </LabelAndValue>
                      <LabelAndValueWithPreload
                        isLoading={isUpdating}
                        label="Variance to lender allowance"
                        valueIconTooltip={outOfBudgetError}
                        valueIcon={outOfBudgetError ? <WarningIcon size={14} /> : null}
                      >
                        <ProgressWithArrow
                          prevValue={percentFormatter({
                            value: drawRequest.gap_before,
                            roundTo: 0,
                            returnValue: '0%',
                          })}
                          nextValue={percentFormatter({
                            value: variance_to_lender_allowance_rate,
                            roundTo: 0,
                            returnValue: '0%',
                          })}
                          getTextStyleForActive={isInReview}
                          prevValueDataTestName="requests_tab__summary__variance_to_lender_allowance__value"
                          nextValueDataTestName="requests_tab__summary__variance_to_lender_allowance__next_value"
                          nextValueType={
                            isInReview
                              ? outOfBudgetError
                                ? { [ValueTypeEnum.IS_ERROR]: true }
                                : { [ValueTypeEnum.IS_IN_REVIEW]: true }
                              : null
                          }
                        />
                      </LabelAndValueWithPreload>
                    </Stack>
                    <Box data-tour="expand_summary_button" sx={{ mt: 1 }}>
                      {getExpandButton()}
                    </Box>
                  </Stack>
                </StyledBox>
              </WithPermission>
            )}
            {!isDrawRequest && (
              <StyledBox sx={{ flexGrow: 1 }}>
                <Stack direction="row">
                  <Stack flex={1} spacing={1}>
                    <LabelAndValue label="" text="Pre ➝ Post draw" color={colors.text.medium} />
                    <ApprovedToDate
                      isUpdating={isUpdating}
                      totals={drawRequest?.totals?.all || {}}
                      isInReview={isInReview}
                      outOfBudgetError={!!outOfBudgetError}
                    />
                    <BalanceToFinish
                      isUpdating={isUpdating}
                      totals={drawRequest?.totals?.all || {}}
                      isInReview={isInReview}
                      outOfBudgetError={!!outOfBudgetError}
                    />
                  </Stack>
                  <Box sx={{ mt: 1 }}>{getExpandButton()}</Box>
                </Stack>
              </StyledBox>
            )}
            {isReallocationAllowed && isInReview && (
              <StyledBox sx={{ flexGrow: 1, display: 'flex', alignItems: 'center', width: '100%' }}>
                <Stack sx={{ flex: 1, mr: '30px' }} spacing={1}>
                  <RemainingReallocationBalanceWithProgress
                    controller={controller}
                    valueErrorText={budgetChangeError}
                  />
                </Stack>
              </StyledBox>
            )}
          </Stack>
        </Stack>
      ) : (
        <Stack direction={{ lg: 'row', md: 'column' }} spacing={1}>
          <StyledBox sx={{ flexGrow: 1, display: 'flex', alignItems: 'center' }}>
            <Stack direction="row" alignItems="flex-end" justifyContent="flex-start">
              {isCompleted ? (
                <Typography variant="h4" sx={{ mr: '4px' }}>{`${
                  isDrawRequest ? 'Draw' : 'Change'
                } #${drawRequest.counter_per_request_type}`}</Typography>
              ) : (
                <>
                  <Typography variant="h4" sx={{ mr: '4px' }}>{`Active ${
                    isDrawRequest ? 'draw' : 'change'
                  } #${drawRequest.counter_per_request_type}`}</Typography>

                  <Typography variant="body3">
                    {isInReview ? '(requested)' : isApproved ? '(approved)' : '(draft)'}
                  </Typography>
                </>
              )}
            </Stack>
          </StyledBox>

          {!isInReview && !isDraft && (
            <StyledBox sx={{ flexGrow: 1 }}>
              <Stack flex={1} spacing={1}>
                <LabelAndValue label={`${isDrawRequest ? 'Draw' : 'Change'} status`}>
                  {getStatusChip(drawRequest)}
                </LabelAndValue>
              </Stack>
            </StyledBox>
          )}

          {isDrawRequest && (
            <StyledBox sx={{ flexGrow: 1 }}>
              <Stack flex={1} spacing={1}>
                <LabelAndValueWithPreload
                  isLoading={isUpdating}
                  color={getValueColor({ isInReview })}
                  label={isApprovedOrCompleted ? 'Approved draw' : 'Draw amount'}
                  text={getTextOrDash(
                    isApprovedOrCompleted ? approved_amount : requested_amount,
                    isApprovedOrCompleted ? approved_amount_relative : requested_amount_relative,
                  )}
                  textDataTestName={`requests_tab__summary__collapsed__${
                    isApprovedOrCompleted ? 'approved_amount' : 'requested_amount'
                  }__value`}
                />
              </Stack>
            </StyledBox>
          )}
          {isReallocationAllowed && (
            <StyledBox sx={{ flexGrow: 1 }}>
              <Stack flex={1} spacing={1}>
                {isApprovedOrCompleted ? (
                  <LabelAndValueWithPreload
                    isLoading={isUpdating}
                    color={getValueColor({ isInReview })}
                    label="Approved reallocations"
                    text={currencyFormatter(drawRequest?.approved_reallocation, '-')}
                    textDataTestName="requests_tab__summary__collapsed__approved_reallocation__value"
                  />
                ) : (
                  <RemainingReallocationBalanceWithProgress
                    controller={controller}
                    valueErrorText={budgetChangeError}
                  />
                )}
              </Stack>
            </StyledBox>
          )}

          <StyledBox sx={{ flexGrow: 1 }}>
            <Stack direction="row">
              {isApprovedOrCompleted ? (
                <Stack flex={1} spacing={1}>
                  {isDrawRequest ? (
                    <LabelAndValueWithPreload
                      label="Lender allowance"
                      text={getTextOrDash(approved_amount_cumulative, lender_allowance_rate)}
                      isLoading={isUpdating}
                      textDataTestName="requests_tab__summary__collapsed__lender_allowance__value"
                    />
                  ) : (
                    <ApprovedToDate
                      isUpdating={isUpdating}
                      totals={drawRequest?.totals?.all || {}}
                      isInReview={isInReview}
                      outOfBudgetError={!!outOfBudgetError}
                    />
                  )}
                </Stack>
              ) : (
                <Stack flex={1} spacing={1}>
                  {isDrawRequest ? (
                    <BalanceToFinish
                      isUpdating={isUpdating}
                      totals={drawRequest?.totals?.all || {}}
                      isInReview={isInReview}
                      outOfBudgetError={!!outOfBudgetError}
                    />
                  ) : (
                    <ApprovedToDate
                      isUpdating={isUpdating}
                      totals={drawRequest?.totals?.all || {}}
                      isInReview={isInReview}
                      outOfBudgetError={!!outOfBudgetError}
                    />
                  )}
                </Stack>
              )}
              <Box sx={{ mt: 1 }}>{getExpandButton()}</Box>
            </Stack>
          </StyledBox>
        </Stack>
      )}
      {changedData && (
        <ChangeDRDataPopup
          onClose={() => setChangedData(null)}
          onSave={(value) => {
            updateRequestData(changedData.type, value);
            setChangedData(null);
          }}
          initData={changedData.data}
          title={changedData.title}
          type={changedData.type}
        />
      )}
    </>
  );
};

export default RequestSummary;
