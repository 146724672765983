import React, { FC } from 'react';
import { Box, Button } from '@mui/material';
import { isEmpty } from 'lodash';

import { FilesUploader, GoogleMap } from '@components';
import { DocumentContentTypeEnum, IProject } from '@interfaces';
import { HomeIcon } from '@svgAsComponents';

import * as Controller from './controller';
import { colors } from '@theme';
import { createTuple } from '@utils';

const ProjectImage: FC<{
  disabled?: boolean;
  project: IProject;
  source: string;
}> = ({ disabled, project, source }) => {
  const projectImage =
    project?.thumb_representations?.thumb?.url ||
    project?.thumb_representations?.original?.url ||
    '';
  const { fileUploader, startUploading, thumbTypeId, closeFileUploader, projectLocation } =
    Controller.useProjectImage({ project });

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '222px',
          width: '440px',
          backgroundColor: colors.background.gray,
          borderRadius: '2px',
          '& img': {
            width: '100%',
            height: '100%',
            objectFit: 'contain',
          },
          opacity: !disabled && 0.7,
          position: 'relative',
        }}
      >
        {!disabled && (
          <Button
            onClick={() => startUploading()}
            variant="new"
            color="secondary"
            size="small"
            sx={{
              position: 'absolute',
              top: '95px',
              left: '0px',
              right: '0px',
              width: '170px',
              margin: 'auto',
              zIndex: 7,
            }}
          >
            Edit project image
          </Button>
        )}
        {projectImage && <img src={projectImage} alt="project image" />}
        {!projectImage && !isEmpty(projectLocation) && <GoogleMap location={projectLocation} />}
        {!projectImage && isEmpty(projectLocation) && <HomeIcon />}
      </Box>
      <Box sx={{ pb: 1 }} />

      {fileUploader.transloaditSignature && (
        <FilesUploader
          open={fileUploader.isFilesUploaderOpened}
          onClose={closeFileUploader}
          transloaditSignature={fileUploader.transloaditSignature}
          restrictions={{
            ...fileUploader.restrictions,
            maxNumberOfFiles: 1,
            allowedFileTypes: ['.png', '.heic', '.jpg', '.jpeg', '.svg'],
          }}
          fields={['document_type_id', 'linked_objects']}
          metaDataDefault={{
            document_type_id: thumbTypeId,
            linked_objects: createTuple(DocumentContentTypeEnum.PROJECT, project.id),
          }}
          source={source}
        />
      )}
    </>
  );
};

export default ProjectImage;
