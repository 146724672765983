import { colors } from './index';
import { fonts } from './fonts';
import { fontSizes } from './sizes';

const typography = {
  body1SemiBold: {
    fontFamily: fonts.primary,
    fontSize: fontSizes.large,
    ['@media (maxWidth: 600px)']: {
      fontSize: fontSizes.medium,
    },
    color: colors.text.dark,
    fontWeight: 600,
  },
  printH1: {
    fontFamily: fonts.primary,
    fontSize: '42px',
    lineHeight: '44px',
    fontWeight: 700,
    color: colors.text.heading,
    display: 'block',
  },
  printH2: {
    fontFamily: fonts.primary,
    fontSize: '26px',
    lineHeight: '36px',
    fontWeight: 600,
    color: colors.text.heading,
    display: 'block',
  },
  printBody1: {
    fontFamily: fonts.primary,
    fontSize: '12px',
    lineHeight: '16px',
    fontWeight: 400,
    color: colors.text.dark,
    display: 'block',
  },
  printBody1SemiBold: {
    fontFamily: fonts.primary,
    fontSize: '12px',
    lineHeight: '16px',
    fontWeight: 600,
    color: colors.text.dark,
    display: 'block',
  },
  body2SemiBold: {
    fontFamily: fonts.primary,
    fontSize: fontSizes.medium,
    ['@media (maxWidth: 600px)']: {
      fontSize: fontSizes.small,
    },
    color: colors.text.dark,
    fontWeight: 600,
  },
  body3: {
    fontFamily: fonts.primary,
    fontSize: fontSizes.small,
    ['@media (maxWidth: 600px)']: {
      fontSize: fontSizes.xSmall,
    },
    color: colors.text.dark,
    fontWeight: 400,
  },
  body3SemiBold: {
    fontFamily: fonts.primary,
    fontSize: fontSizes.small,
    ['@media (maxWidth: 600px)']: {
      fontSize: fontSizes.xSmall,
    },
    color: colors.text.dark,
    fontWeight: 600,
  },
  label: {
    fontFamily: fonts.primary,
    fontSize: fontSizes.xSmall,
    ['@media (maxWidth: 600px)']: {
      fontSize: fontSizes.xxSmall,
    },
    color: colors.text.dark,
    fontWeight: 400,
  },
  labelSemiBold: {
    fontFamily: fonts.primary,
    fontSize: fontSizes.xSmall,
    ['@media (maxWidth: 600px)']: {
      fontSize: fontSizes.xxSmall,
    },
    lineHeight: '16px',
    color: colors.text.dark,
    fontWeight: 600,
  },
  columnLabel: {
    fontFamily: fonts.primary,
    fontSize: fontSizes.xSmall,
    ['@media (maxWidth: 600px)']: {
      fontSize: fontSizes.xxSmall,
    },
    lineHeight: '16px',
    fontWeight: 600,
    color: colors.text.medium,
    cursor: 'pointer',
    zIndex: 1,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    '&:hover': {
      overflow: 'visible',
      background: 'white',
    },
  },
};

export default typography;

declare module '@mui/material/styles' {
  interface TypographyVariants {
    body1SemiBold: React.CSSProperties;
    body2SemiBold: React.CSSProperties;
    body3: React.CSSProperties;
    body3SemiBold: React.CSSProperties;
    label: React.CSSProperties;
    labelSemiBold: React.CSSProperties;
    columnLabel: React.CSSProperties;
    printH1: React.CSSProperties;
    printH2: React.CSSProperties;
    printBody1: React.CSSProperties;
    printBody1SemiBold: React.CSSProperties;
  }

  // allow configuration using `createTheme`
  interface TypographyVariantsOptions {
    body1SemiBold: React.CSSProperties;
    body2SemiBold: React.CSSProperties;
    body3: React.CSSProperties;
    body3SemiBold: React.CSSProperties;
    label: React.CSSProperties;
    labelSemiBold: React.CSSProperties;
    columnLabel: React.CSSProperties;
    printH1: React.CSSProperties;
    printH2: React.CSSProperties;
    printBody1: React.CSSProperties;
    printBody1SemiBold: React.CSSProperties;
  }
}

// Update the Typography's variant prop options
declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    body1SemiBold: true;
    body2SemiBold: true;
    body3: true;
    body3SemiBold: true;
    label: true;
    labelSemiBold: true;
    columnLabel: true;
    printH1: true;
    printH2: true;
    printBody1: true;
    printBody1SemiBold: true;
  }
}
