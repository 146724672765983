import { useContext, useEffect, useMemo, useState } from 'react';
import { useMutation, useQueries, useQueryClient } from 'react-query';
import { format } from 'date-fns';
import differenceBy from 'lodash/differenceBy';
import sortedUniqBy from 'lodash/sortedUniqBy';
import isEqual from 'lodash/isEqual';

import {
  DateValidationEnum,
  DocumentContentTypeEnum,
  DocumentTypeEnum,
  ErrorDual,
  IProject,
  IPropertyDetail,
  IPropertyDetailLocal,
  PatchBuildingParam,
  QueryNamesEnums,
  TransloaditTemplateEnum,
  WorkflowReasonEnum,
} from '@interfaces';
import {
  createProjectBuilding,
  getProjectDocumentTypeByName,
  getProjectExitStrategiesList,
  getProjectPropertyDetailsNames,
  getProjectPropertyTypes,
  getProjectTypes,
  getWorkflowReasons,
  patchProjectBuilding,
  updateProjectFields,
} from '@globalService';
import { useDateFieldModel, useStringFieldModel } from '@models';
import { useFilesUploader, useSafeSnackbar } from '@hooks';
import {
  compareDates,
  getDateValidationRule,
  getReasonsList,
  getReasonText,
  parsePathErrorDual,
} from '@utils';
import { ControllerInterface } from './interface';
import { SettingsContext } from '@context';

export const useProject = (
  projectId: string,
  completionDateDefault: Date | null,
  projectDefaultType: string,
  exitStrategyDefault: string,
  propertyDefaultType: string,
  propertyProposedType: string,
  propertyDetailsDefault: IPropertyDetail[],
  scopeOfWorkDefault: string,
  propertyTypeId: string,
  onClose: () => void,
  startDate: Date | null,
  projectName: string,
): ControllerInterface => {
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSafeSnackbar();
  const { isPHBProject } = useContext(SettingsContext);

  const [propertyDetails, setPropertyDetails] = useState<IPropertyDetailLocal[]>([]);
  const [projectType, setProjectType] = useState(projectDefaultType);
  const [exitStrategy, setExitStrategy] = useState(exitStrategyDefault);
  const [propertyType, setPropertyType] = useState({
    existing_type_name: propertyDefaultType,
    proposed_type_name: propertyProposedType,
  });
  const [completionDateReasons, setCompletionDateReasons] = useState([]);

  const scopeOfWork = useStringFieldModel({
    initValue: scopeOfWorkDefault,
  });
  const completionDate = useDateFieldModel({
    initValue: completionDateDefault,
    validationRule: (value) =>
      getDateValidationRule({
        value,
        minDate: startDate,
        rule: DateValidationEnum.MORE_OR_EQUAL,
        dateDescription: 'start date',
      }),
  });
  const projectNameField = useStringFieldModel({
    initValue: projectName,
    validationRule: (value) => Boolean(value?.trim()),
  });

  const requestedDataQueries = useQueries([
    {
      queryKey: [QueryNamesEnums.GET_PROJECT_TYPES],
      queryFn: getProjectTypes.bind(this, ''),
    },
    {
      queryKey: [QueryNamesEnums.GET_PROJECT_PROPERTY_TYPES],
      queryFn: getProjectPropertyTypes.bind(this, ''),
    },
    {
      queryKey: [QueryNamesEnums.GET_PROJECT_PROPERTY_DETAILS_NAMES],
      queryFn: getProjectPropertyDetailsNames,
    },
    {
      queryKey: [QueryNamesEnums.GET_PROJECT_EXIT_STRATEGIES],
      queryFn: getProjectExitStrategiesList,
    },
    {
      queryKey: [QueryNamesEnums.GET_PROJECT_DOCUMENT_THUMB_TYPES],
      queryFn: getProjectDocumentTypeByName.bind(this, DocumentTypeEnum.THUMB),
    },
    {
      queryKey: [QueryNamesEnums.GET_WORKFLOW_REASONS],
      queryFn: getWorkflowReasons.bind(this),
    },
  ]);

  const projectTypesQuery = requestedDataQueries[0].data;
  const propertyTypesQuery = requestedDataQueries[1].data;
  const propertyDetailsQuery = requestedDataQueries[2].data;
  const exitStrategiesQuery = requestedDataQueries[3].data;
  const documentThumbTypeQuery = requestedDataQueries[4].data;
  const reasonsQuery = requestedDataQueries[5].data;

  const updateInfoProject = useMutation<
    Response,
    Error,
    { projectId: string; json: Partial<IProject> }
  >(updateProjectFields, {
    onSuccess: () => {
      queryClient.invalidateQueries(QueryNamesEnums.GET_PROJECT);
      queryClient.invalidateQueries(QueryNamesEnums.GET_PROJECT_PROPERTY_DETAILS_NAMES);
    },
    onError: (error) => {
      enqueueSnackbar(
        typeof error === 'string' || error instanceof String
          ? error
          : 'Something went wrong while updating project info',
        { variant: 'error' },
      );
    },
  });

  const patchBuildingMutation = useMutation<Response, ErrorDual, PatchBuildingParam>(
    patchProjectBuilding,
    {
      onError: (error) => {
        enqueueSnackbar(parsePathErrorDual(error), { variant: 'error' });
      },
      onSuccess: () => {
        queryClient.invalidateQueries(QueryNamesEnums.GET_PROJECT_BUILDING);
      },
    },
  );

  const addBuildingMutation = useMutation<
    Response,
    Error,
    {
      projectId: string;
      details?: IPropertyDetail[];
    }
  >(createProjectBuilding, {
    onSuccess: () => {
      queryClient.invalidateQueries([QueryNamesEnums.GET_PROJECT_BUILDING, { projectId }]);
    },
    onError: (error) => {
      enqueueSnackbar(error.message, { variant: 'error' });
    },
  });

  useEffect(() => {
    if (propertyDetailsDefault?.length && propertyDetailsQuery?.results?.length) {
      setPropertyDetails(
        propertyDetailsDefault.map((detail) => ({
          ...detail,
          name: propertyDetailsQuery.results.find((item) => item.name === detail.name)?.id,
        })),
      );
    }
  }, [propertyDetailsDefault, propertyDetailsQuery?.results]);

  const updatePropertyType = (key, value) =>
    setPropertyType((prop) => ({
      ...prop,
      [key]: value,
    }));

  const updatePropertyDetail = (
    key: keyof IPropertyDetailLocal,
    value: string | number,
    index: number,
  ) =>
    setPropertyDetails((details) =>
      Object.assign([...details], { [index]: { ...details[index], [key]: value } }),
    );

  const addPropertyDetail = () => {
    if (checkIfEmptyDetailExist()) return;
    setPropertyDetails((details) => [
      ...details,
      {
        name: null,
        existing_value: '',
        proposed_value: '',
        isEditable: true,
      },
    ]);
  };

  const checkIfEmptyDetailExist = () =>
    Boolean(
      propertyDetails.find(
        (detail) => !detail.name && !detail.existing_value && !detail.proposed_value,
      ),
    );

  const deletePropertyDetail = (index: number) =>
    setPropertyDetails(propertyDetails.filter((details, i) => i !== index));

  const hasDetailsDuplicates = () =>
    sortedUniqBy(propertyDetails, 'name')?.length !== propertyDetails.length;

  const removeUnnecessaryProps = (detailsList, hasDefaultDetails) =>
    hasDefaultDetails
      ? detailsList.filter((item) => item.name).map(({ name_display, ...rest }) => rest)
      : detailsList;

  const findIdInArrByValue = (arr, searchedVal) =>
    arr?.find((type) => type.name_display === searchedVal)?.id || '';

  const getPropertyDataForPayload = () => ({
    project: projectId,
    ...(!isEqual(propertyDetails, propertyDetailsDefault) && {
      details: removeUnnecessaryProps(propertyDetails, propertyTypeId),
    }),
  });

  const showCompletionDateReasons = useMemo(
    () => compareDates(completionDateDefault, completionDate.value) !== 0,
    [completionDate.value],
  );

  const isCompletionDateReasonsValid = useMemo(
    () =>
      !completionDateDefault || !showCompletionDateReasons || Boolean(completionDateReasons.length),
    [completionDateDefault, completionDateReasons, showCompletionDateReasons],
  );

  const onSave = async () => {
    if (hasDetailsDuplicates())
      return enqueueSnackbar('Property details names should be unique', { variant: 'error' });

    if (!isCompletionDateReasonsValid)
      return enqueueSnackbar('The reason of completion date change is required', {
        variant: 'error',
      });

    const propertyData = getPropertyDataForPayload();

    if (!isPHBProject) {
      if (propertyTypeId) {
        await patchBuildingMutation.mutateAsync({
          project: projectId,
          building: propertyTypeId.toString(),
          json: { details: propertyData.details },
        });
      } else {
        await addBuildingMutation.mutateAsync({ projectId, details: propertyData.details });
      }
    }

    if (!projectNameField.validate()) return;
    await updateInfoProject.mutateAsync({
      projectId,
      json: {
        ...(showCompletionDateReasons &&
          completionDate.validate() && {
            estimated_completion_date: format(completionDate.value, 'yyyy-MM-dd'),
            estimated_completion_date_change_reason: getReasonText(completionDateReasons, ''),
          }),
        ...(projectType !== projectDefaultType && {
          type: findIdInArrByValue(projectTypesQuery?.results, projectType),
        }),
        ...(exitStrategy !== exitStrategyDefault && {
          exit_strategy: findIdInArrByValue(exitStrategiesQuery?.results, exitStrategy),
        }),
        ...(scopeOfWork.value !== scopeOfWorkDefault && {
          scope_of_work: scopeOfWork.value,
        }),
        ...(projectNameField.value !== projectName && {
          name: projectNameField.value,
        }),
        ...(propertyType.existing_type_name !== propertyDefaultType && {
          property_existing_type: findIdInArrByValue(
            propertyTypesQuery?.results,
            propertyType.existing_type_name,
          ),
        }),
        ...(propertyType.proposed_type_name !== propertyProposedType && {
          property_proposed_type: findIdInArrByValue(
            propertyTypesQuery?.results,
            propertyType.proposed_type_name,
          ),
        }),
      },
    });

    onClose();
  };

  const filteredPropertyDetailsNames = useMemo(
    () =>
      propertyDetailsQuery?.results
        ? differenceBy([...propertyDetailsQuery.results], [...propertyDetailsDefault], 'name')
        : [],
    [propertyDetailsQuery?.results, propertyDetailsDefault],
  );

  const fileUploader = useFilesUploader();
  const startUploading = () => {
    const fields = {
      content_type: DocumentContentTypeEnum.PROJECT,
      object_id: projectId,
    };
    fileUploader.uploadMedia({
      fields,
      templateType: TransloaditTemplateEnum.DOCUMENTS,
    });
  };

  const closeFileUploader = () => {
    queryClient.invalidateQueries(QueryNamesEnums.GET_PROJECT);
    queryClient.invalidateQueries(QueryNamesEnums.GET_PROJECT_LIST);
    fileUploader.filesUploaderClose();
    onClose();
  };

  const completionDateReasonsList = useMemo(
    () => getReasonsList(reasonsQuery, WorkflowReasonEnum.COMPLETION_DATE_CHANGED),
    [reasonsQuery],
  );

  const handleCompletionDateReasonChange = (event) => {
    const {
      target: { value },
    } = event;
    setCompletionDateReasons(value);
  };

  return {
    projectType,
    setProjectType,
    exitStrategy,
    setExitStrategy,
    propertyType,
    updatePropertyType,
    projectTypesList: projectTypesQuery?.results,
    propertyTypesList: propertyTypesQuery?.results,
    exitStrategiesList: exitStrategiesQuery?.results,
    propertyDetails,
    addPropertyDetail,
    deletePropertyDetail,
    propertyDetailsNames: filteredPropertyDetailsNames,
    scopeOfWork,
    completionDate,
    completionDateReasonsList,
    completionDateReasons,
    handleCompletionDateReasonChange,
    showCompletionDateReasons,
    updatePropertyDetail,
    onSave,
    checkIfEmptyDetailExist,
    fileUploader,
    startUploading,
    thumbTypeId: documentThumbTypeQuery?.id,
    closeFileUploader,
    isCompletionDateReasonsValid,
    projectNameField,
  };
};
