import React, { useContext, FC, useEffect, PropsWithChildren } from 'react';
import { EngineContext, EngineProvider } from '@context';
import { styled } from '@mui/material/styles';
import _ from 'lodash';
const Content = styled('main')({
  flexGrow: 1,
  overflow: 'auto',
  display: 'flex',
  flexDirection: 'column',
  minHeight: '100vh',
});

const ContentWithEngine: FC<PropsWithChildren> = ({ children }) => {
  const { setScrollHeight } = useContext(EngineContext);

  const debounceScroll = _.debounce(() => {
    setScrollHeight(window.pageYOffset);
  }, 500);

  useEffect(() => {
    document.body.onscroll = debounceScroll;
  }, []);
  return <Content>{children}</Content>;
};

export const ContentBody: FC<PropsWithChildren> = ({ children }) => (
  <EngineProvider>
    <ContentWithEngine>{children}</ContentWithEngine>
  </EngineProvider>
);
