import { useGetData } from '@context';
import { patchDrawRequestListItem } from '@globalService';
import {
  CreditReasonEnum,
  ErrorDual,
  IMilestone,
  MutationKeyEnum,
  PatchDrawRequestListItemParam,
  QueryNamesEnums,
} from '@interfaces';
import { useStringFieldModel } from '@models';
import { emptyHTML } from '@utils';
import { useCallback, useState } from 'react';
import { useIsMutating, useMutation, useQueryClient } from 'react-query';
import { useParams } from 'react-router';

export const useSubmitCredit = ({ requestId, milestoneId, setOpen }) => {
  const queryClient = useQueryClient();
  const { projectId } = useParams();
  const [reason, setReason] = useState<string[]>([CreditReasonEnum.REBATE_OR_CREDIT]);

  const commentField = useStringFieldModel({
    initValue: '',
    validationRule: (value) => Boolean(value.trim()) && !emptyHTML(value),
  });
  const project = useGetData({
    type: QueryNamesEnums.GET_PROJECT,
    keys: ['name'],
    args: { projectId },
  });

  const milestone = useGetData({
    type: QueryNamesEnums.GET_DRAW_REQUEST_MILESTONE,
    keys: ['requested_credit_amount'],
    args: { projectId, drawRequestId: requestId, milestoneId },
  });

  const patchMilestoneRequestMutation = useMutation<
    IMilestone,
    ErrorDual,
    PatchDrawRequestListItemParam
  >(patchDrawRequestListItem, {
    mutationKey: MutationKeyEnum.MILESTONE_PATCH,
    onSuccess: (_data, varibles) => {
      // TODO : change this because Draft not works with invalidate
      queryClient.invalidateQueries([]);
      milestone.manualUpdate({ requested_credit_amount: varibles.json.requested_credit_amount });
      setOpen(false);
    },
  });

  const requested_credit_amount = useStringFieldModel({
    initValue: milestone.data?.requested_credit_amount?.toString?.(),
    withProgressCheck: true,
  });

  const resetState = useCallback(() => {
    commentField.setValue('');
    requested_credit_amount.setAsFloat(+milestone.data?.requested_credit_amount);
  }, [milestone]);

  const submit = useCallback(
    async () =>
      await patchMilestoneRequestMutation.mutateAsync({
        project: projectId,
        drawRequest: requestId,
        milestone: milestoneId,
        json: {
          requested_credit_amount: +requested_credit_amount.value,
          credit_reason: reason.includes(CreditReasonEnum.OTHER) ? commentField.value : reason[0],
        },
      }),
    [requestId, projectId, milestoneId, requested_credit_amount],
  );

  const isMutating = useIsMutating();
  return {
    name: project.data.name,
    reason,
    commentField,
    setReason,
    requested_credit_amount,
    resetState,
    submit,
    isSubmiting: isMutating,
  };
};
