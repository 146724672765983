import React, { FC } from 'react';
import { Button, Dialog, IconButton, Stack, Typography } from '@mui/material';

import { ButtonWithTooltip, Popup, VerticalMenu } from '@components';
import { TOOLTIP_TEXTS } from '@constants';
import { DrawRequestTypeEnum, PopupTypeEnum } from '@interfaces';
import { getTooltipText, toggleElementFromArray } from '@utils';
import NewDrawRequest from 'encirclelabs-assets/new_draw_request.svg';
import NewChangeRequest from 'encirclelabs-assets/new_change_request.svg';
import NewRetainageRequest from 'encirclelabs-assets/new_retainage_request.svg';
import { useServicesButton } from './controller';
import { OrderInspection } from '../index';
import { CloseIcon } from '@svgAsComponents';
import CheckboxWithMedia from './CheckboxWithMedia';

const ServicesButton: FC<{ withUserFocus?: boolean }> = ({ withUserFocus }) => {
  const {
    menuItems,
    isLoading,
    isSubmitting,
    onCloseModal,
    isOpenOrderInspection,
    isCurrentProjectArchived,
    drawRequestInProgress,
    infoModalText,
    closeInfoModal,
    showContinueDraftButton,
    openDraft,
    isCurrentProjectActive,
    createType,
    setCreateType,
    createModalVisible,
    setCreateModal,
    shortCreate,
    retainageRate,
    isReallocationAvailable,
  } = useServicesButton({ withUserFocus });

  return (
    Boolean(menuItems.length) && (
      <span data-tour="add_request_icon">
        {showContinueDraftButton && (
          <ButtonWithTooltip
            disabled={!isCurrentProjectActive}
            tooltipText={getTooltipText({ nonActiveProject: !isCurrentProjectActive })}
            onClick={openDraft}
            dataTestName="project__continue_draft__button"
          >
            Continue draft request
          </ButtonWithTooltip>
        )}
        {!showContinueDraftButton && (
          <VerticalMenu menuItems={menuItems} disabled={isCurrentProjectArchived || isLoading}>
            <ButtonWithTooltip
              tooltipText={TOOLTIP_TEXTS.isCurrentProjectArchived}
              fullWidth={true}
              loading={isLoading}
              disabled={isCurrentProjectArchived}
              dataTestName="project__add_request__button"
            >
              {isSubmitting ? 'Adding request...' : 'Add request'}
            </ButtonWithTooltip>
          </VerticalMenu>
        )}
        <Popup open={Boolean(infoModalText)} type={PopupTypeEnum.ERROR} title="Delete request">
          <Stack alignItems="center" sx={{ width: '100%' }}>
            <Stack alignItems="center" mb={7.5} sx={{ whiteSpace: 'pre-line' }}>
              <Typography textAlign="center" variant="body2">
                {infoModalText}
              </Typography>
            </Stack>
            <Button variant="new" color="secondary" onClick={closeInfoModal}>
              Got it
            </Button>
          </Stack>
        </Popup>
        {withUserFocus && (
          <Dialog maxWidth={'md'} fullWidth={true} open={createModalVisible}>
            <Stack width="100%" alignItems="center" spacing={2} p={4} direction="row">
              <Typography flex={1} variant="h2">
                What would you like to include in request?
              </Typography>
              <IconButton edge="end" onClick={() => setCreateModal(false)} size="large">
                <CloseIcon />
              </IconButton>
            </Stack>
            <Stack width="100%" spacing={2} p={4}>
              <CheckboxWithMedia
                disabled={
                  createType.includes(DrawRequestTypeEnum.CHANGE_REQUEST) &&
                  !isReallocationAvailable
                }
                checked={createType.includes(DrawRequestTypeEnum.DRAW_REQUEST)}
                imagePath={NewDrawRequest}
                title="Draw request"
                descriptions="Request funds for your construction project."
                onChange={() =>
                  setCreateType((data) =>
                    toggleElementFromArray(data, DrawRequestTypeEnum.DRAW_REQUEST),
                  )
                }
                dataTestName="project__draft_user_focus__check_draw"
              />
              <CheckboxWithMedia
                disabled={
                  (!isReallocationAvailable &&
                    createType.includes(DrawRequestTypeEnum.DRAW_REQUEST)) ||
                  createType.includes(DrawRequestTypeEnum.RETAINAGE_REQUEST)
                }
                checked={createType.includes(DrawRequestTypeEnum.CHANGE_REQUEST)}
                imagePath={NewChangeRequest}
                title="Change request"
                descriptions="Adjust line item budgets or propose borrower equity changes."
                onChange={() =>
                  setCreateType((data) =>
                    toggleElementFromArray(data, DrawRequestTypeEnum.CHANGE_REQUEST),
                  )
                }
                dataTestName="project__draft_user_focus__check_change"
              />
              {!!retainageRate && (
                <CheckboxWithMedia
                  disabled={
                    createType.includes(DrawRequestTypeEnum.CHANGE_REQUEST) &&
                    !(
                      isReallocationAvailable &&
                      createType.includes(DrawRequestTypeEnum.DRAW_REQUEST)
                    )
                  }
                  checked={createType.includes(DrawRequestTypeEnum.RETAINAGE_REQUEST)}
                  imagePath={NewRetainageRequest}
                  title="Retainage"
                  descriptions="Release funds from eligible line items and view your
              retainage details."
                  onChange={() =>
                    setCreateType((data) =>
                      toggleElementFromArray(data, DrawRequestTypeEnum.RETAINAGE_REQUEST),
                    )
                  }
                  dataTestName="project__draft_user_focus__check_retainage"
                />
              )}
              <Typography variant="body2SemiBold">
                Initiate to update your table view preferences, or skip and manually configure the
                request on the following screen if needed
              </Typography>
            </Stack>
            <Stack width="100%" justifyContent="end" spacing={2} p={4} direction="row">
              <Button
                variant="text"
                onClick={() => shortCreate(false)}
                data-cy="project__draft_user_focus__button_skip"
              >
                Skip
              </Button>
              <Button
                disabled={!createType}
                onClick={() => shortCreate(true)}
                data-cy="project__draft_user_focus__button_approve"
              >
                Next
              </Button>
            </Stack>
          </Dialog>
        )}
        {isOpenOrderInspection && (
          <OrderInspection drawRequestId={drawRequestInProgress?.id} onClose={onCloseModal} />
        )}
      </span>
    )
  );
};

export default ServicesButton;
