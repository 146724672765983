import React, { useCallback } from 'react';
import ReactDOM from 'react-dom';
import { IconButton, Stack } from '@mui/material';

import { colors } from '@theme';
import { ComponentProps } from './interface';
import { FilesUploader } from '@components';
import { useFilesUploader } from '@hooks';
import { AddPhotoIcon } from '@svgAsComponents';
import { TransloaditTemplateEnum } from '@interfaces';

function AddPhotoBox({ projectId, requestId, milestoneId, dataTestName }: ComponentProps) {
  const {
    isFilesUploaderOpened,
    transloaditSignature,
    uploadMedia,
    filesUploaderClose,
    restrictions,
  } = useFilesUploader();

  const uploadClick = useCallback(
    () =>
      uploadMedia({
        fields: {
          ...(requestId && { draw_request_id: requestId }),
          project_id: projectId,
          milestone_id: milestoneId,
        },
        templateType: TransloaditTemplateEnum.PROOFPOINTS,
      }),
    [],
  );

  const uploader = () => (
    <FilesUploader
      open={isFilesUploaderOpened}
      onClose={filesUploaderClose}
      transloaditSignature={transloaditSignature}
      restrictions={restrictions}
      source={dataTestName}
    />
  );

  return (
    <>
      <Stack
        justifyContent="center"
        alignItems="center"
        sx={{
          backgroundColor: colors.main.primary.lightest,
          width: '100%',
          height: '100%',
          cursor: 'pointer',
          borderRadius: 1,
        }}
        onClick={() => uploadClick()}
      >
        <IconButton data-cy={dataTestName}>
          <AddPhotoIcon />
        </IconButton>
      </Stack>
      {isFilesUploaderOpened &&
        transloaditSignature &&
        ReactDOM.createPortal(uploader(), document.body)}
    </>
  );
}

export default AddPhotoBox;
