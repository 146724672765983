import React, { FC, useState } from 'react';
import { Box, Grid, Stack } from '@mui/material';

import {
  ColumnsFilter,
  DownloadCSVButton,
  DrawRequestsListFilters,
  DrawRequestsListFiltersV2,
  FiltersV2,
  ServiceMessage,
  StyledBox,
  TablePagination,
} from '@components';
import { useColumnFilterV2, useMultiFiltering, useSorting, useTablePagination } from '@hooks';
import { HookState, TableKeyEnum } from '@interfaces';
import * as Controller from './controller';
import { useDrawRequestList } from './dataController';
import { DrawChangeRequestTable, RequestsSummaryBlock } from '../index';
import { useLaunchDarklyFlags } from '@context';

const DrawChangeRequestTab: FC = () => {
  const { page, rowsPerPage, rowsPerPageOptions, onPageChange, onRowsPerPageChange } =
    useTablePagination();
  const { handleSortClick, sortValue } = useSorting();
  const flags = useLaunchDarklyFlags();
  const fitersFromState = useMultiFiltering(TableKeyEnum.REQUESTS);
  const [filterStringQuery, setFilterStringQuery] = useState<string>('');
  const [search, setSearch] = useState('');
  const handleSearchSubmit = setSearch;
  const clearSearch = () => setSearch('');

  const { state, count, formattedData } = useDrawRequestList({
    page,
    rowsPerPage,
    sortValue,
    filters: fitersFromState.filters,
    search,
    filterStringQuery,
  });

  const { hiddenColumns, changeFieldVisibility, isColumnFilterUpdating } = useColumnFilterV2(
    TableKeyEnum.REQUESTS,
  );

  const controller = Controller.useDrawChangeRequestTab({
    isLoading: state === HookState.FETCHING,
    formattedData,
    hiddenColumns,
  });
  const { columns, csvHeaders } = controller;
  return (
    <Stack sx={{ height: '100%' }}>
      <RequestsSummaryBlock />
      <Box sx={{ mt: 2 }} />
      <StyledBox sx={{ p: 0, flexGrow: 1 }}>
        <Stack flexDirection="row" justifyContent="space-between">
          {flags?.['ENG_7702_filters_v2'] ? (
            <FiltersV2
              source="requests"
              setFilterStringQuery={setFilterStringQuery}
              LeftComponent={DrawRequestsListFiltersV2}
              filters={[
                'textSearchFilter',
                'isOnHoldFilter',
                'requestStatusFilter',
                'currentReviewerTeamFilter',
                'projectStatusFilter',
                'inspectionStatusFilter',
                'lenderFilter',
                'borrowerFilter',
                'coordinatorFilter',
                'projectHealthFilter',
                'customerFilter',
                'inverstorFilter',
              ]}
            />
          ) : (
            <DrawRequestsListFilters
              {...fitersFromState}
              handleSearchSubmit={handleSearchSubmit}
              clearSearch={clearSearch}
              search={search}
            />
          )}

          <Box mt={1} mr={2}>
            <Stack flexDirection="row" sx={{ alignItems: 'center' }}>
              <Stack mr={1}>
                <ColumnsFilter
                  columns={columns}
                  hiddenColumns={hiddenColumns}
                  changeFieldVisibility={changeFieldVisibility}
                  isUpdating={isColumnFilterUpdating}
                  source={TableKeyEnum.REQUESTS.toLowerCase()}
                />
              </Stack>
              <Stack>
                <DownloadCSVButton
                  data={formattedData}
                  headers={csvHeaders}
                  filename={`dr-list.csv`}
                />
              </Stack>
            </Stack>
          </Box>
        </Stack>
        {state === HookState.ERROR && <ServiceMessage text="draw requests list" />}
        {state !== HookState.FETCHING && state !== HookState.ERROR && !formattedData?.length && (
          <ServiceMessage>There are no draw requests in this category</ServiceMessage>
        )}
        {(state === HookState.FETCHING || Boolean(formattedData?.length)) && (
          <>
            <DrawChangeRequestTable
              initialData={formattedData}
              initialSortBy={sortValue}
              handleSortClick={handleSortClick}
              isUpdating={state === HookState.FETCHING}
              isLoading={state === HookState.FETCHING}
              hiddenColumns={hiddenColumns}
              controller={controller}
            />
            <Grid container justifyContent="flex-end" alignItems="center" sx={{ mt: 1 }}>
              <TablePagination
                page={page}
                rowsPerPage={rowsPerPage}
                rowsPerPageOptions={rowsPerPageOptions}
                itemsCount={count}
                onPageChange={onPageChange}
                onRowsPerPageChange={onRowsPerPageChange}
              />
            </Grid>
          </>
        )}{' '}
      </StyledBox>
    </Stack>
  );
};

export default DrawChangeRequestTab;
