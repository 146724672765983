import React, { useCallback, useContext, useMemo, useState } from 'react';
import {
  DrawRequestSourceEnum,
  DrawRequestTypeEnum,
  IDrawRequest,
  IInspection,
  IMenuItem,
  IProject,
  IProjectSettings,
  PermissionNamesEnums,
  PostDrawRequest,
  QueryNamesEnums,
  TableKeyEnum,
} from '@interfaces';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import {
  getProject,
  getProjectDrawRequestsList,
  getProjectInspectionsList,
  getProjectSettings,
  postDrawRequest,
} from '@globalService';
import { useColumnFilterV2, useSafeSnackbar } from '@hooks';
import { useNavigate, useParams } from 'react-router-dom';
import { AuthContext, PermissionsContext, SettingsContext, useLaunchDarklyFlags } from '@context';
import {
  checkIsCreator,
  getActiveDrawRequestFromList,
  getCurrentInspection,
  getHiddenColumnsForDR,
  getTeamRole,
  isCancelRequestedInspection,
  isChangeRequest,
  isReallocationEnabledByProject,
  isRequestDraft,
  isRequestInReview,
  isRestricted,
} from '@utils';
import { ChangeIcon, DrawRequestIcon, InspectionIcon, ServicesIcon } from '@svgAsComponents';
import { TOOLTIP_TEXTS } from '@constants';

export interface ControllerInterface {
  createRequest: (values) => void;
  isLoading: boolean;
  isSubmitting: boolean;
  menuItems: IMenuItem[];
  isOpenOrderInspection: boolean;
  drawRequestInProgress: IDrawRequest;
  onCloseModal: () => void;
  isCurrentProjectArchived: boolean;
  infoModalText: string;
  closeInfoModal: () => void;
  showContinueDraftButton: boolean;
  openDraft: () => void;
  isCurrentProjectActive: boolean;
  createType?: Array<DrawRequestTypeEnum>;
  setCreateType: React.Dispatch<React.SetStateAction<DrawRequestTypeEnum[]>>;
  shortCreate: (falag: boolean) => void;
  createModalVisible: boolean;
  setCreateModal: React.Dispatch<React.SetStateAction<boolean>>;
  retainageRate: boolean;
  isReallocationAvailable?: boolean;
}

export const useServicesButton = ({ withUserFocus }): ControllerInterface => {
  const { updateUserColumns } = useColumnFilterV2(TableKeyEnum.SUBMISSION);

  const [createType, setCreateType] = useState<DrawRequestTypeEnum[]>([]);
  const [createModalVisible, setCreateModal] = useState<boolean>(false);
  const { permissions } = useContext(PermissionsContext);
  const { isCurrentProjectArchived, isCurrentProjectActive } = useContext(SettingsContext);
  const { enqueueSnackbar } = useSafeSnackbar();
  const navigate = useNavigate();
  const { projectId } = useParams();
  const queryClient = useQueryClient();
  const [infoModalText, setInfoModalText] = useState<string>('');
  const flags = useLaunchDarklyFlags();

  const [isOpenOrderInspection, setOpenOrderInspection] = useState(false);
  const { user } = useContext(AuthContext);
  const teamRole = getTeamRole(user);

  const drawRequestsQuery = useQuery<{ results: IDrawRequest[] }, Error>(
    [QueryNamesEnums.GET_PROJECT_DRAW_REQUEST_LIST, { projectId }],
    getProjectDrawRequestsList.bind(this, projectId),
  );

  const settingsQuery = useQuery<IProjectSettings, Error>(
    [QueryNamesEnums.GET_PROJECT_SETTINGS, { projectId }],
    getProjectSettings.bind(this, projectId),
  );

  const drawRequestInProgress = useMemo(
    () => getActiveDrawRequestFromList(drawRequestsQuery.data?.results),
    [drawRequestsQuery.data],
  );

  const { data: { results: inspectionData = [] } = {} } = useQuery<
    { results: IInspection[] },
    Error
  >(
    [QueryNamesEnums.GET_PROJECT_INSPECTIONS, { projectId }],
    getProjectInspectionsList.bind(this, { projectId }),
    {
      enabled: !isRestricted(PermissionNamesEnums.PROJECT__INSPECTION, permissions),
    },
  );

  const draftRequest = useMemo(() => {
    const DRList = drawRequestsQuery.data?.results || [];
    return DRList.find((dr) => isRequestDraft(dr?.status));
  }, [drawRequestsQuery.data]);

  const addRequestMutation = useMutation<IDrawRequest, Error, PostDrawRequest>(postDrawRequest, {
    onSuccess: (data) => {
      queryClient.invalidateQueries([QueryNamesEnums.GET_PROJECT_DRAW_REQUEST_LIST, { projectId }]);
      queryClient.invalidateQueries(QueryNamesEnums.GET_DRAW_REQUEST);
      queryClient.invalidateQueries(QueryNamesEnums.GET_DRAW_REQUEST_FOR_APPROVAL);
      queryClient.invalidateQueries(QueryNamesEnums.GET_DRAW_REQUEST_ITEM_CHECKLIST);
      queryClient.invalidateQueries([QueryNamesEnums.GET_PROJECT_PROGRESS, { projectId }]);
      queryClient.invalidateQueries([QueryNamesEnums.GET_PROJECT_FUNDS, { projectId }]);
      queryClient.invalidateQueries([QueryNamesEnums.GET_PROJECT, { projectId }]);
      if (isRequestDraft(data.status)) {
        navigate(`/projects/${projectId}/requests/${data?.id}/submission/`);
      }
      if (isRequestInReview(data.status)) {
        navigate(`/projects/${projectId}/draws/draw-requests/${data?.id}/`);
      }
    },
    onError: (error) => {
      enqueueSnackbar(error.message, { variant: 'error' });
    },
  });

  const createRequest = useCallback(
    (chosenType: DrawRequestTypeEnum, chosenSource?: DrawRequestSourceEnum) => {
      if (draftRequest) {
        setInfoModalText(
          `There is Draft ${isChangeRequest(draftRequest) ? 'CR' : 'DR'} created by Borrower user ${
            draftRequest.team?.company_name || ''
          }. If you need to create a new request on behalf of the borrower, please contact Customer Support.`,
        );
        return;
      }
      addRequestMutation.mutateAsync({
        id: projectId,
        type: chosenType,
        ...(chosenSource && { source: chosenSource }),
      });
    },
    [projectId, draftRequest, teamRole],
  );

  const isOrderButtonShown = useMemo(
    () =>
      !isRestricted(PermissionNamesEnums.PROJECT__INSPECTION__CREATE, permissions) &&
      Boolean(drawRequestInProgress?.type === DrawRequestTypeEnum.DRAW_REQUEST),
    [settingsQuery, drawRequestInProgress],
  );

  const projectQuery = useQuery<IProject, Error>(
    [QueryNamesEnums.GET_PROJECT, { projectId }],
    getProject.bind(this, projectId),
  );

  const inProgressInspection = useMemo(
    () => getCurrentInspection(inspectionData),
    [inspectionData],
  );
  const isInspectionCancelRequested = useMemo(
    () => isCancelRequestedInspection(inProgressInspection?.status),
    [inProgressInspection],
  );

  const canEditDraft = useMemo(
    () =>
      draftRequest?.id &&
      !isRestricted(PermissionNamesEnums.PROJECT__DRAW_REQUEST__SUBMIT, permissions) &&
      checkIsCreator(draftRequest, teamRole),
    [draftRequest, permissions, teamRole],
  );

  const menuItems = useMemo(() => {
    // TODO remove items after flags?.['ENG_7877_order_service'] is removed
    const items =
      !flags?.['ENG_7877_order_service'] &&
      !isRestricted(PermissionNamesEnums.PROJECT__INSPECTION__CREATE, permissions)
        ? [
            {
              action: () => setOpenOrderInspection(true),
              text: 'Inspection',
              icon: <InspectionIcon size={18} />,
              disabled: Boolean(inProgressInspection),
              disabledTooltipText: isInspectionCancelRequested
                ? TOOLTIP_TEXTS.inspectionCancelRequested
                : TOOLTIP_TEXTS.inspectionOrdered,
              dataTestName: 'project__add_request_menu__order_inspection__button',
            },
          ]
        : [];

    return [
      ...items,
      ...(!isRestricted(PermissionNamesEnums.PROJECT__DRAW_REQUEST__CREATE, permissions)
        ? withUserFocus
          ? [
              {
                action: () => setCreateModal(true),
                text: 'Draw',
                icon: <DrawRequestIcon size={20} />,
                disabled: Boolean(drawRequestInProgress) || !isCurrentProjectActive,
                dataTestName: 'add_draw_draft',
                disabledTooltipText: isCurrentProjectActive
                  ? TOOLTIP_TEXTS.activeRequest
                  : TOOLTIP_TEXTS.isCurrentProjectArchived,
              },
            ]
          : [
              {
                action: () => createRequest(DrawRequestTypeEnum.DRAW_REQUEST),
                text: 'Draw',
                icon: <DrawRequestIcon size={20} />,
                disabled: Boolean(drawRequestInProgress) || !isCurrentProjectActive,
                dataTestName: 'add_draw_draft',
                disabledTooltipText: isCurrentProjectActive
                  ? TOOLTIP_TEXTS.activeRequest
                  : TOOLTIP_TEXTS.isCurrentProjectArchived,
              },
              {
                action: () => createRequest(DrawRequestTypeEnum.CHANGE_REQUEST),
                text: 'Change',
                icon: <ChangeIcon size={18} />,
                dataTestName: 'add_change_request',
                disabled: Boolean(drawRequestInProgress) || !isCurrentProjectActive,
                disabledTooltipText: isCurrentProjectActive
                  ? TOOLTIP_TEXTS.activeRequest
                  : TOOLTIP_TEXTS.isCurrentProjectArchived,
              },
            ]
        : []),
      ...(!isRestricted(PermissionNamesEnums.PROJECT__HISTORICAL_DRAW_REQUEST__CREATE, permissions)
        ? [
            {
              action: () =>
                createRequest(DrawRequestTypeEnum.DRAW_REQUEST, DrawRequestSourceEnum.MANUAL),
              text: 'Historical draw',
              icon: <DrawRequestIcon size={24} />,
              dataTestName: 'add_historical_draw',
              disabled: Boolean(drawRequestInProgress),
              disabledTooltipText: TOOLTIP_TEXTS.activeRequest,
              sectionBreak: !isRestricted(
                PermissionNamesEnums.PROJECT__INSPECTION__CREATE,
                permissions,
              ),
            },
          ]
        : []),
      ...(flags?.['ENG_7877_order_service'] &&
      !isRestricted(PermissionNamesEnums.PROJECT__INSPECTION__CREATE, permissions)
        ? [
            {
              action: () => navigate(`order-service/inspection`),
              text: 'Service',
              icon: <ServicesIcon size={24} />,
              dataTestName: 'project__add_request_menu__order_service__button',
            },
          ]
        : []),
    ];
  }, [
    draftRequest,
    drawRequestInProgress,
    permissions,
    inProgressInspection,
    canEditDraft,
    isOrderButtonShown,
    isCurrentProjectActive,
  ]);

  const openDraft = () => {
    if (draftRequest?.id)
      navigate(`/projects/${projectId}/requests/${draftRequest.id}/submission/`);
  };
  const isReallocationAvailable = useMemo(
    () => isReallocationEnabledByProject(projectQuery.data),
    [projectQuery.data],
  );
  const retainageRate = useMemo(() => !!projectQuery.data?.retainage_rate, [projectQuery.data]);

  const shortCreate = useCallback(
    (withOverWrite: boolean) => {
      if (withOverWrite) {
        updateUserColumns(getHiddenColumnsForDR(createType));
      }

      setCreateModal(false);
      if (createType[0] === DrawRequestTypeEnum.CHANGE_REQUEST && createType.length === 1) {
        createRequest(DrawRequestTypeEnum.CHANGE_REQUEST);
      } else {
        createRequest(DrawRequestTypeEnum.DRAW_REQUEST);
      }
    },
    [createType, createRequest],
  );

  return {
    menuItems,
    isLoading: drawRequestsQuery.isLoading || addRequestMutation.isLoading,
    isSubmitting: addRequestMutation.isLoading,
    createRequest,
    isOpenOrderInspection,
    drawRequestInProgress,
    onCloseModal: () => {
      setOpenOrderInspection(false);
    },
    isCurrentProjectArchived,
    infoModalText,
    closeInfoModal: () => setInfoModalText(''),
    showContinueDraftButton: canEditDraft,
    openDraft,
    createModalVisible,
    isCurrentProjectActive,
    createType,
    setCreateType,
    shortCreate,
    setCreateModal,
    retainageRate,
    isReallocationAvailable,
  };
};
