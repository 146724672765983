import React, { ChangeEvent, FC, useContext } from 'react';
import { useParams } from 'react-router-dom';
import {
  Box,
  Button,
  Divider,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  TextField,
} from '@mui/material';
import { DocumentContentTypeEnum, IPropertyDetail, PermissionNamesEnums } from '@interfaces';
import * as Controller from './controller';
import { ArrowRightIcon, DeleteIcon } from '@svgAsComponents';
import { CustomDatePickerInput, CustomSelect, CustomTextField, FilesUploader } from '@components';
import { createTuple, WithPermission } from '@utils';
import { SettingsContext } from '@context';

interface ComponentProps {
  completionDateDefault: Date | null;
  onClose: () => void;
  projectDefaultType: string;
  propertyDefaultType: string;
  propertyProposedType: string;
  propertyDetailsDefault: IPropertyDetail[];
  scopeOfWorkDefault: string;
  exitStrategyDefault: string;
  propertyTypeId: string;
  startDate: Date | null;
  projectName: string;
}

const ProjectDetailsEdit: FC<ComponentProps> = ({
  completionDateDefault,
  onClose,
  projectDefaultType,
  propertyDefaultType,
  propertyDetailsDefault,
  scopeOfWorkDefault,
  exitStrategyDefault,
  propertyTypeId,
  propertyProposedType,
  startDate,
  projectName,
}) => {
  const { projectId } = useParams();
  const { isPHBProject } = useContext(SettingsContext);
  const {
    projectType,
    exitStrategy,
    setExitStrategy,
    propertyType,
    updatePropertyType,
    setProjectType,
    projectTypesList,
    propertyTypesList,
    exitStrategiesList,
    propertyDetails,
    propertyDetailsNames,
    scopeOfWork,
    completionDate,
    completionDateReasonsList,
    completionDateReasons,
    handleCompletionDateReasonChange,
    showCompletionDateReasons,
    addPropertyDetail,
    deletePropertyDetail,
    updatePropertyDetail,
    onSave,
    checkIfEmptyDetailExist,
    fileUploader,
    startUploading,
    thumbTypeId,
    closeFileUploader,
    isCompletionDateReasonsValid,
    projectNameField,
  } = Controller.useProject(
    projectId,
    completionDateDefault,
    projectDefaultType,
    exitStrategyDefault,
    propertyDefaultType,
    propertyProposedType,
    propertyDetailsDefault,
    scopeOfWorkDefault,
    propertyTypeId,
    onClose,
    startDate,
    projectName,
  );

  return (
    <Stack spacing={0} width="100%">
      <Button
        onClick={() => startUploading()}
        variant="new"
        color="secondary"
        size="small"
        sx={{
          position: 'absolute',
          top: '230px',
          left: '0px',
          right: '0px',
          width: '170px',
          margin: 'auto',
        }}
      >
        Edit profile image
      </Button>
      <WithPermission permissionKey={PermissionNamesEnums.PROJECT__OVERVIEW__NAME__EDIT}>
        <CustomTextField
          field={projectNameField}
          label="Project name"
          required
          inputProps={{ 'data-cy': 'project__overview__name__input' }}
          sx={{ my: '24px' }}
        />
      </WithPermission>
      <WithPermission
        permissionKey={[
          PermissionNamesEnums.PROJECT__OVERVIEW__PROJECT_COMPLETION__EDIT,
          PermissionNamesEnums.PROJECT__OVERVIEW__NAME__EDIT,
        ]}
      >
        <Stack spacing={2} width="100%">
          <CustomDatePickerInput
            label="Estimated project completion date"
            field={completionDate}
            inputStyles={{ mt: '1.5rem' }}
            minDate={startDate}
            inputProps={{
              'data-cy': `project__overview__completion_date__date_picker`,
            }}
          />
          {showCompletionDateReasons && (
            <CustomSelect
              label="Reason for update"
              optionsList={completionDateReasonsList}
              selectedOptions={completionDateReasons}
              handleSelectChange={handleCompletionDateReasonChange}
              required
              error={!isCompletionDateReasonsValid}
              dataTestName="project__overview__completion_date__reasons__select"
            />
          )}
        </Stack>
      </WithPermission>
      <Box sx={{ py: '24px' }}>
        <Divider />
      </Box>
      {Boolean(projectTypesList?.length) && (
        <WithPermission permissionKey={PermissionNamesEnums.PROJECT__OVERVIEW__PROJECT_TYPE__EDIT}>
          <FormControl sx={{ mb: '24px' }}>
            <InputLabel
              id="project-type"
              sx={{
                '&:not(.MuiFormLabel-filled)': { transform: 'translate(12px, 10px) scale(1)' },
              }}
            >
              Project type
            </InputLabel>
            <Select
              labelId="project-type"
              id="project-type"
              value={projectType || ''}
              label="Project type"
              onChange={(event: SelectChangeEvent) => setProjectType(event.target.value)}
              sx={{ height: '40px' }}
              data-cy="project__overview__project_type__select"
            >
              {projectTypesList?.map((type) => (
                <MenuItem value={type.name_display} key={type.id}>
                  {type.name_display}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </WithPermission>
      )}

      {Boolean(exitStrategiesList?.length) && (
        <WithPermission permissionKey={PermissionNamesEnums.PROJECT__OVERVIEW__EXIT_STRATEGY__EDIT}>
          {/* TODO change here to autocomplete */}
          <FormControl sx={{ mb: '24px' }}>
            <InputLabel
              id="exit-strategy"
              sx={{
                '&:not(.MuiFormLabel-filled)': { transform: 'translate(12px, 10px) scale(1)' },
              }}
            >
              Exit strategy
            </InputLabel>
            <Select
              labelId="exit-strategy"
              id="exit-strategy"
              value={exitStrategy || ''}
              label="Exit strategy"
              sx={{ height: '40px' }}
              onChange={(event: SelectChangeEvent) => setExitStrategy(event.target.value)}
              data-cy="project__overview__exit_strategy__select"
            >
              {exitStrategiesList?.map((strategy) => (
                <MenuItem value={strategy.name_display} key={strategy.id}>
                  {strategy.name_display}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </WithPermission>
      )}
      {Boolean(propertyTypesList?.length) && (
        <WithPermission
          permissionKey={[PermissionNamesEnums.PROJECT__OVERVIEW__PROPERTY_TYPE__EDIT]}
        >
          <FormControl>
            <InputLabel
              id="property-type-existing"
              sx={{
                '&:not(.MuiFormLabel-filled)': { transform: 'translate(12px, 10px) scale(1)' },
              }}
            >
              Property type (Existing)
            </InputLabel>
            <Select
              labelId="property-type-existing"
              id="property-type-existing"
              value={propertyType.existing_type_name || ''}
              label="Property type"
              sx={{ height: '40px' }}
              onChange={(event: SelectChangeEvent) =>
                updatePropertyType('existing_type_name', event.target.value)
              }
              data-cy="project__overview__property_type_exisiting__select"
            >
              {propertyTypesList?.map((type) => (
                <MenuItem value={type.name_display} key={type.id}>
                  {type.name_display}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <Box sx={{ mt: '12px', mb: '10px', ['svg']: { transform: 'rotate(90deg)' } }}>
            <ArrowRightIcon size={20} />
          </Box>
          <FormControl>
            <InputLabel
              id="property-type-proposed"
              sx={{
                '&:not(.MuiFormLabel-filled)': { transform: 'translate(12px, 10px) scale(1)' },
              }}
            >
              Property type (Proposed)
            </InputLabel>
            <Select
              labelId="property-type-proposed"
              id="property-type-proposed"
              value={propertyType.proposed_type_name || ''}
              label="Property type"
              sx={{ mb: '24px', height: '40px' }}
              onChange={(event: SelectChangeEvent) =>
                updatePropertyType('proposed_type_name', event.target.value)
              }
              data-cy="project__overview__property_type_proposed__select"
            >
              <MenuItem value="">
                <em>-</em>
              </MenuItem>
              {propertyTypesList?.map((type) => (
                <MenuItem value={type.name_display} key={type.id}>
                  {type.name_display}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </WithPermission>
      )}
      <WithPermission
        permissionKey={[
          PermissionNamesEnums.PROJECT__OVERVIEW__PROPERTY_DETAILS__EDIT,
          PermissionNamesEnums.PROJECT__OVERVIEW__NAME__EDIT,
        ]}
      >
        {!isPHBProject &&
          propertyDetails?.map((details, index) => (
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
              sx={{ mb: '16px' }}
              key={`${details.name}${[index]}`}
            >
              <Stack sx={{ width: '100%' }}>
                {details.isEditable && (
                  <Stack sx={{ my: '8px' }}>
                    <InputLabel id={`property-detail-${index}`}>Property detail</InputLabel>
                    <Select
                      value={details.name?.toString() || ''}
                      labelId={`property-detail-${index}`}
                      id={`property-detail-${index}`}
                      label="Property detail"
                      onChange={(e) => updatePropertyDetail('name', e.target.value, index)}
                      sx={{ mb: '2px', height: '40px' }}
                      data-cy={`project__overview__property_detail_name__select__index_${index}`}
                    >
                      {propertyDetailsNames?.map((type) => (
                        <MenuItem value={type.id} key={type.id}>
                          {type.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </Stack>
                )}
                <Stack
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                  sx={{ mt: 1 }}
                >
                  <TextField
                    fullWidth
                    variant="outlined"
                    label={details.isEditable ? 'existing' : details.name_display}
                    value={details.existing_value || ' '}
                    sx={{ mr: '10px', '& .MuiInputBase-root': { height: '40px' } }}
                    onChange={(e) => updatePropertyDetail('existing_value', e.target.value, index)}
                    data-cy={`project__overview__property_detail_existing__input__index_${index}`}
                  />
                  <ArrowRightIcon size={20} />
                  <TextField
                    fullWidth
                    variant="outlined"
                    label={details.isEditable ? 'proposed' : ''}
                    value={details.proposed_value || ' '}
                    sx={{ ml: '10px', '& .MuiInputBase-root': { height: '40px' } }}
                    onChange={(e) => updatePropertyDetail('proposed_value', e.target.value, index)}
                    data-cy={`project__overview__property_detail_proposed__input__index_${index}`}
                  />
                </Stack>
              </Stack>
              <IconButton
                sx={{ pr: 0, pl: '10px' }}
                onClick={() => deletePropertyDetail(index)}
                data-cy={`project__overview__property_detail__delete__button__index_${index}`}
              >
                <DeleteIcon size={20} />
              </IconButton>
            </Stack>
          ))}
      </WithPermission>
      <WithPermission
        permissionKey={[
          PermissionNamesEnums.PROJECT__OVERVIEW__PROPERTY_DETAILS__EDIT,
          PermissionNamesEnums.PROJECT__OVERVIEW__NAME__EDIT,
        ]}
      >
        {!isPHBProject && propertyDetailsNames && (
          <Button
            data-cy="project__overview__property_detail__add__button"
            sx={{ mb: '24px', mt: '8px' }}
            variant="new"
            color="secondary"
            onClick={addPropertyDetail}
            disabled={checkIfEmptyDetailExist()}
          >
            Add property detail
          </Button>
        )}
      </WithPermission>
      <WithPermission
        permissionKey={[
          PermissionNamesEnums.PROJECT__OVERVIEW__PROJECT_SCOPE__EDIT,
          PermissionNamesEnums.PROJECT__OVERVIEW__NAME__EDIT,
        ]}
      >
        <TextField
          data-cy="project__overview__scope_of_work__input"
          fullWidth
          variant="outlined"
          label="Scope of work"
          value={scopeOfWork.value || ''}
          sx={{ mb: '24px' }}
          multiline
          rows={5}
          onChange={(event: ChangeEvent<HTMLInputElement>) => scopeOfWork.handleChange(event)}
        />
      </WithPermission>
      <Stack direction="row" justifyContent="flex-end" alignItems="center">
        <Button
          variant="text"
          onClick={onClose}
          data-cy="project__overview__edit_details__cancel__button"
        >
          Cancel
        </Button>
        <Button
          sx={{ ml: '0.5rem' }}
          data-cy="project__overview__edit_details__save__button"
          onClick={onSave}
          disabled={!completionDate.isValid}
        >
          Save
        </Button>
      </Stack>
      {fileUploader.transloaditSignature && (
        <FilesUploader
          open={fileUploader.isFilesUploaderOpened}
          onClose={closeFileUploader}
          transloaditSignature={fileUploader.transloaditSignature}
          restrictions={{
            ...fileUploader.restrictions,
            maxNumberOfFiles: 1,
            allowedFileTypes: ['.png', '.heic', '.jpg', '.jpeg', '.svg'],
          }}
          fields={['document_type_id', 'linked_objects']}
          metaDataDefault={{
            document_type_id: thumbTypeId,
            linked_objects: createTuple(DocumentContentTypeEnum.PROJECT, projectId),
          }}
          source="project__overview__edit_details__profile_image"
        />
      )}
    </Stack>
  );
};

export default ProjectDetailsEdit;
