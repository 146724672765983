import { useCallback, useEffect } from 'react';
import { useIsMutating, useMutation, useQueryClient } from 'react-query';
import { ControllerInterface, PolicyContainerProps } from './interface';
import {
  MutationKeyEnum,
  PlatformPoliciesTemplateParam,
  PoliciesItemListLocal,
  QueryNamesEnums,
} from '@interfaces';
import { updatePlatformPoliciesTemplate } from '@globalService';
import { useSafeSnackbar } from '@hooks';

export const usePolicyContainer = ({
  policyLists,
  assigneeRole,
  type,
  setEditMode,
}: PolicyContainerProps): ControllerInterface => {
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSafeSnackbar();

  useEffect(() => {
    setEditMode(null);
  }, [type]);

  const activeTemplate = policyLists.find((templ) => templ.is_default) || policyLists[0];

  const updatePoliciesItems = useMutation<Response, Error, PlatformPoliciesTemplateParam>(
    updatePlatformPoliciesTemplate,
    {
      mutationKey: MutationKeyEnum.PLATFORM_POLICIES_TEMPLATE_PATCH + assigneeRole,
      onSuccess: () => {
        queryClient.invalidateQueries([QueryNamesEnums.GET_PLATFORM_POLICIES_TEMPLATES]);
      },
      onError: (error) => {
        enqueueSnackbar(error.message, { variant: 'error' });
      },
    },
  );

  const isMutating = useIsMutating({
    predicate: ({ options }) =>
      options.mutationKey === MutationKeyEnum.PLATFORM_POLICIES_TEMPLATE_PATCH + assigneeRole,
  });

  const handleSave = useCallback(
    (templateId: string) =>
      ({ items, name }: PoliciesItemListLocal) => {
        if (templateId) {
          const itemsList = items.map((item, index) => ({
            name: { name: item.label },
            id: item.id,
            index,
            description: item.description,
            ...(item.type && { type: item.type }),
          }));
          updatePoliciesItems.mutateAsync({
            templateId,
            templateData: { name, is_default: true, items: itemsList },
          });
        }
      },
    [],
  );

  const handleClose = () => {
    setEditMode(null);
  };

  return {
    activeTemplate,
    handleSave,
    handleClose,
    isMutating: Boolean(isMutating),
  };
};
