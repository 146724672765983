import React, { FC, useCallback, useContext, useRef } from 'react';
import { useFilesUploader, useImagePicker } from '@hooks';
import { ButtonWithTooltip, FilesUploader, Gallery, PanoramaViewer, PhotoCard } from '../index';
import { Box, Skeleton, Stack, Theme, Typography, useMediaQuery } from '@mui/material';
import { HookState, IDocument, IProofpoint, TransloaditTemplateEnum } from '@interfaces';
import { NoDataImage } from '@svgAsComponents';
import { usePhotoPanel } from './controller';
import { colors } from '@theme';
import { getTooltipText, openFullScreenPanorama } from '@utils';
import ReactDOM from 'react-dom';
import { SettingsContext, useLaunchDarklyFlags } from '@context';
import { useParams } from 'react-router-dom';
import { ViewerAPI } from 'react-photo-sphere-viewer';

const PhotosPanel: FC<{ milestoneId: string; drawRequestId?: string; source: string }> = ({
  milestoneId,
  drawRequestId,
  source,
}) => {
  const { projectId } = useParams();
  const { isCurrentProjectArchived } = useContext(SettingsContext);
  const { gallery, open, close, startIndex } = useImagePicker();
  const smallMediaQuery = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));
  const {
    isFilesUploaderOpened,
    transloaditSignature,
    uploadMedia,
    filesUploaderClose,
    restrictions,
    refetchCallback,
  } = useFilesUploader();
  const { photos, state, refetch, canUploadPhoto } = usePhotoPanel({ milestoneId, drawRequestId });
  const [showPanorama, setShowPanorama] = React.useState(false);
  const ref = useRef<ViewerAPI>();
  const flags = useLaunchDarklyFlags();

  const onPhotoClick = useCallback(
    (photo: IProofpoint | IDocument, index: number) => {
      if (photo.is_panorama && flags?.['ENG_8135_360_degree_photos_viewer']) {
        openFullScreenPanorama(setShowPanorama, ref, photo);
      } else {
        open(photos, index);
      }
    },
    [photos, open, ref, flags],
  );

  return (
    <Stack direction="row" flexWrap="wrap" sx={{ flex: 1 }}>
      {state === HookState.SUCCESS && !photos?.length && <NoContent />}
      {state === HookState.FETCHING && <LoadingSkeleton />}
      {showPanorama && <PanoramaViewer ref={ref} />}
      {photos?.map((photo, index) => (
        <MemoizedPhoto
          key={photo.id}
          photo={photo}
          onClick={() => onPhotoClick(photo, index)}
          source={source}
          index={index}
        />
      ))}
      {canUploadPhoto && (
        <Stack
          direction="row"
          justifyContent="flex-end"
          sx={{
            mt: 'auto',
            position: 'fixed',
            bottom: 0,
            backgroundColor: colors.background.white,
            p: 4.5,
            width: smallMediaQuery ? '100%' : '564px',
          }}
        >
          <ButtonWithTooltip
            variant="new"
            color="secondary"
            onClick={() => {
              uploadMedia({
                fields: {
                  draw_request_id: drawRequestId,
                  project_id: projectId,
                  milestone_id: milestoneId,
                },
                templateType: TransloaditTemplateEnum.PROOFPOINTS,
              });
            }}
            disabled={isCurrentProjectArchived}
            tooltipText={getTooltipText({ isCurrentProjectArchived })}
            sx={{ ml: 1 }}
            dataTestName={`${source}__upload__button`}
          >
            Upload progress photo
          </ButtonWithTooltip>
        </Stack>
      )}
      {gallery && <Gallery startIndex={startIndex} close={close} files={gallery} />}

      {isFilesUploaderOpened &&
        transloaditSignature &&
        ReactDOM.createPortal(
          <FilesUploader
            open={isFilesUploaderOpened}
            onClose={() => {
              filesUploaderClose();
            }}
            transloaditSignature={transloaditSignature}
            restrictions={restrictions}
            refetchCallback={refetchCallback}
            refetch={[() => refetch()]}
            source={source}
          />,
          document.body,
        )}
    </Stack>
  );
};

const Photo: FC<{
  photo: IProofpoint | IDocument;
  onClick: () => void;
  source: string;
  index: number;
}> = ({ photo, onClick, source, index }) => (
  <Box
    sx={{ width: '125px', mr: 1 }}
    key={photo.id}
    onClick={onClick}
    data-cy={`${source}__image__index_${index}`}
  >
    <PhotoCard photo={photo} backgroundColor={colors.neutral.white} />
  </Box>
);

const MemoizedPhoto = React.memo(
  Photo,
  (prevProps, nextProps) => prevProps?.photo?.id === nextProps?.photo?.id,
);

const NoContent: FC = () => (
  <Stack justifyContent="center" alignItems="center" sx={{ flexGrow: 1 }}>
    <NoDataImage size={200} />
    <Typography sx={{ mt: 4, textAlign: 'center' }} variant="body2SemiBold">
      No progress photos yet
    </Typography>
  </Stack>
);

const LoadingSkeleton = () => (
  <Stack
    alignItems="flex-start"
    justifyContent="center"
    direction="row"
    spacing={1}
    sx={{ width: '100%' }}
  >
    <Skeleton height={140} width={125} />
    <Skeleton height={140} width={125} />
    <Skeleton height={140} width={125} />
    <Skeleton height={140} width={125} />
  </Stack>
);

export default PhotosPanel;
