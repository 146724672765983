/* eslint-disable dot-notation */
/* eslint-disable no-console */
import React, { useContext, useEffect } from 'react';
import { Navigate, useRoutes } from 'react-router-dom';
import TagManager from 'react-gtm-module';
import * as Sentry from '@sentry/react';
import { datadogRum } from '@datadog/browser-rum';

import {
  ForgotPassword,
  ResetPassword,
  LoginLayout,
  AuthContext,
  getTeamRole,
  MyCompany,
  PageLayout,
  Profile,
  Project,
  Projects,
  ProjectPeople,
  ProjectSettings,
  NoAvailableContentPage,
  ProjectsPortfolio,
  isLocalhost,
  isProduction,
  InactiveUserPage,
  LoginForm,
  InspectionResults,
  RequestsQueue,
  Analytics,
  NewProject,
  EmailConfirmation,
  AppUpdateContext,
  packageJsonVersion,
  CreateBudgetTableWrapper,
  DashboardDetails,
  PlatformSettings,
  AddBorrowerCompany,
  OrderService,
} from 'encirclelabs-shared';

import { screensACL } from '@constants';
import config from '@config';

const { gtmId } = config;

const Router = () => {
  const { updateAvailable } = useContext(AppUpdateContext);
  const { user } = useContext(AuthContext);
  const teamRole = getTeamRole(user);

  const defaultScreen = screensACL[teamRole || user?.primary_role]?.default || '/no-content';

  return useRoutes([
    {
      path: '/',
      element: <PageLayout screensACL={screensACL} updateAvailable={updateAvailable} />,
      children: [
        { path: '/', element: <Navigate to={defaultScreen} replace /> },
        {
          path: 'projects',
          element: <Projects />,
        },
        { path: 'projects/add-new', element: <NewProject /> },
        { path: 'projects/:projectId/add-borrower', element: <AddBorrowerCompany /> },
        { path: 'projects/:projectId/order-service/*', element: <OrderService /> },
        { path: 'projects/:projectId/people', element: <ProjectPeople /> },
        { path: 'projects/:projectId/settings/*', element: <ProjectSettings /> },
        { path: 'projects/:projectId/*', element: <Project /> },
        { path: 'projects/:projectId/draws/all/:action', element: <CreateBudgetTableWrapper /> },
        {
          path: 'projects/:projectId/inspection/:inspectionId/:action',
          element: <InspectionResults />,
        },
        { path: 'requests/*', element: <RequestsQueue /> },
        { path: 'profile/*', element: <Profile /> },
        { path: 'company/:companyId/*', element: <MyCompany /> },
        { path: 'risk-radar', element: <ProjectsPortfolio /> },
        {
          path: 'analytics',
          element: <Analytics />,
        },
        { path: 'analytics/:dashboardId', element: <DashboardDetails /> },
        { path: 'platform-settings/*', element: <PlatformSettings /> },
        { path: '*', element: <ProjectsPortfolio /> },
      ],
    },
    {
      path: '/',
      element: <LoginLayout updateAvailable={updateAvailable} />,
      children: [
        { path: 'login', element: <LoginForm /> },
        { path: 'email/confirm', element: <EmailConfirmation /> },
        { path: 'forgot-password', element: <ForgotPassword /> },
        { path: 'reset-password/:token', element: <ResetPassword /> },
      ],
    },
    { path: 'no-content', element: <NoAvailableContentPage /> },
    { path: 'deactivated-account', element: <InactiveUserPage /> },
  ]);
};

export default function WrappedRouter() {
  const { user } = useContext(AuthContext);
  const { id, first_name, last_name, email, active_team, last_login, agreed_to_terms, customers } =
    user || {};
  const gtmParamsProd = {
    gtmId,
    dataLayer: {
      user_id: id,
      email,
      first_name,
      last_name,
      role: active_team?.role,
      last_login,
      tos_agreed: agreed_to_terms,
      customer: active_team?.company?.is_customer ? active_team?.company?.name : '',
      customers,
    },
  };

  useEffect(() => {
    if (isProduction && user?.id) TagManager.initialize(gtmParamsProd);
    if (!isLocalhost && user?.id) {
      Sentry.setUser({ id, username: `${first_name} ${last_name}` });
      if (active_team?.company?.name) Sentry.setTag('user.company', active_team.company.name);
      datadogRum.setUser({
        id,
        email,
        name: `${first_name} ${last_name}`,
        company: active_team?.company?.name,
      });
    }
    Sentry.setTag('appVersion', packageJsonVersion);
  }, [user]); // eslint-disable-line

  return <Router />;
}
