import { useQuery } from 'react-query';
import {
  HookState,
  IDocument,
  IDrawRequest,
  IMilestone,
  IProofpoint,
  QueryNamesEnums,
} from '@interfaces';
import {
  getDrawRequest,
  getDrawRequestMilestone,
  getMilestoneDocuments,
  getProjectMilestone,
  getProjectMilestoneDocuments,
} from '@globalService';
import { getFilteredByRepresentationPhotos, getHookState, isRequestDraftOrInReview } from '@utils';
import { useCallback, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { onlyProgressPhotos, proofpointsQueryFields } from '@constants';
import { useLaunchDarklyFlags } from '@context';

interface ComponentProps {
  state: HookState;
  photos: (IDocument | IProofpoint)[];
  refetch: CallableFunction;
  canUploadPhoto: boolean;
}
export const usePhotoPanel = ({ drawRequestId, milestoneId }): ComponentProps => {
  const { projectId } = useParams();
  const flags = useLaunchDarklyFlags();
  const isProjectLevel = useMemo(() => !drawRequestId, [drawRequestId]);

  const drawRequestMilestonePhotosQuery = useQuery<IMilestone, Error>(
    [
      QueryNamesEnums.GET_DRAW_REQUEST_MILESTONE,
      { projectId, drawRequestId, milestoneId, query: proofpointsQueryFields },
    ],
    () =>
      getDrawRequestMilestone({
        projectId,
        drawRequestId,
        milestoneId,
        restQlParams: proofpointsQueryFields,
      }),
    { enabled: Boolean(projectId && drawRequestId && milestoneId) },
  );

  const projectMilestonePhotosQuery = useQuery<IMilestone, Error>(
    [
      QueryNamesEnums.GET_PROJECT_MILESTONE,
      { projectId, milestoneId, query: proofpointsQueryFields },
    ],
    getProjectMilestone.bind(this, { projectId, milestoneId, query: proofpointsQueryFields }),
    { enabled: Boolean(!drawRequestId && projectId && milestoneId) },
  );

  const drawRequestMilestonePhotosQueryV2 = useQuery<{ results: IDocument[] }, Error>(
    [
      QueryNamesEnums.GET_DRAW_REQUEST_MILESTONE_DOCS,
      { projectId, drawRequestId, milestoneId, query: onlyProgressPhotos },
    ],
    () =>
      getMilestoneDocuments({
        projectId,
        drawRequestId,
        milestoneId,
        query: onlyProgressPhotos,
      }),
    { enabled: Boolean(projectId && drawRequestId && milestoneId) },
  );

  const projectMilestonePhotosQueryV2 = useQuery<{ results: IDocument[] }, Error>(
    [
      QueryNamesEnums.GET_PROJECT_MILESTONE_DOCS,
      { projectId, milestoneId, query: onlyProgressPhotos },
    ],
    getProjectMilestoneDocuments.bind(this, { projectId, milestoneId, query: onlyProgressPhotos }),
    { enabled: Boolean(!drawRequestId && projectId && milestoneId) },
  );

  const drawRequestQuery = useQuery<IDrawRequest, Error>(
    [QueryNamesEnums.GET_DRAW_REQUEST, { projectId, drawRequestId }],
    getDrawRequest.bind(this, { projectId, drawRequestId }),
    { enabled: Boolean(drawRequestId) },
  );

  const canUploadPhoto = useMemo(
    () => isRequestDraftOrInReview(drawRequestQuery?.data?.status),
    [drawRequestQuery?.data?.status],
  );

  const photos = useMemo(
    () =>
      flags?.['ENG_8203_migrate_proofpoints_to_documents']
        ? getFilteredByRepresentationPhotos(
            isProjectLevel
              ? projectMilestonePhotosQueryV2.data?.results
              : drawRequestMilestonePhotosQueryV2.data?.results,
          )
        : getFilteredByRepresentationPhotos(
            isProjectLevel
              ? projectMilestonePhotosQuery.data?.proofpoints
              : drawRequestMilestonePhotosQuery.data?.proofpoints,
          ),
    [
      drawRequestMilestonePhotosQuery.data,
      projectMilestonePhotosQuery.data,
      isProjectLevel,
      flags,
      drawRequestMilestonePhotosQueryV2.data,
      projectMilestonePhotosQueryV2.data,
    ],
  );

  const refetch = useCallback(() => {
    flags?.['ENG_8203_migrate_proofpoints_to_documents']
      ? drawRequestMilestonePhotosQueryV2.refetch()
      : drawRequestMilestonePhotosQuery.refetch();
  }, [flags]);

  return {
    state: getHookState(
      isProjectLevel
        ? [projectMilestonePhotosQuery, projectMilestonePhotosQueryV2]
        : [drawRequestMilestonePhotosQuery, drawRequestMilestonePhotosQueryV2],
    ),
    photos,
    refetch,
    canUploadPhoto,
  };
};
