import { useQuery, useQueryClient } from 'react-query';
import { useEffect, useState } from 'react';

import {
  DocumentContentTypeEnum,
  DocumentTypeEnum,
  IDocumentType,
  QueryNamesEnums,
  TransloaditTemplateEnum,
} from '@interfaces';
import { getProjectDocumentTypeByName } from '@globalService';
import { IFileUploader, useFilesUploader } from '@hooks';
import { getAddress, getLocationFromAddress, isProduction } from '@utils';

interface ControllerInterface {
  fileUploader: IFileUploader;
  startUploading: () => void;
  thumbTypeId: string;
  closeFileUploader: () => void;
  projectLocation: {
    lat: number;
    long: number;
    address: string;
  };
}
export const useProjectImage = ({ project }): ControllerInterface => {
  const [projectLocation, setProjectLocation] = useState(null);
  useEffect(() => {
    const getLocation = async (address) => {
      const location = await getLocationFromAddress(getAddress(address));
      if (location) setProjectLocation({ ...location, long: location?.lng });
    };
    if (project?.address && isProduction) getLocation(project?.address);
  }, [project, isProduction]);

  const queryClient = useQueryClient();

  const fileUploader = useFilesUploader();

  const documentThumbTypeQuery = useQuery<IDocumentType, Error>(
    [QueryNamesEnums.GET_PROJECT_DOCUMENT_THUMB_TYPES],
    getProjectDocumentTypeByName.bind(this, DocumentTypeEnum.THUMB),
  );

  const startUploading = () => {
    const fields = {
      content_type: DocumentContentTypeEnum.PROJECT,
      object_id: project?.id,
    };
    fileUploader.uploadMedia({
      fields,
      templateType: TransloaditTemplateEnum.DOCUMENTS,
    });
  };

  const closeFileUploader = () => {
    queryClient.invalidateQueries(QueryNamesEnums.GET_PROJECT_LIST);
    fileUploader.filesUploaderClose();
  };

  return {
    fileUploader,
    startUploading,
    thumbTypeId: documentThumbTypeQuery.data?.id,
    closeFileUploader,
    projectLocation,
  };
};
