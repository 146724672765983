import { useQuery } from 'react-query';
import { getDrawRequestListStats } from '@globalService';
import { IDrawRequestsStats, QueryNamesEnums } from '@interfaces';

export interface ControllerInterface {
  stats: IDrawRequestsStats;
}

export const useRequestsSummaryBlock = (): ControllerInterface => {
  const stats = useQuery<IDrawRequestsStats, Error>(
    [QueryNamesEnums.GET_DRAW_REQUEST_LIST_STATS],
    getDrawRequestListStats.bind(this, ''),
  );

  return {
    stats: stats.data,
  };
};
